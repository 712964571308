import TrainersStats from "../../assets/img/about/trainersStats.png";
import MCTLogo from "../../assets/img/about/MCT_logo.png";
import CertificationsStats from "../../assets/img/about/certifications.png";
import SectionTitle from "../../components/Common/SectionTitle";
import { useState, useEffect } from "react";

const AboutStats = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <div
      id="rs-stats"
      className="rs-team style1 inner-style orange-style  pb-110 md-pt-64 md-pb-70 "
      style={{
        margin: "0 2rem",
      }}
    >
      <div className="container">
        <SectionTitle
          sectionClass="sec-title mb-50 md-mb-30 text-center"
          titleClass="sl-title text-center "
          title="CloudiaSys Academy Aujourd'hui"
          titleStyle={{
            fontSize: isMobile ? "2.5rem" : "4rem",
            marginBottom: "5rem",
          }}
        />
        <div className="row">
          <div className="col-lg-4 col-md-6 mb-30">
            <div className="stats-item text-center">
              <img className="mb-4 w-50" src={TrainersStats} alt="Formateurs" />
              <h2
                style={{
                  color: "#ff5421",
                  fontSize: isMobile ? "2rem" : "3rem",
                }}
              >
                +10 <br></br>Formateurs
              </h2>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 mb-30">
            <div className="stats-item text-center">
              <img className="mb-4 w-50" src={MCTLogo} alt="MCT" />
              <h2
                style={{
                  color: "gold",
                  fontSize: isMobile ? "2rem" : "3rem",
                }}
              >
                6 <br></br>Certifiés MCT
              </h2>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 mb-30">
            <div className="stats-item text-center">
              <img
                className="mb-4 w-50"
                src={CertificationsStats}
                alt="Certifications"
              />
              <h2
                style={{
                  color: "blue",
                  fontSize: isMobile ? "2rem" : "3rem",
                }}
              >
                +30 <br></br>Certifications
              </h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutStats;
