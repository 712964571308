import React from "react";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";

//CloudiaSys Routes
import kubernetes from "../CloudiaSys/bootCamps/kubernetes";
import cloud from "../CloudiaSys/bootCamps/cloud";
import formation from "../CloudiaSys/formations";
import AddFormation from "../CloudiaSys/formations/add/CreateFormation";
import SingleFormation from "../CloudiaSys/formations/show-details/indexOld";

//Custom Components

import Home from "../pages/home";
import About from "../CloudiaSys/about";
import OurMethod from "../CloudiaSys/about/method";
import Financement from "../CloudiaSys/about/financement";
import AboutTwo from "../pages/about-2";
import CourseOne from "../CloudiaSys/formations/trainingsList";
import CourseTwo from "../pages/course-2";
import CourseThree from "../pages/course-3";
import CourseFour from "../pages/course-4";
import CourseFive from "../pages/course-5";
import CourseSix from "../pages/course-6";
import CourseSingle from "../CloudiaSys/formations/show-details";
import CourseCategoryPage from "../pages/course-categories";
import Team from "../pages/team";
import TeamTwo from "../pages/team-2";
import TeamSingle from "../pages/team/team-single";
import Event from "../pages/event";
import EventTwo from "../pages/event-2";
import EventThree from "../pages/event-3";
import Gallery from "../pages/gallery";
import GalleryTwo from "../pages/gallery-2";
import GalleryThree from "../pages/gallery-3";
import Faq from "../pages/faq";
import Login from "../pages/login";
import Register from "../pages/register";
import Blog from "../pages/blog";
import BlogLeft from "../pages/blog/BlogLeft";
import BlogRight from "../pages/blog/BlogRight";
import SinglePostLeftSidebar from "../pages/blog/single-post-left-sidebar";
import SinglePostRightSidebar from "../pages/blog/single-post-right-sidebar";
import SinglePostFullWidth from "../pages/blog/single-post-full-width";
import Contact from "../pages/contact";
import ContactTwo from "../pages/contact-2";
import ContactThree from "../pages/contact-3";
import ContactFour from "../pages/contact-4";
import Error from "../pages/404";
import LoadTop from "../components/Common/ScrollTop/LoadTop";
import EditFormation from "../CloudiaSys/formations/Edit/EditFormation";
import EditFormationForm from "../CloudiaSys/formations/Edit/EditFormationForm";
import Microsoft from "../CloudiaSys/certifications/microsoft";
import Google from "../CloudiaSys/certifications/google";
import Kubernetes from "../CloudiaSys/certifications/kubernetes";

const App = () => {
  return (
    <div className="App">
      <Router>
        <LoadTop />
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/home" component={Home} />
          <Route path="/about" exact component={About} />
          <Route path="/about/nousConnaitre" component={About} />
          <Route path="/about/methode" component={OurMethod} />
          <Route path="/about/financement" component={Financement} />
          <Route path="/about-2" component={AboutTwo} />
          <Route
            path="/course/course-single/:courseRef"
            component={CourseSingle}
          />
          <Route path="/course/:thematic" component={CourseOne} />
          <Route path="/course" exact component={CourseOne} />
          <Route path="/course-2" component={CourseTwo} />
          <Route path="/course-3" component={CourseThree} />
          <Route path="/course-4" component={CourseFour} />
          <Route path="/course-5" component={CourseFive} />
          <Route path="/course-6" component={CourseSix} />
          <Route path="/course-categories" component={CourseCategoryPage} />
          <Route path="/team" exact component={Team} />
          <Route path="/team-2" component={TeamTwo} />
          <Route path="/team/team-single" component={TeamSingle} />
          <Route path="/event" component={Event} />
          <Route path="/event-2" component={EventTwo} />
          <Route path="/event-3" component={EventThree} />
          <Route path="/gallery" component={Gallery} />
          <Route path="/gallery-2" component={GalleryTwo} />
          <Route path="/gallery-3" component={GalleryThree} />
          <Route path="/faq" component={Faq} />
          <Route path="/login" component={Login} />
          <Route path="/register" component={Register} />
          <Route path="/blog" exact component={Blog} />
          <Route path="/blog/blog-left-sidebar" component={BlogLeft} />
          <Route path="/blog/blog-right-sidebar" component={BlogRight} />
          <Route
            path="/blog/single-post-left-sidebar"
            component={SinglePostLeftSidebar}
          />
          <Route
            path="/blog/single-post-right-sidebar"
            component={SinglePostRightSidebar}
          />
          <Route
            path="/blog/single-post-full-width"
            component={SinglePostFullWidth}
          />

          <Route path="/contact" component={Contact} />
          <Route path="/contact-2" component={ContactTwo} />
          <Route path="/contact-3" component={ContactThree} />
          <Route path="/contact-4" component={ContactFour} />

          <Route path="/Bootcamps/Kubernetes" component={kubernetes} />
          <Route path="/Bootcamps/cloud" component={cloud} />

          <Route path="/Training/Cloud" component={formation} />
          <Route path="/Training/Data" component={formation} />
          <Route path="/Training/DevOps" component={formation} />
          <Route path="/Training/Cyber" component={formation} />
          <Route path="/Training/Azure" component={formation} />
          <Route path="/Training/Gcp" component={formation} />
          <Route path="/Training/Kubernetes" component={formation} />
          <Route path="/Training/add" component={AddFormation} />
          <Route path="/Training/edit/:id" component={EditFormationForm} />
          <Route path="/Training/edit" component={EditFormation} />
          <Route path="/training/:courseTitle" component={SingleFormation} />

          <Route path="/certifications/microsoft" component={Microsoft} />
          <Route path="/certifications/google" component={Google} />
          <Route path="/certifications/kubernetes" component={Kubernetes} />

          <Route component={Error} />
        </Switch>
      </Router>
    </div>
  );
};

export default App;
