import React from "react";

const ContactForm = (props) => {
  const { submitBtnClass, btnText } = props;
  return (
    <form id="contact-form" action="#">
      <div className="row">
        <div className="col-md-6 mb-30">
          <input
            className="from-control"
            type="text"
            id="firstName"
            name="firstName"
            placeholder="Prénom"
            required
          />
        </div>

        <div className="col-md-6 mb-30">
          <input
            className="from-control"
            type="text"
            id="lastName"
            name="lastName"
            placeholder="Nom"
            required
          />
        </div>

        <div className="col-md-6 mb-30">
          <input
            className="from-control"
            type="text"
            id="email"
            name="email"
            placeholder="E-Mail"
            required
          />
        </div>

        <div className="col-md-6 mb-30">
          <input
            className="from-control"
            type="text"
            id="phone"
            name="phone"
            placeholder="Numéro de téléphone"
            required
          />
        </div>

        <div className="col-12 mb-30">
          <textarea
            className="from-control"
            id="message"
            name="message"
            placeholder="Votre message ici"
            required
          ></textarea>
        </div>
      </div>
      {/* <div className="btn-part">
        <button
          className={submitBtnClass ?? "readon learn-more submit"}
          type="submit"
        >
          {btnText ?? "Submit Now"}
        </button>
      </div> */}

      <div className="row" style={{ padding: "0 10px" }}>
        <button
          type="submit"
          className="readon orange-btn main-home text-center"
          style={{ width: "100%", border: "none" }}
        >
          {btnText ?? "Submit Now"}
        </button>
      </div>
    </form>
  );
};

export default ContactForm;
