import React from "react";
import { Link } from "react-router-dom";
import FooterBottom from "./FooterBottom";

import footerLogo1 from "../../../assets/img/logo/logo.png";
import qualiopiLogo from "../../../assets/img/qualiopi_logo.png";

const Footer = (props) => {
  const { footerLogo, footerClass, footerTopClass } = props;
  return (
    <footer className={footerClass ? footerClass : "rs-footer"}>
      <div className={`footer-top ${footerTopClass}`}>
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-12 col-sm-12 footer-widget md-mb-50">
              <div className="footer-logo mb-30">
                <Link to="/" as="/">
                  <img src={footerLogo ?? footerLogo1} alt="Logo" />
                </Link>
              </div>
              <div className="textwidget pr-60 md-pr-14">
                <p>Façonnez un avenir qui vous ressemble !</p>
                <img src={qualiopiLogo} alt="Qualiopi" />
              </div>
            </div>
            <div className="col-lg-4 col-md-12 col-sm-12 footer-widget md-mb-50">
              <h3 className="widget-title">Adresse</h3>
              <ul className="address-widget">
                <li>
                  <i className="flaticon-location"></i>
                  <div className="desc">
                    78 AVENUE DES CHAMPS ELYSEES 75008 PARIS
                  </div>
                </li>
                <li>
                  <i className="flaticon-call"></i>
                  <div className="desc">
                    <a href="tel:0033651405982">0033651405982</a>
                  </div>
                </li>
                <li>
                  <i className="flaticon-email"></i>
                  <div className="desc">
                    <a href="mailto:cloudiasys.academy.yt@gmail.com">
                      cloudiasys.academy.yt@gmail.com
                    </a>
                  </div>
                </li>
              </ul>
            </div>
            <div className="col-lg-4 col-md-12 col-sm-12 pl-50 md-pl-14 footer-widget md-mb-50">
              <h3 className="widget-title">Formations</h3>
              <ul className="site-map">
                <li>
                  <Link to="/course">Toutes les formations</Link>
                </li>
                <li>
                  <Link to="/course/IA">Formation IA</Link>
                </li>
                <li>
                  <Link to="/course/data">Formation Data</Link>
                </li>
                {/* <li><Link to="/course-2">Course Two</Link></li>
                                <li><Link to="/course/course-single">Single Course</Link></li>
                                <li><Link to="/shop/my-account">Profile</Link></li>
                                <li><Link to="/login">Login</Link>/<Link to="/register">Register</Link></li> */}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <FooterBottom />
    </footer>
  );
};

export default Footer;
