import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ScrollToTop from "../../../components/Common/ScrollTop";

import "./style.css";

const GoogleDetailsMain = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Adjust breakpoint as needed
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const descStyle = {
    fontFamily: "Montserrat",
    fontSize: "1.5rem",
    lineHeight: "1.5",
    margin: isMobile ? "0px !important" : "1rem",
    padding: isMobile ? "0 2rem" : "0 7rem",
    textAlign: "justify",
  };

  return (
    <React.Fragment>
      <div style={{ "margin-top": "70px" }}>
        <div className="fixed-training-menu full-size-block bg-color-grey-50 ">
          {/* FIRST SECTION */}
          <div className="width-100 justify-center">
            <p className="desc" style={descStyle}>
              En 2024, Google Cloud Platform (GCP) s'est affirmée comme le
              troisième plus grand fournisseur de services cloud au monde, avec
              une part de marché mondiale de 10 %. Cette position solide sur le
              marché témoigne de la confiance des entreprises envers les
              solutions de Google Cloud. Par exemple, des leaders du secteur du
              Retail tels que L'Oréal et LVMH ont adopté GCP pour améliorer leur
              productivité et innover dans leurs services. (
              <a
                href="https://www.crn.com/news/cloud/microsoft-azure-grows-twice-as-fast-as-aws-in-q4-2023"
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  color: "blue",
                  textDecoration: "underline",
                }}
              >
                crn.com
              </a>
              )
            </p>
            <p className="desc" style={descStyle}>
              Les certifications Google Cloud permettent aux professionnels de
              valider leurs compétences sur la plateforme GCP et d'accéder à des
              rôles à responsabilités, améliorant ainsi leur trajectoire de
              carrière.
            </p>
          </div>

          {/* STATISTICS SECTION */}
          <div
            className="statistics-section"
            style={{
              padding: isMobile ? "2rem" : "2rem 7rem",
              marginTop: "5rem",
            }}
          >
            <h1
              className="sl-title text-center"
              style={{
                fontSize: isMobile ? "2.5rem" : "3rem",
                marginBottom: "5rem",
              }}
            >
              Impacts des Certifications Officielles Editeurs
            </h1>

            <div
              style={{
                display: "flex",
                flexDirection: isMobile ? "column" : "row",
                justifyContent: "space-between",
                gap: isMobile ? "3rem" : "2rem",
                width: "100%",
              }}
            >
              {/* First Stat */}
              <div
                style={{
                  flex: 1,
                  textAlign: "center",
                  width: isMobile ? "100%" : "auto",
                  padding: isMobile ? "0 1rem" : 0,
                }}
              >
                <h3
                  style={{
                    color: "rgb(232, 99, 69)",
                    fontSize: "4rem",
                    fontWeight: "bold",
                    marginBottom: "1rem",
                  }}
                >
                  85 %
                </h3>
                <p
                  style={{
                    fontSize: "1.2rem",
                    lineHeight: "1.5",
                    fontFamily: "Montserrat",
                  }}
                >
                  Des professionnels certifiés Google Cloud se sentent plus
                  compétents vis-à-vis de la technologie cloud
                </p>
                <p
                  style={{
                    color: "#4A90E2",
                    fontSize: "1rem",
                    marginTop: "1rem",
                    fontStyle: "italic",
                  }}
                >
                  Ipsos (2025)
                </p>
              </div>

              {/* Second Stat */}
              <div style={{ flex: 1, textAlign: "center" }}>
                <h3
                  style={{
                    color: "rgb(232, 99, 69)",
                    fontSize: "4rem",
                    fontWeight: "bold",
                    marginBottom: "1rem",
                  }}
                >
                  80 %
                </h3>
                <p
                  style={{
                    fontSize: "1.2rem",
                    lineHeight: "1.5",
                    fontFamily: "Montserrat",
                  }}
                >
                  Des entreprises préfèrent recruter des professionnels
                  certifiés car ils sont plus productifs
                </p>
                <p
                  style={{
                    color: "#4A90E2",
                    fontSize: "1rem",
                    marginTop: "1rem",
                    fontStyle: "italic",
                  }}
                >
                  Ipsos (2025)
                </p>
              </div>

              {/* Third Stat */}
              <div style={{ flex: 1, textAlign: "center" }}>
                <h3
                  style={{
                    color: "rgb(232, 99, 69)",
                    fontSize: "4rem",
                    fontWeight: "bold",
                    marginBottom: "1rem",
                  }}
                >
                  75 %
                </h3>
                <p
                  style={{
                    fontSize: "1.2rem",
                    lineHeight: "1.5",
                    fontFamily: "Montserrat",
                  }}
                >
                  Des candidats certifiés disent que les certifications les
                  aident à obtenir des salaires plus élevés
                </p>
                <p
                  style={{
                    color: "#4A90E2",
                    fontSize: "1rem",
                    marginTop: "1rem",
                    fontStyle: "italic",
                  }}
                >
                  Ipsos (2025)
                </p>
              </div>
            </div>
          </div>

          {/* WHY CLOUDIASYS SECTION */}
          <div
            className="why-cloudiasys-section"
            style={{
              padding: isMobile ? "0" : "2rem 7rem",
              marginTop: isMobile ? "0" : "5rem",
            }}
          >
            <h1
              className="sl-title text-center"
              style={{
                fontSize: isMobile ? "2.5rem" : "3rem",
                marginBottom: "5rem",
              }}
            >
              Pourquoi Préparer votre Certification Google avec CloudiaSys
              Academy ?
            </h1>

            <div
              style={{
                display: "flex",
                flexDirection: isMobile ? "column" : "row",
                alignItems: "stretch",
                gap: isMobile ? "2rem" : "4rem",
                padding: "2rem",
                borderRadius: "10px",
              }}
            >
              {/* LEFT SECTION */}
              <div
                style={{
                  flex: 1,
                  backgroundColor: "#f8f9fa",
                  padding: "2rem",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  order: isMobile ? "2" : "1",
                }}
              >
                <h3
                  className="sl-title text-center"
                  style={{
                    color: "#4A90E2",
                    fontSize: isMobile ? "1.5rem" : "2.5rem",
                    marginBottom: "2rem",
                  }}
                >
                  Formateurs Experts & Certifiés
                </h3>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "1.5rem",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "flex-start",
                      gap: "1rem",
                      marginTop: "1.5rem",
                    }}
                  >
                    <span style={{ color: "#E86345", fontSize: "1.5rem" }}>
                      ✓
                    </span>
                    <p
                      style={{
                        fontSize: isMobile ? "1rem" : "1.5rem",
                        lineHeight: "1.5",
                        fontFamily: "Montserrat",
                        margin: 0,
                        textAlign: "justify",
                      }}
                    >
                      Chaque formateur détient les certifications officielles
                      Google Cloud associées aux formations qu'il dispense,
                      garantissant la maîtrise des concepts et des meilleures
                      pratiques
                    </p>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "flex-start",
                      gap: "1rem",
                      marginTop: "1.5rem",
                    }}
                  >
                    <span style={{ color: "#E86345", fontSize: "1.5rem" }}>
                      ✓
                    </span>
                    <p
                      style={{
                        fontSize: isMobile ? "1rem" : "1.5rem",
                        lineHeight: "1.5",
                        fontFamily: "Montserrat",
                        margin: 0,
                        textAlign: "justify",
                      }}
                    >
                      Nos formateurs experts ont plusieurs années d'expérience
                      sur la mise en œuvre des projets en entreprise avec GCP et
                      les solutions Google.
                    </p>
                  </div>
                </div>
              </div>

              {/* RIGHT SECTION */}
              <div
                style={{
                  flex: 1,
                  display: "flex",
                  justifyContent: "center",
                  order: isMobile ? "1" : "2",
                }}
              >
                <img
                  src={require("../../../assets/img/certifications/google_cloud_certified.png")}
                  alt="Google Cloud Certified Trainers"
                  style={{
                    maxWidth: "100%",
                    height: "auto",
                    borderRadius: "8px",
                  }}
                />
              </div>
            </div>
          </div>

          {/* EXCLUSIFS ADVANTAGES SECTION */}
          <div
            className="why-cloudiasys-section"
            style={{
              padding: isMobile ? "0" : "2rem 7rem",
              marginTop: isMobile ? "0" : "5rem",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: isMobile ? "column" : "row",
                alignItems: "stretch",
                gap: isMobile ? "2rem" : "4rem",
                padding: "2rem",
              }}
            >
              {/* LEFT SECTION */}
              <div
                style={{
                  flex: 1,
                  display: "flex",
                  justifyContent: "center",
                  order: isMobile ? "2" : "1",
                }}
              >
                <img
                  src={require("../../../assets/img/certifications/avantages.png")}
                  alt="Avantages Exclusifs"
                  style={{
                    maxWidth: "100%",
                    height: "auto",
                  }}
                />
              </div>

              {/* RIGHT SECTION */}
              <div
                style={{
                  flex: 1,
                  backgroundColor: "#f8f9fa",
                  padding: "2rem",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  order: isMobile ? "1" : "2",
                }}
              >
                <h3
                  className="sl-title text-center"
                  style={{
                    color: "#4A90E2",
                    fontSize: isMobile ? "1.5rem" : "2.5rem",
                    marginBottom: "2rem",
                  }}
                >
                  Avantages Exclusifs
                </h3>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "1.5rem",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "flex-start",
                      gap: "1rem",
                      marginTop: "1.5rem",
                    }}
                  >
                    <span style={{ color: "#E86345", fontSize: "1.5rem" }}>
                      ✓
                    </span>
                    <p
                      style={{
                        fontSize: isMobile ? "1rem" : "1.5rem",
                        lineHeight: "1.5",
                        fontFamily: "Montserrat",
                        margin: 0,
                        textAlign: "justify",
                      }}
                    >
                      Nous offrons du temps supplémentaire,
                      <strong>(jusqu'à une journée)</strong>, pour vous
                      permettre de mieux vous préparer à votre examen de
                      certification
                    </p>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "flex-start",
                      gap: "1rem",
                      marginTop: "1.5rem",
                    }}
                  >
                    <span style={{ color: "#E86345", fontSize: "1.5rem" }}>
                      ✓
                    </span>
                    <p
                      style={{
                        fontSize: isMobile ? "1rem" : "1.5rem",
                        lineHeight: "1.5",
                        fontFamily: "Montserrat",
                        margin: 0,
                        textAlign: "justify",
                      }}
                    >
                      Nous organisons des sessions de révision avec des examens
                      blancs, au cours desquelles nos formateurs experts
                      partageront leurs meilleures astuces pour réussir votre
                      examen
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* KEY CERTIFICATIONS SECTION */}
          <div
            className="key-certifications-section"
            style={{
              padding: isMobile ? "0 2rem" : "2rem 7rem",
              marginTop: isMobile ? "0" : "5rem",
              marginBottom: "2rem",
            }}
          >
            <h1
              className="sl-title text-center"
              style={{
                fontSize: isMobile ? "2.5rem" : "3rem",
                marginBottom: "5rem",
              }}
            >
              Préparez-vous aux Certifications Clés
            </h1>

            <div
              style={{
                display: "flex",
                flexDirection: isMobile ? "column" : "row",
                gap: isMobile ? "2rem" : "4rem",
                justifyContent: "center",
              }}
            >
              {/* Cloud DevOps Engineer Card */}
              <div
                style={{
                  flex: 1,
                  backgroundColor: "#f8f9fa",
                  padding: "2rem",
                  borderRadius: "10px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <img
                  src={require("../../../assets/img/certifications/cloud-devops-engineer.png")}
                  alt="Cloud DevOps Engineer"
                  style={{
                    width: "100px",
                    height: "auto",
                    marginBottom: "2rem",
                  }}
                />
                <h3
                  className="sl-title text-center"
                  style={{
                    color: "#4A90E2",
                    fontSize: isMobile ? "1.5rem" : "1.8rem",
                    marginBottom: "1.5rem",
                  }}
                >
                  Professional Cloud DevOps Engineer
                </h3>
                <div style={{ width: "100%" }}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "flex-start",
                      gap: "1rem",
                      marginBottom: "1.5rem",
                      marginTop: "2rem",
                    }}
                  >
                    <span style={{ color: "#E86345", fontSize: "1.5rem" }}>
                      ✓
                    </span>
                    <p
                      style={{
                        fontSize: isMobile ? "1rem" : "1.5rem",
                        lineHeight: "1.5",
                        fontFamily: "Montserrat",
                        textAlign: "justify",
                        margin: 0,
                      }}
                    >
                      Démontrez vos compétences pour concevoir, automatiser et
                      optimiser des infrastructures et des applications sur GCP
                      en appliquant les principes DevOps et SRE
                    </p>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "flex-start",
                      gap: "1rem",
                      marginTop: "2rem",
                    }}
                  >
                    <span style={{ color: "#E86345", fontSize: "1.5rem" }}>
                      ✓
                    </span>
                    <p
                      style={{
                        fontSize: isMobile ? "1rem" : "1.5rem",
                        lineHeight: "1.5",
                        fontFamily: "Montserrat",
                        textAlign: "justify",
                        margin: 0,
                      }}
                    >
                      Idéale pour ceux qui souhaitent gérer les infrastructures
                      et les applications GCP
                    </p>
                  </div>
                </div>
              </div>

              {/* Data Engineer Card */}
              <div
                style={{
                  flex: 1,
                  backgroundColor: "#f8f9fa",
                  padding: "2rem",
                  borderRadius: "10px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <img
                  src={require("../../../assets/img/certifications/data-engineer.png")}
                  alt="Data Engineer"
                  style={{
                    width: "100px",
                    height: "auto",
                    marginBottom: "2rem",
                  }}
                />
                <h3
                  className="sl-title text-center"
                  style={{
                    color: "#4A90E2",
                    fontSize: isMobile ? "1.5rem" : "1.8rem",
                    marginBottom: "1.5rem",
                  }}
                >
                  Professional Data Engineer
                </h3>
                <div style={{ width: "100%" }}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "flex-start",
                      gap: "1rem",
                      marginBottom: "1.5rem",
                      marginTop: "2rem",
                    }}
                  >
                    <span style={{ color: "#E86345", fontSize: "1.5rem" }}>
                      ✓
                    </span>
                    <p
                      style={{
                        fontSize: isMobile ? "1rem" : "1.5rem",
                        lineHeight: "1.5",
                        fontFamily: "Montserrat",
                        textAlign: "justify",
                        margin: 0,
                      }}
                    >
                      Démontrez vos compétences pour concevoir, gérer et
                      sécuriser des systèmes de traitement des données sur GCP,
                      en optimisant l'ingestion, le stockage
                    </p>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "flex-start",
                      gap: "1rem",
                      marginTop: "2rem",
                    }}
                  >
                    <span style={{ color: "#E86345", fontSize: "1.5rem" }}>
                      ✓
                    </span>
                    <p
                      style={{
                        fontSize: isMobile ? "1rem" : "1.5rem",
                        lineHeight: "1.5",
                        fontFamily: "Montserrat",
                        textAlign: "justify",
                        margin: 0,
                      }}
                    >
                      Idéale pour automatiser et optimiser le traitement des
                      données avec GCP
                    </p>
                  </div>
                </div>
              </div>
            </div>

            {/* Call to Action Button */}
            <div
              className="sl-btn mt-40"
              style={{
                width: isMobile ? "100%" : "50%",
                margin: "3rem auto 0",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Link
                to={{
                  pathname: "/course",
                  state: { certification: "google" },
                }}
                className="readon orange-btn main-home"
                style={{
                  width: isMobile ? "100%" : "auto",
                  textAlign: "center",
                  padding: isMobile ? "1rem" : "1.5rem 3rem",
                  fontSize: isMobile ? "1rem" : "1.2rem",
                  display: "block",
                  textDecoration: "none",
                }}
              >
                CONSULTEZ TOUTES LES CERTIFICATIONS GOOGLE
              </Link>
            </div>
          </div>
        </div>
      </div>

      {/* scrolltop-start */}
      <ScrollToTop scrollClassName="scrollup orange-color" />
      {/* scrolltop-end */}
    </React.Fragment>
  );
};

export default GoogleDetailsMain;
