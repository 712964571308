import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import SectionTitle from "../../../components/Common/SectionTitle";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Import partner logos
import logo1 from "../../../assets/img/home/partners/partner_engie.png";
import logo2 from "../../../assets/img/home/partners/partner_totalenergies.png";
import logo3 from "../../../assets/img/home/partners/partner_thales.png";
import logo4 from "../../../assets/img/home/partners/partner_celio.png";
import logo5 from "../../../assets/img/home/partners/partner_norauto.png";

const Partners = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const logos = [logo1, logo2, logo3, logo4, logo5];

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="rs-categories main-home pt-90 pb-100 md-pt-60 md-pb-80">
      <div className="container">
        <SectionTitle
          sectionClass="sec-title3 text-center mb-44"
          subtitleClass="sub-title"
          titleClass="sl-title text-center "
          title="Ils Recrutent Nos Élèves"
          titleStyle={{
            fontSize: isMobile ? "2.5rem" : "4rem",
          }}
        />
        <div className="row mb-34">
          <Slider {...settings}>
            {logos.map((logo, index) => (
              <div
                key={index}
                className="partner-logo"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "150px", // Set consistent height for all slides
                  padding: "10px",
                }}
              >
                <img
                  src={logo}
                  alt={`Partner Logo ${index + 1}`}
                  style={{
                    maxHeight: "100%", // Ensures the image scales within the parent container
                    maxWidth: "100%", // Maintains responsiveness
                    objectFit: "contain", // Ensures logos maintain their aspect ratio
                  }}
                />
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default Partners;
