import React, { useState } from "react";
import { useForm } from "react-hook-form";
import emailjs from "@emailjs/browser";

import GoogleBg from "../../../assets/img/certifications/google-bg.jpg";

const SiteBreadcrumb = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [showDownloadModal, setShowDownloadModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const handleDownloadRequest = async (data) => {};

  const sendEmail = async (formData) => {
    try {
      setIsSubmitting(true);

      const templateParams = {
        from_name: formData.fullName,
        from_email: formData.email,
        message: formData.message,
      };

      const response = await emailjs.send(
        "service_gmail_houcem",
        "template_rcihop6",
        templateParams,
        "-N1Go-oGKNUw9N77D"
      );

      if (response.status === 200) {
        alert("Message envoyé avec succès!");
        setShowModal(false);
        reset();
      }
    } catch (error) {
      console.error("Error sending email:", error);
      alert(
        "Une erreur est survenue lors de l'envoi du message. Veuillez réessayer."
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  const onSubmit = (data) => {
    sendEmail(data);
  };

  return (
    <div className="big-wrapper">
      <style>{`
        .responsive-title {
          font-size: 5rem;
          font-weight: bold;
          line-height: 1.2;
        }
        @media (max-width: 768px) {
          .responsive-title {
            font-size: 2rem !important;
            line-height: 1.3 !important;
            margin-bottom: 1rem !important;
          }
        }
      `}</style>
      <div
        className="bootcamp-section main-header-comp bgcolor-midnight-gradient"
        style={{
          backgroundImage: `url(${GoogleBg})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          position: "relative",
          padding: "3rem",
          minHeight: "300px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 1,
          }}
        />
        <div className="wrapper-1600 flex-inline justify-start position-relative wrapp-on-phablet">
          <div className="col-6">
            <div className="mb-s2">
              <h1 className="sl-title responsive-title">
                Boostez votre Carrière avec une Certification Google
              </h1>
            </div>
          </div>
        </div>
      </div>
      {/* Download Modal */}
      {showDownloadModal && (
        <div
          className="modal-overlay"
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 1000,
          }}
        >
          <div
            className="modal-content"
            style={{
              backgroundColor: "white",
              padding: "2rem",
              borderRadius: "8px",
              width: "90%",
              maxWidth: "500px",
            }}
          >
            <h3 style={{ marginBottom: "1.5rem" }}>Télécharger le Programme</h3>
            <form onSubmit={handleSubmit(handleDownloadRequest)}>
              <div style={{ marginBottom: "1rem" }}>
                <label
                  htmlFor="fullName"
                  style={{ display: "block", marginBottom: "0.5rem" }}
                >
                  Prénom & Nom *
                </label>
                <input
                  type="text"
                  id="fullName"
                  {...register("fullName", {
                    required: "Nom complet est requis",
                    minLength: {
                      value: 5,
                      message:
                        "Le nom complet doit contenir au moins 5 caractères",
                    },
                  })}
                  style={{
                    width: "100%",
                    padding: "0.5rem",
                    borderRadius: "4px",
                    border: errors.fullName
                      ? "1px solid red"
                      : "1px solid #ddd",
                  }}
                />
                {errors.fullName && (
                  <span style={{ color: "red", fontSize: "0.8rem" }}>
                    {errors.fullName.message}
                  </span>
                )}
              </div>
              <div style={{ marginBottom: "1rem" }}>
                <label
                  htmlFor="email"
                  style={{ display: "block", marginBottom: "0.5rem" }}
                >
                  Email *
                </label>
                <input
                  type="email"
                  id="email"
                  {...register("email", {
                    required: "Email est requis",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: "Email invalide",
                    },
                  })}
                  style={{
                    width: "100%",
                    padding: "0.5rem",
                    borderRadius: "4px",
                    border: errors.email ? "1px solid red" : "1px solid #ddd",
                  }}
                />

                {errors.email && (
                  <span style={{ color: "red", fontSize: "0.8rem" }}>
                    {errors.email.message}
                  </span>
                )}
              </div>
              <div style={{ marginBottom: "1.5rem" }}>
                <label
                  htmlFor="phoneNumber"
                  style={{ display: "block", marginBottom: "0.5rem" }}
                >
                  Numéro de téléphone *
                </label>
                <input
                  type="tel"
                  id="phoneNumber"
                  {...register("phoneNumber", {
                    required: "Numéro de téléphone est requis",
                    pattern: {
                      value: /^[0-9+\s-]{8,}$/,
                      message: "Numéro de téléphone invalide",
                    },
                  })}
                  style={{
                    width: "100%",
                    padding: "0.5rem",
                    borderRadius: "4px",
                    border: errors.phoneNumber
                      ? "1px solid red"
                      : "1px solid #ddd",
                  }}
                />
                {errors.phoneNumber && (
                  <span style={{ color: "red", fontSize: "0.8rem" }}>
                    {errors.phoneNumber.message}
                  </span>
                )}
              </div>
              <div>
                <input
                  type="hidden"
                  {...register("programme")}
                  defaultValue="Ansible - Automatiser la Gestion des Serveurs"
                />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: "1rem",
                }}
              >
                <button
                  type="button"
                  onClick={() => setShowDownloadModal(false)}
                  disabled={isSubmitting}
                  style={{
                    padding: "0.5rem 1rem",
                    borderRadius: "4px",
                    border: "1px solid #ddd",
                    backgroundColor: "#fff",
                    cursor: isSubmitting ? "not-allowed" : "pointer",
                    opacity: isSubmitting ? 0.7 : 1,
                  }}
                >
                  Fermer
                </button>
                <button
                  type="submit"
                  className="readon orange-btn main-home"
                  disabled={isSubmitting}
                  style={{
                    padding: "0.5rem 1rem",
                    borderRadius: "4px",
                    border: "none",
                    cursor: isSubmitting ? "not-allowed" : "pointer",
                    opacity: isSubmitting ? 0.7 : 1,
                  }}
                >
                  {isSubmitting ? "Traitement..." : "Télécharger"}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {/* Contact Modal */}
      {showModal && (
        <div
          className="modal-overlay"
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 1000,
          }}
        >
          <div
            className="modal-content"
            style={{
              backgroundColor: "white",
              padding: "2rem",
              borderRadius: "8px",
              width: "90%",
              maxWidth: "500px",
            }}
          >
            <h3 style={{ marginBottom: "1.5rem" }}>Contactez-nous</h3>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div style={{ marginBottom: "1rem" }}>
                <label
                  htmlFor="fullName"
                  style={{ display: "block", marginBottom: "0.5rem" }}
                >
                  Nom complet *
                </label>
                <input
                  type="text"
                  id="fullName"
                  name="fullName"
                  {...register("fullName", { required: true })}
                  style={{
                    width: "100%",
                    padding: "0.5rem",
                    borderRadius: "4px",
                    border: "1px solid #ddd",
                  }}
                />
              </div>
              <div style={{ marginBottom: "1rem" }}>
                <label
                  htmlFor="email"
                  style={{ display: "block", marginBottom: "0.5rem" }}
                >
                  Email *
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  {...register("email", { required: true })}
                  style={{
                    width: "100%",
                    padding: "0.5rem",
                    borderRadius: "4px",
                    border: "1px solid #ddd",
                  }}
                />
              </div>
              <div style={{ marginBottom: "1.5rem" }}>
                <label
                  htmlFor="message"
                  style={{ display: "block", marginBottom: "0.5rem" }}
                >
                  Message *
                </label>
                <textarea
                  id="message"
                  name="message"
                  {...register("message", { required: true })}
                  rows="4"
                  style={{
                    width: "100%",
                    padding: "0.5rem",
                    borderRadius: "4px",
                    border: "1px solid #ddd",
                  }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: "1rem",
                }}
              >
                <button
                  type="button"
                  onClick={() => setShowModal(false)}
                  disabled={isSubmitting}
                  style={{
                    padding: "0.5rem 1rem",
                    borderRadius: "4px",
                    border: "1px solid #ddd",
                    backgroundColor: "#fff",
                    cursor: isSubmitting ? "not-allowed" : "pointer",
                    opacity: isSubmitting ? 0.7 : 1,
                  }}
                >
                  Fermer
                </button>
                <button
                  type="submit"
                  className="readon orange-btn main-home"
                  disabled={isSubmitting}
                  style={{
                    padding: "0.5rem 1rem",
                    borderRadius: "4px",
                    border: "none",
                    cursor: isSubmitting ? "not-allowed" : "pointer",
                    opacity: isSubmitting ? 0.7 : 1,
                  }}
                >
                  {isSubmitting ? "Envoi en cours..." : "Envoyer"}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default SiteBreadcrumb;
