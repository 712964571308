import SectionTitle from "../../components/Common/SectionTitle";
import { FaBook, FaChalkboardTeacher, FaMoneyBill } from "react-icons/fa";
import { GrDirections } from "react-icons/gr";
import { TbTargetArrow } from "react-icons/tb";
import { GoThumbsup } from "react-icons/go";
import { useState, useEffect } from "react";

const WhatDistinguishesUs = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div
      id="rs-team"
      className="rs-team style1 inner-style orange-style "
      style={{
        margin: "2rem",
      }}
    >
      <div className="container">
        <SectionTitle
          sectionClass="sec-title mb-50 md-mb-30 text-center"
          titleClass="sl-title mb-0"
          title="Ce Qui Nous Distingue"
          titleStyle={{
            fontSize: isMobile ? "2.5rem" : "4rem",
          }}
        />

        {/* First Row */}
        <div className="row">
          {/* Catalogue Riche */}
          <div className="col-lg-6 mb-4">
            <div
              className="gray-bg p-4 rounded-lg shadow-md h-full"
              style={{
                borderRadius: "1rem",
              }}
            >
              <div className="row mb-2">
                <FaBook className={isMobile ? "col-2" : "col-1"} size={30} />
                <h3 className="col-8 sl-title font-bold text-gray-800 ">
                  Catalogue Riche
                </h3>
              </div>
              <p className="text-gray-700">
                Que vous soyez débutant ou expert, nous proposons un large choix
                de formations DevOps, Data & IA, … :{" "}
                <strong>Bootcamps intensifs</strong>, formations IT et
                préparation aux certifications officielles de Microsoft,
                Kubernetes,…{" "}
                <strong>le tout dopé à l'IA et à l'IA Générative</strong> pour
                préparer les métiers de demain
              </p>
            </div>
          </div>

          {/* Formateurs Experts */}
          <div className="col-lg-6 mb-4">
            <div
              className="gray-bg p-4 rounded-lg shadow-md h-full"
              style={{
                borderRadius: "1rem",
              }}
            >
              <div className="row mb-2">
                <FaChalkboardTeacher
                  className={isMobile ? "col-2" : "col-1"}
                  size={30}
                />
                <h3 className="col-8 sl-title font-bold text-gray-800 ">
                  Formateurs Experts
                </h3>
              </div>
              <p className="text-gray-700">
                Nos formateurs possèdent une solide expertise en tant que
                Consultants et sont certifiés dans les domaines qu'ils
                enseignent. Ils savent transmettre efficacement leurs
                connaissances et vous accompagner dans votre préparation aux
                certifications
              </p>
            </div>
          </div>
        </div>

        {/* Second Row */}
        <div className="row">
          {/* Approche Projet */}
          <div className="col-lg-6 mb-4">
            <div
              className="gray-bg p-4 rounded-lg shadow-md h-full"
              style={{
                borderRadius: "1rem",
              }}
            >
              <div className="row mb-2">
                <TbTargetArrow
                  className={isMobile ? "col-2" : "col-1"}
                  size={30}
                />
                <h3 className="col-8 sl-title font-bold text-gray-800 ">
                  Approche Projet
                </h3>
              </div>
              <p className="text-gray-700">
                Nos formations s’appuient sur des projets immersifs et des
                scénarios inspirés du monde professionnel, vous permettant de
                développer des compétences directement applicables dans votre
                poste actuel ou futur. À l’issue de la formation, vous serez
                immédiatement opérationnel
              </p>
            </div>
          </div>

          {/* Avantages Exclusifs */}
          <div className="col-lg-6 mb-4">
            <div
              className="gray-bg p-4 rounded-lg shadow-md h-full"
              style={{
                borderRadius: "1rem",
              }}
            >
              <div className="row mb-2">
                <GoThumbsup
                  className={isMobile ? "col-2" : "col-1"}
                  size={30}
                />
                <h3 className="col-8 sl-title font-bold text-gray-800 ">
                  Avantages Exclusifs
                </h3>
              </div>
              <p className="text-gray-700">
                Nous proposons régulièrement des offres exclusives sur nos
                formations afin de rendre l’apprentissage accessible à tous. De
                plus, nous offrons du temps supplémentaire, (
                <strong>jusqu’à une journée</strong>), pour vous permettre de
                mieux vous préparer à votre examen de certification
              </p>
            </div>
          </div>
        </div>

        {/* Third Row */}
        <div className="row">
          {/* Flexibilité */}
          <div className="col-lg-6 mb-4">
            <div
              className="gray-bg p-4 rounded-lg shadow-md h-full"
              style={{
                borderRadius: "1rem",
              }}
            >
              <div className="row mb-2">
                <GrDirections
                  className={isMobile ? "col-2" : "col-1"}
                  size={30}
                />
                <h3 className="col-8 sl-title font-bold text-gray-800 ">
                  Flexibilité
                </h3>
              </div>
              <p className="text-gray-700">
                Vous pouvez suivre toutes nos formations en{" "}
                <strong>présentiel</strong> ou <strong>à distance</strong> avec
                la même qualité d'enseignement. Optez pour un rythme{" "}
                <strong>intensif</strong> ou <strong>modulable</strong> selon
                votre disponibilité, sans compromis sur l'efficacité de
                l'apprentissage
              </p>
            </div>
          </div>

          {/* Financement */}
          <div className="col-lg-6 mb-4">
            <div
              className="gray-bg p-4 rounded-lg shadow-md h-full"
              style={{
                borderRadius: "1rem",
              }}
            >
              <div className="row mb-2">
                <FaMoneyBill
                  className={isMobile ? "col-2" : "col-1"}
                  size={30}
                />
                <h3 className="col-8 sl-title font-bold text-gray-800 ">
                  Financement
                </h3>
              </div>
              <p className="text-gray-700">
                Nos conseillers experts en dispositifs de financement (OPCO,
                CPF, POEI, …) vous aident à identifier la meilleure solution de
                financement, adaptée à votre situation, avec la possibilité de
                couvrir jusqu'à 100 % de vos frais de formation
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhatDistinguishesUs;
