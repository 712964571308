import { Link } from "react-router-dom";

const SingleTestimonial = (props) => {
  return (
    <div className={props.itemClass}>
      <div className="author-desc">
        <div className="desc">
          <img src={props.quoteImage} alt={props.Title} className="quote" />
          <p>{props.Description}</p>
        </div>
        <div className="author-img">
          {props.authorImage ? (
            <img
              src={props.authorImage}
              alt={props.Title}
              style={{
                width: "5rem",
                height: "5rem",
                objectFit: "cover",
                border: "2px solid white",
                backgroundColor: "white",
              }}
            />
          ) : (
            <div
              style={{
                width: "5rem",
                height: "5rem",
                backgroundColor: "white",
                border: "2px solid white",
                borderRadius: "50%",
              }}
            />
          )}
        </div>
      </div>
      <div className="author-part">
        <Link to="#" className="name">
          {props.Title}
        </Link>
        <span className="designation">{props.Designation}</span>
      </div>
    </div>
  );
};
export default SingleTestimonial;
