import React from "react";
import CoursePart from "./CourseSection";
import ScrollToTop from "../../components/Common/ScrollTop";

const CourseMain = ({ thematic }) => {
  return (
    <React.Fragment>
      {/* CoursePart Start */}
      <CoursePart thematic={thematic} />
      {/* CoursePart End */}

      {/* scrolltop-start */}
      <ScrollToTop scrollClassName="scrollup orange-color" />
      {/* scrolltop-end */}
    </React.Fragment>
  );
};

export default CourseMain;
