import React from "react";
import Slider from "react-slick";
import SectionTitle from "../../components/Common/SectionTitle";
import SingleTestimonial from "../../components/Testimonial/SingleTestimonial";

import quote from "../../assets/img/testimonial/main-home/test-2.png";
import author1 from "../../assets/img/testimonial/style5/nizar_smida.png";
import author2 from "../../assets/img/testimonial/style5/aymen_marzouk.png";

const Testimonial = () => {
  const testimonialSettings = {
    dots: true,
    centerMode: false,
    infinite: true,
    arrows: false,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
          dots: false,
        },
      },
    ],
  };

  return (
    <div className="rs-testimonial main-home pt-100 pb-100 md-pt-70 md-pb-70">
      <div className="container">
        <SectionTitle
          sectionClass="sec-title3 mb-50 md-mb-30 text-center"
          subtitleClass="sub-title primary"
          subtitle="TEMOIGNAGE"
          titleClass="title white-color"
          title="Ce que disent les étudiants"
          effectClass="heading-line"
        />
        <Slider {...testimonialSettings}>
          <SingleTestimonial
            itemClass="testi-item"
            quoteImage={quote}
            authorImage={author1}
            Title="Nizar SMIDA"
            Designation="Ingénieur .Net & DevOps"
            Description="J’ai suivi le parcours Cloud & DevOps et ce qui m'a le plus marqué, ce sont les projets immersifs basés sur des scénarios réels d’entreprise. Grâce à cette approche, je me suis senti opérationnel dès le premier jour de ma mission. J'ai pu travailler immédiatement sur Azure et Kubernetes et implémenter l'approche CI/CD en toute autonomie."
          />
          <SingleTestimonial
            itemClass="testi-item"
            quoteImage={quote}
            authorImage={author2}
            Title="Aymen MARZOUK"
            Designation="Ingénieur Azure DevOps"
            Description="J'ai suivi plusieurs formations avec CloudiaSys Academy sur Azure, GCP, la Data et le DevOps pour approfondir mes compétences en DataOps. Ce qui m’a marqué, au-delà de l’expertise des formateurs, c’est l’approche très orientée pratique et le temps qui nous a été consacré à la préparation des examens de certifications."
          />
          <SingleTestimonial
            itemClass="testi-item"
            quoteImage={quote}
            Title="Aymen ZORGANI"
            Designation="Consultant Data Senior"
            Description="J’ai suivi une formation avec CloudiaSys Academy pour renforcer mes connaissances sur Databricks, la plateforme d’analyse et d’intelligence artificielle. Grâce à cette formation et son approche axée sur la pratique, j’ai acquis une meilleure maîtrise de Spark et des pipelines de traitement des données à grande échelle."
          />
        </Slider>
      </div>
    </div>
  );
};

export default Testimonial;
