import React from "react";

const InfoSection = ({
  isMobile,
  image,
  title,
  description,
  imagePosition = "left",
}) => {
  const isImageLeft = imagePosition === "left";

  return (
    <div
      style={{
        padding: isMobile ? "2rem" : "2rem 7rem",
        position: "relative",
        display: "flex",
        flexDirection: isMobile ? "column" : "row",
        alignItems: "center",
        gap: isMobile ? "2rem" : "0",
      }}
    >
      {/* Image Section */}
      <div
        style={{
          flex: isMobile ? "1" : "0.6",
          width: "100%",
          zIndex: 1,
          order: isMobile ? 1 : isImageLeft ? 1 : 2,
        }}
      >
        <img
          src={image}
          alt={title}
          style={{
            width: "100%",
            height: "auto",
            borderRadius: "10px",
            boxShadow: "0 4px 10px rgba(0,0,0,0.1)",
          }}
        />
      </div>

      {/* Content Section */}
      <div
        style={{
          flex: isMobile ? "1" : "0.5",
          backgroundColor: "#f8f9fa",
          padding: "2rem",
          borderRadius: "10px",
          boxShadow: "0 4px 10px rgba(0,0,0,0.1)",
          marginLeft: isMobile ? "0" : isImageLeft ? "-10%" : "0",
          marginRight: isMobile ? "0" : isImageLeft ? "0" : "-10%",
          zIndex: 2,
          position: "relative",
          order: isMobile ? 2 : isImageLeft ? 2 : 1,
        }}
      >
        <h3
          style={{
            color: "#ff5421",
            fontSize: isMobile ? "1.8rem" : "2.2rem",
            fontWeight: "600",
            marginBottom: "1.5rem",
            fontFamily: "Montserrat",
          }}
        >
          {title}
        </h3>
        <p
          style={{
            fontSize: isMobile ? "1.1rem" : "1.3rem",
            lineHeight: "1.6",
            color: "#333333",
            fontFamily: "Montserrat",
          }}
        >
          {description}
        </p>
      </div>
    </div>
  );
};

export default InfoSection;
