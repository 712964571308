import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";

const OverviewPart = () => {
  const { courseRef } = useParams();
  const [courseContent, setCourseContent] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCourseDetails = async () => {
      try {
        setLoading(true);
        const apiUrl = `${process.env.REACT_APP_API_URL}/formations/content/${courseRef}`;

        const response = await axios.get(apiUrl);
        setCourseContent(JSON.parse(response.data));
      } catch (err) {
        console.error("Error fetching course:", err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    if (courseRef) {
      fetchCourseDetails();
    }
  }, [courseRef]);

  if (loading) return <div>Loading course details...</div>;
  if (error) return <div>Error loading course: {error}</div>;
  if (!courseContent) return <div>No course details available</div>;

  const sections = courseContent?.sections
    ? courseContent.sections.sort((a, b) => a.order - b.order)
    : [];

  const renderSectionContent = (section) => {
    if (section.id === "programme") {
      return section?.items?.map((item, index) => (
        <div key={index}>
          <h3
            style={{
              backgroundColor: "#FCE1D8",
              borderRadius: "10px 10px 0 0",
              border: "none",
              padding: "20px 15px",
              fontWeight: "bold",
              marginTop: "3rem",
            }}
          >
            {item.titre}
          </h3>
          <ul className="review-list-with-icon pl-40">
            {item?.modules?.map((module, moduleIndex) => (
              <li key={moduleIndex}>{module}</li>
            ))}
          </ul>
        </div>
      ));
    }

    if (section?.items?.length === 1) {
      if (section.isLink) {
        return (
          <a
            href={`mailto:${section.items[0]}`}
            target="_blank"
            rel="noopener noreferrer"
            style={{
              color: "#ff5421",
              textDecoration: "none",
              fontWeight: 500,
              transition: "all 0.3s ease",
            }}
            onMouseOver={(e) => (e.target.style.color = "#171f32")}
            onMouseOut={(e) => (e.target.style.color = "#ff5421")}
          >
            {section.items[0]}
          </a>
        );
      }
      return <p className="desc">{section.items[0]}</p>;
    }

    return (
      <ul
        className={`pl-40 review-list${section.withIcon ? "-with-icon" : ""}`}
      >
        {section.items.map((item, index) => (
          <li key={index}>{section.withIcon ? <p>{item}</p> : item}</li>
        ))}
      </ul>
    );
  };

  return (
    <div id="course-details-print">
      <div className="content white-bg pt-30">
        <div className="course-overview">
          <div className="course-details">
            <div className="inner-box">
              {sections?.map((section) => (
                <div key={section.id} style={{ margin: "3rem 0" }}>
                  <h4 style={{ margin: "1rem 0" }}>{section.titre}</h4>
                  {renderSectionContent(section)}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OverviewPart;
