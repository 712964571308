import React from "react";
import "./FeatureSingle.css";

const FeatureSingle = (props) => {
  const styles = {
    container: {
      borderRadius: "unset",
      backgroundColor: "white",
      border: "1px solid #ff5421",
    },
    contentPart: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      height: "100%",
    },
    image: {
      marginBottom: "10px",
      color: "red",
    },
    buttonContainer: {
      paddingTop: "20px",
    },
  };

  const handleScrollToSection = () => {
    const targetSection = document.getElementById(props.targetSection);
    if (targetSection) {
      targetSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div
      className={`feature-single ${props.itemClass}`}
      style={styles.container}
    >
      <div className="content-part" style={styles.contentPart}>
        <img
          src={props.itemImg}
          alt={props.itemTitle}
          width={50}
          style={styles.image}
        />
        <h4 className="title">{props.itemTitle}</h4>
        <div>
          <p className="dese">{props.itemDesc}</p>
        </div>
        <div className="sl-btn" style={styles.buttonContainer}>
          <button
            className="readon orange-btn main-home"
            onClick={handleScrollToSection}
          >
            Découvrez les {props.itemTitle}
          </button>
        </div>
      </div>
    </div>
  );
};

export default FeatureSingle;
