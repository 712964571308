import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

const DomainsSingle = ({ id, itemClass, title, image, iconImg, btnText }) => {
  return (
    <div className={itemClass}>
      <div className="cate-images">
        <Link
          to={{
            pathname: "/course",
            state: { domain: id },
          }}
        >
          <img src={image} alt={title} />
        </Link>
      </div>
      <div className="contents">
        <div className="img-part">
          <img src={iconImg} alt={title} />
        </div>
        <div className="content-wrap">
          <h2 className="title">
            <Link
              to={{
                pathname: "/course",
                state: { domain: id },
              }}
            >
              {title}
            </Link>
          </h2>
          {btnText ? (
            <>
              <div className="btn2">
                <Link
                  to={{
                    pathname: "/course",
                    state: { domain: id },
                  }}
                >
                  {btnText}
                </Link>
              </div>
            </>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

DomainsSingle.propTypes = {
  id: PropTypes.string.isRequired,
  itemClass: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  iconImg: PropTypes.string.isRequired,
  btnText: PropTypes.string,
};

export default DomainsSingle;
