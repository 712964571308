import React from "react";
import CourseDetailsPart from "./CourseDetailsPart";
import ScrollToTop from "../../../components/Common/ScrollTop";
import PropTypes from "prop-types";

const CourseDetailsMain = ({ courseDetails }) => {
  return (
    <React.Fragment>
      {/* CourseDetails Start */}
      <CourseDetailsPart courseDetails={courseDetails} />
      {/* CourseDetails End */}

      {/* scrolltop-start */}
      <ScrollToTop scrollClassName="scrollup orange-color" />
      {/* scrolltop-end */}
    </React.Fragment>
  );
};

CourseDetailsMain.propTypes = {
  courseDetails: PropTypes.object.isRequired,
};

export default CourseDetailsMain;
