import React from "react";
import { Link, useLocation } from "react-router-dom";
import kubernetesLogo from "../../../assets/img/menu/kubernetes.png";
import devopsLogo from "../../../assets/img/menu/devops.png";

const MenuItems = (props) => {
  const { parentMenu } = props;
  const location = useLocation();

  return (
    <React.Fragment>
      <li
        className={
          parentMenu === "Bootcamps"
            ? "rs-mega-menu menu-item-has-children current-menu-item"
            : "rs-mega-menu menu-item-has-children"
        }
      >
        <Link to="#">Bootcamps</Link>
        <ul className="mega-menu two-column">
          <li className="mega-menu-container">
            <div className="single-megamenu ">
              <ul className="sub-menu">
                <li
                  style={{
                    textAlign: "center",
                  }}
                >
                  <Link
                    to="/Bootcamps/Kubernetes"
                    className={
                      location.pathname === "/Bootcamps/Kubernetes"
                        ? "active-menu"
                        : ""
                    }
                  >
                    <img
                      src={kubernetesLogo}
                      alt="Orchestration avec Kubernetes"
                    />
                    <div>
                      <span>Orchestration avec Kubernetes</span>
                    </div>
                    <div className="caption">
                      Apprenez le Kubernetes en 4 semaines
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
            <div className="single-megamenu">
              <ul className="sub-menu">
                <li
                  style={{
                    textAlign: "center",
                  }}
                >
                  <Link
                    to="/Bootcamps/Cloud"
                    className={
                      location.pathname === "/Bootcamps/Cloud"
                        ? "active-menu"
                        : ""
                    }
                  >
                    <img src={devopsLogo} alt="Ingénieur Cloud & DevOps" />
                    <div>
                      <span>Ingénieur Cloud & DevOps</span>
                    </div>
                    <div className="caption">
                      Apprenez le DevOps en 10 semaines
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </li>
      <li
        className={
          parentMenu === "Training"
            ? "rs-mega-menu menu-item-has-children current-menu-item"
            : "rs-mega-menu menu-item-has-children"
        }
      >
        <Link to="#">Formations</Link>
        <ul className="mega-menu two-column">
          <li className="mega-menu-container">
            <div className="single-megamenu ">
              <ul className="sub-menu">
                <li>
                  <div className="menu-group-title">
                    <strong>Par thématique</strong>
                  </div>
                </li>
                <li>
                  <Link
                    to={{
                      pathname: "/course",
                      state: { domain: "cloud&Infra" },
                    }}
                    className={
                      location.pathname === "/course" &&
                      location.state?.domain === "cloud&Infra"
                        ? "active-menu"
                        : ""
                    }
                  >
                    Cloud & Infrastructure
                  </Link>
                </li>
                <li>
                  <Link
                    to={{
                      pathname: "/course",
                      state: { domain: "dataAnalytics" },
                    }}
                    className={
                      location.pathname === "/course" &&
                      location.state?.domain === "dataAnalytics"
                        ? "active-menu"
                        : ""
                    }
                  >
                    Data Analytics
                  </Link>
                </li>
                <li>
                  <Link
                    to={{
                      pathname: "/course",
                      state: { domain: "devops" },
                    }}
                    className={
                      location.pathname === "/course" &&
                      location.state?.domain === "devops"
                        ? "active-menu"
                        : ""
                    }
                  >
                    DevOps & DevSecOps
                  </Link>
                </li>
                <li>
                  <Link
                    to={{
                      pathname: "/course",
                      state: { domain: "IA" },
                    }}
                    className={
                      location.pathname === "/course" &&
                      location.state?.domain === "IA"
                        ? "active-menu"
                        : ""
                    }
                  >
                    Intelligence Artificielle
                  </Link>
                </li>
                <li>
                  <Link
                    to={{
                      pathname: "/course",
                      state: { domain: "dataViz" },
                    }}
                    className={
                      location.pathname === "/course" &&
                      location.state?.domain === "dataViz"
                        ? "active-menu"
                        : ""
                    }
                  >
                    Data Visualization
                  </Link>
                </li>
                <li>
                  <Link
                    to={{
                      pathname: "/course",
                      state: { domain: "cybersecurity" },
                    }}
                    className={
                      location.pathname === "/course" &&
                      location.state?.domain === "cybersecurity"
                        ? "active-menu"
                        : ""
                    }
                  >
                    Cyber Sécurité
                  </Link>
                </li>
              </ul>
            </div>
            <div className="single-megamenu">
              <ul className="sub-menu">
                <li>
                  <div className="menu-group-title">
                    <strong>Par plateforme</strong>
                  </div>
                </li>
                <li>
                  <Link
                    to={{
                      pathname: "/course",
                      state: { platform: "azure" },
                    }}
                    className={
                      location.pathname === "/course" &&
                      location.state?.platform === "azure"
                        ? "active-menu"
                        : ""
                    }
                  >
                    Azure ( Microsoft ){" "}
                  </Link>
                </li>
                <li>
                  <Link
                    to={{
                      pathname: "/course",
                      state: { platform: "gcp" },
                    }}
                    className={
                      location.pathname === "/course" &&
                      location.state?.platform === "gcp"
                        ? "active-menu"
                        : ""
                    }
                  >
                    GCP ( Google ){" "}
                  </Link>
                </li>
                <li>
                  <Link
                    to={{
                      pathname: "/course",
                      state: { platform: "kubernetes" },
                    }}
                    className={
                      location.pathname === "/course" &&
                      location.state?.platform === "kubernetes"
                        ? "active-menu"
                        : ""
                    }
                  >
                    Kubernetes ( Linux foundation ){" "}
                  </Link>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </li>
      <li
        className={
          parentMenu === "Certifications"
            ? "menu-item-has-children current-menu-item"
            : "menu-item-has-children"
        }
      >
        <Link to="#">Certifications</Link>
        <ul className="sub-menu">
          <li>
            <div className="menu-group-title">
              <strong>Par Editeur</strong>
            </div>
          </li>
          <li>
            <Link
              to="/certifications/microsoft"
              className={
                location.pathname === "/certifications/microsoft"
                  ? "active-menu"
                  : ""
              }
            >
              Microsoft ( Azure ){" "}
            </Link>
          </li>
          <li>
            <Link
              to="/certifications/google"
              className={
                location.pathname === "/certifications/google"
                  ? "active-menu"
                  : ""
              }
            >
              Google ( GCP ){" "}
            </Link>
          </li>
          <li>
            <Link
              to="/certifications/kubernetes"
              className={
                location.pathname === "/certifications/kubernetes"
                  ? "active-menu"
                  : ""
              }
            >
              Linux foundation ( Kubernetes ){" "}
            </Link>
          </li>
        </ul>
      </li>
      {/* <li
        className={
          parentMenu === "blog"
            ? "menu-item-has-children current-menu-item"
            : "menu-item-has-children"
        }
      >
        <Link to="#">Blog</Link>
        <ul className="sub-menu">
          <li>
            <Link
              to="/blog/Ressources"
              className={
                location.pathname === "/blog/Ressources" ? "active-menu" : ""
              }
            >
              Ressources techniques
            </Link>
          </li>
          <li>
            <Link
              to="/blog/events"
              className={
                location.pathname === "/blog/events" ? "active-menu" : ""
              }
            >
              Evènements
            </Link>
          </li>
        </ul>
      </li> */}
      <li
        className={
          parentMenu === "about"
            ? "rs-mega-menu menu-item-has-children current-menu-item"
            : "rs-mega-menu menu-item-has-children"
        }
      >
        <Link to="#">Nous connaitre</Link>
        <ul className="mega-menu two-column">
          <li className="mega-menu-container">
            <div className="single-megamenu ">
              <ul className="sub-menu">
                <li>
                  <div className="menu-group-title">
                    <strong>CloudiaSys Academy</strong>
                  </div>
                </li>
                <li>
                  <Link
                    to="/about/nousConnaitre"
                    className={
                      location.pathname === "/about/nousConnaitre"
                        ? "active-menu"
                        : ""
                    }
                  >
                    Qui Sommes-nous
                  </Link>
                </li>
                <li>
                  <Link
                    to="/about/methode"
                    className={
                      location.pathname === "/about/methode"
                        ? "active-menu"
                        : ""
                    }
                  >
                    Notre Méthode
                  </Link>
                </li>
                {/* <li>
                  <Link
                    to="/about/partenaires"
                    className={
                      location.pathname === "/about/partenaires"
                        ? "active-menu"
                        : ""
                    }
                  >
                    Nos Partenaires
                  </Link>
                </li> */}
              </ul>
            </div>
            <div className="single-megamenu">
              <ul className="sub-menu">
                <li>
                  <div className="menu-group-title">
                    <strong>Financement</strong>
                  </div>
                </li>
                <li>
                  <Link
                    to="/about/financement"
                    className={
                      location.pathname === "/about/financement"
                        ? "active-menu"
                        : ""
                    }
                  >
                    Dispositifs de financement
                  </Link>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </li>
      <li
        className={
          parentMenu === "contact"
            ? "menu-item-has-children current-menu-item"
            : "menu-item-has-children"
        }
      >
        <Link to="#">Contact</Link>
        <ul className="sub-menu">
          <li>
            <Link
              to="/contact"
              className={location.pathname === "/contact" ? "active-menu" : ""}
            >
              Nous contacter
            </Link>
          </li>
        </ul>
      </li>
    </React.Fragment>
  );
};

export default MenuItems;
