import React, { useState } from "react";
import contactUsImg from "../../assets/img/about/contactUs.jpg";
import { useForm } from "react-hook-form";
import emailjs from "@emailjs/browser";

const ContactUs = () => {
  const [showModal, setShowModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const contentStyle = {
    padding: "2rem 0",
    marginBottom: "5rem",
  };

  const sendEmail = async (formData) => {
    try {
      setIsSubmitting(true);

      const templateParams = {
        from_name: formData.fullName,
        from_email: formData.email,
        message: formData.message,
      };

      const response = await emailjs.send(
        "service_gmail_houcem",
        "template_rcihop6",
        templateParams,
        "-N1Go-oGKNUw9N77D"
      );

      if (response.status === 200) {
        alert("Message envoyé avec succès!");
        setShowModal(false);
        reset();
      }
    } catch (error) {
      console.error("Error sending email:", error);
      alert(
        "Une erreur est survenue lors de l'envoi du message. Veuillez réessayer."
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  const onSubmit = (data) => {
    sendEmail(data);
  };

  return (
    <section
      className="relative w-full"
      style={{
        backgroundImage: `url(${contactUsImg})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
    >
      <style>{`
        @media (min-width: 768px) {
          .content-padding {
            padding: 5rem 0 !important;
          }
        }
        @media (min-width: 1024px) {
          .content-padding {
            padding: 10rem 0 !important;
          }
          .content-title {
            font-size: 3.5rem !important;
          }
        }
      `}</style>

      {/* Dark overlay */}
      <div className="absolute inset-0 bg-black/60" />

      {/* Content */}
      <div
        className="relative h-full z-10 w-full content-padding"
        style={contentStyle}
      >
        <div className="container mx-auto h-full flex flex-col items-center justify-center px-4 text-center">
          <h2 className="content-title text-white font-bold mx-auto leading-tight mb-20">
            Parlez-nous de votre projet de formation, notre équipe est là pour
            vous accompagner à chaque étape.
          </h2>

          <div className="sl-btn" style={{ marginTop: "4rem" }}>
            <a
              className="readon orange-btn main-home"
              href="#"
              onClick={(e) => {
                e.preventDefault();
                setShowModal(true);
              }}
            >
              NOUS CONTACTER
            </a>
          </div>
        </div>
      </div>

      {/* Contact Modal */}
      {showModal && (
        <div
          className="modal-overlay"
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 1000,
          }}
        >
          <div
            className="modal-content"
            style={{
              backgroundColor: "white",
              padding: "2rem",
              borderRadius: "8px",
              width: "90%",
              maxWidth: "500px",
            }}
          >
            <h3 style={{ marginBottom: "1.5rem" }}>Contactez-nous</h3>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div style={{ marginBottom: "1rem" }}>
                <label
                  htmlFor="fullName"
                  style={{ display: "block", marginBottom: "0.5rem" }}
                >
                  Nom complet *
                </label>
                <input
                  type="text"
                  id="fullName"
                  {...register("fullName", { required: true })}
                  style={{
                    width: "100%",
                    padding: "0.5rem",
                    borderRadius: "4px",
                    border: "1px solid #ddd",
                  }}
                />
              </div>
              <div style={{ marginBottom: "1rem" }}>
                <label
                  htmlFor="email"
                  style={{ display: "block", marginBottom: "0.5rem" }}
                >
                  Email *
                </label>
                <input
                  type="email"
                  id="email"
                  {...register("email", { required: true })}
                  style={{
                    width: "100%",
                    padding: "0.5rem",
                    borderRadius: "4px",
                    border: "1px solid #ddd",
                  }}
                />
              </div>
              <div style={{ marginBottom: "1.5rem" }}>
                <label
                  htmlFor="message"
                  style={{ display: "block", marginBottom: "0.5rem" }}
                >
                  Message *
                </label>
                <textarea
                  id="message"
                  {...register("message", { required: true })}
                  rows="4"
                  style={{
                    width: "100%",
                    padding: "0.5rem",
                    borderRadius: "4px",
                    border: "1px solid #ddd",
                  }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: "1rem",
                }}
              >
                <button
                  type="button"
                  onClick={() => setShowModal(false)}
                  disabled={isSubmitting}
                  style={{
                    padding: "0.5rem 1rem",
                    borderRadius: "4px",
                    border: "1px solid #ddd",
                    backgroundColor: "#fff",
                    cursor: isSubmitting ? "not-allowed" : "pointer",
                    opacity: isSubmitting ? 0.7 : 1,
                  }}
                >
                  Fermer
                </button>
                <button
                  type="submit"
                  className="readon orange-btn main-home"
                  disabled={isSubmitting}
                  style={{
                    padding: "0.5rem 1rem",
                    borderRadius: "4px",
                    border: "none",
                    cursor: isSubmitting ? "not-allowed" : "pointer",
                    opacity: isSubmitting ? 0.7 : 1,
                  }}
                >
                  {isSubmitting ? "Envoi en cours..." : "Envoyer"}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </section>
  );
};

export default ContactUs;
