import React from "react";
import { useParams } from "react-router-dom";
import { getDomain } from "../../utils/helpers";
import Header from "../../components/Layout/Header/Header";
import Footer from "../../components/Layout/Footer/Footer";
import OffWrap from "../../components/Layout/Header/OffWrap";
import SearchModal from "../../components/Layout/Header/SearchModal";
import Newsletter from "../../components/Common/Newsletter";
import SiteBreadcrumb from "../../components/Common/Breadcumb";
import CourseMain from "./CourseMain";

// Image
import Logo from "../../assets/img/logo/csa.svg";
import footerLogo from "../../assets/img/logo/csa.svg";

import bannerbg from "../../assets/img/breadcrumbs/breadcrumbs.jpg";

const CourseOne = () => {
  const { thematic } = useParams();

  return (
    <React.Fragment>
      <OffWrap />
      <Header
        parentMenu="course"
        headerNormalLogo={Logo}
        headerStickyLogo={Logo}
        CanvasLogo={Logo}
        mobileNormalLogo={Logo}
        CanvasClass="right_menu_togle hidden-md"
        headerClass="full-width-header header-style1 home8-style4"
        TopBar="enable"
        TopBarClass="topbar-area home8-topbar"
        emailAddress="cloudiasys.academy.yt@gmail.com"
        Location="78 AVENUE DES CHAMPS ELYSEES 75008 PARIS "
      />

      {/* breadcrumb-area-start */}
      <SiteBreadcrumb
        pageTitle={
          thematic ? `Formations en ${getDomain(thematic)}` : "Nos formations"
        }
        innerClass="breadcrumbs-text"
        pageName="Formations"
        breadcrumbsImg={bannerbg}
      />
      {/* breadcrumb-area-start */}

      {/* Course Main */}
      <CourseMain thematic={thematic} />
      {/* Course Main */}

      <Newsletter
        sectionClass="rs-newsletter style1 orange-color mb--90 sm-mb-0 sm-pb-70"
        titleClass="title mb-0 white-color"
      />

      <Footer
        footerClass="rs-footer home9-style main-home"
        footerLogo={footerLogo}
      />
      <SearchModal />
    </React.Fragment>
  );
};

export default CourseOne;
