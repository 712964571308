import React from "react";
import SectionTitle from "../../../components/Common/SectionTitle";

// Team Members
import LearnDiffImage from "../../../assets/img/about/learnDiff_image.png";

const LearnDifferentlySection = () => {
  return (
    <div
      id="rs-team"
      className="rs-team style1 inner-style orange-style pt-102 pb-110 md-pt-64 md-pb-70 "
      style={{
        margin: "2rem",
      }}
    >
      <div className="container">
        <SectionTitle
          sectionClass="sec-title mb-50 md-mb-30 text-center"
          titleClass="title mb-0"
          title="Apprendre autrement"
        />
        <div className="row gray-bg">
          <p className="desc text-justify p-4">
            Chez CloudiaSys Academy, nous avons conçu une méthode de formation
            pensée pour transformer chaque apprenant en professionnel
            opérationnel, prêt à relever les défis concrets du terrain.
          </p>
          <img
            className="responsive-img"
            style={{
              padding: "1rem 5rem",
            }}
            src={LearnDiffImage}
            alt="Train The Trainer"
          />

          <p className="desc text-justify px-4 pt-4">
            Notre méthode d'apprentissage,{" "}
            <strong>axée sur la pratique et la performance</strong>, a été{" "}
            <strong>testée et validée sur des profils très variés</strong> :
            professionnels de l'IT en reconversion, personnes issues d'univers
            totalement différents, comme le théâtre ou la communication,
            souhaitant{" "}
            <strong>se reconvertir dans les métiers du numérique</strong>.
          </p>
          <p className="desc text-justify px-4">
            Grâce à l'accompagnement de nos <strong>formateurs experts</strong>{" "}
            et à notre <strong>pédagogie immersive</strong>, nos apprenants
            <strong>acquièrent rapidement des compétences concrètes</strong> et
            deviennent{" "}
            <strong>opérationnels dès la fin de leur formation</strong>. Ils
            sont ainsi capables d'intégrer efficacement le monde professionnel
            et de relever les défis technologiques actuels.
          </p>
        </div>
      </div>
    </div>
  );
};

export default LearnDifferentlySection;
