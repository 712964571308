import React from "react";
import SectionTitle from "../../components/Common/SectionTitle";

// Team Members
import TTTimage from "../../assets/img/about/trainTheTrainerImage.png";

const TrainTheTrainerSection = () => {
  return (
    <div
      id="rs-team"
      className="rs-team style1 inner-style orange-style pt-102 pb-110 md-pt-64 md-pb-70 "
      style={{
        margin: "2rem",
      }}
    >
      <div className="container">
        <SectionTitle
          sectionClass="sec-title mb-50 md-mb-30 text-center"
          titleClass="title mb-0"
          title="Une Académie Créée par des Experts pour Former les Experts de Demain"
        />
        <div className="row gray-bg">
          <p className="desc text-justify p-4">
            Créée par des <strong>experts en IT</strong> et des formateurs
            <strong>certifiés Microsoft (MCT)</strong>, CloudiaSys Academy s’est
            donnée pour mission de transformer la formation professionnelle en
            informatique. Notre offre forme sur les meilleures technologies
            demandées sur le marché, comme le Cloud Computing, les solutions
            Cloud Native et l’Intelligence Artificielle, et nous croyons
            fermement que la meilleure façon d’apprendre, c’est de faire. C’est
            pourquoi nos programmes reposent sur des{" "}
            <strong>projets concrets et immersifs</strong>, inspirés de
            situations réelles en entreprise.
          </p>
          <img
            className="responsive-img"
            style={{
              padding: "1rem 5rem",
            }}
            src={TTTimage}
            alt="Train The Trainer"
          />

          <p className="desc text-justify p-4">
            Grâce à notre approche{" "}
            <strong>axée sur la pratique et la performance</strong> et nos
            formateurs experts, nos apprenants acquièrent les compétences
            nécessaires pour être{" "}
            <strong>opérationnels dès la fin de leur formation</strong>. Ils
            deviennent ainsi des professionnels capables de relever les défis du
            monde numérique.
          </p>
        </div>
      </div>
    </div>
  );
};

export default TrainTheTrainerSection;
