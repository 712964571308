import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ScrollToTop from "../../../components/Common/ScrollTop";

import "./style.css";

const KubernetesDetailsMain = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Adjust breakpoint as needed
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const descStyle = {
    fontFamily: "Montserrat",
    fontSize: "1.5rem",
    lineHeight: "1.5",
    margin: isMobile ? "0px !important" : "1rem",
    padding: isMobile ? "0 2rem" : "0 7rem",
    textAlign: "justify",
  };

  return (
    <React.Fragment>
      <div style={{ "margin-top": "70px" }}>
        <div className="fixed-training-menu full-size-block bg-color-grey-50 ">
          <div className="width-100 justify-center">
            <p className="desc" style={descStyle}>
              <span style={{ color: "#ff5421" }}>Kubernetes</span> est une
              plateforme Open Source conçue pour automatiser le déploiement, la
              mise à l'échelle et la gestion d'applications conteneurisées. Elle
              permet aux développeurs de déployer et de gérer efficacement des
              applications en conteneurs sur des clusters de machines, qu'il
              s'agisse de machines physiques ou virtuelles.
            </p>

            <p className="desc" style={descStyle}>
              De nombreuses entreprises de premier plan ont adopté Kubernetes
              pour orchestrer leurs applications conteneurisées. Par exemple,
              <span style={{ color: "#ff5421" }}> OpenAI</span> déploie
              actuellement <span style={{ color: "#ff5421" }}>ChatGPT</span> sur
              des clusters Kubernetes, ayant étendu leur infrastructure de{" "}
              <strong>plus de 10 000 nœuds</strong> pour supporter des modèles
              d'IA à grande échelle (
              <a
                href="https://www.cncf.io/kubecon-cloudnativecon-events"
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  color: "blue",
                  textDecoration: "underline",
                }}
              >
                CNCF 2024
              </a>
              ).
            </p>
            <p className="desc" style={descStyle}>
              Cette adoption massive souligne l'importance de Kubernetes,
              rendant les professionnels certifiés sur cette plateforme
              particulièrement recherchés sur le marché de l'emploi.
            </p>
          </div>

          {/* STATISTICS SECTION */}
          <div
            className="statistics-section"
            style={{
              padding: isMobile ? "2rem" : "2rem 7rem",
              marginTop: "5rem",
            }}
          >
            <h1
              className="sl-title text-center"
              style={{
                fontSize: isMobile ? "2.5rem" : "3rem",
                marginBottom: "5rem",
              }}
            >
              Place des Certifications Officielles Kubernetes
            </h1>

            <div
              style={{
                display: "flex",
                flexDirection: isMobile ? "column" : "row",
                justifyContent: "space-between",
                gap: isMobile ? "3rem" : "2rem",
                width: "100%",
              }}
            >
              {/* First Stat */}
              <div
                style={{
                  flex: 1,
                  textAlign: "center",
                  width: isMobile ? "100%" : "auto",
                  padding: isMobile ? "0 1rem" : 0,
                }}
              >
                <h3
                  style={{
                    color: "rgb(232, 99, 69)",
                    fontSize: "4rem",
                    fontWeight: "bold",
                    marginBottom: "1rem",
                  }}
                >
                  +200K
                </h3>
                <p
                  style={{
                    fontSize: "1.2rem",
                    lineHeight: "1.5",
                    fontFamily: "Montserrat",
                  }}
                >
                  de professionnels enregistrés à l'examen Certified Kubernetes
                  Administrator (CKA)
                </p>
                <p
                  style={{
                    color: "#4A90E2",
                    fontSize: "1rem",
                    marginTop: "1rem",
                    fontStyle: "italic",
                  }}
                >
                  CNCF (2024)
                </p>
              </div>

              {/* Second Stat */}
              <div style={{ flex: 1, textAlign: "center" }}>
                <h3
                  style={{
                    color: "rgb(232, 99, 69)",
                    fontSize: "4rem",
                    fontWeight: "bold",
                    marginBottom: "1rem",
                  }}
                >
                  55 %
                </h3>
                <p
                  style={{
                    fontSize: "1.2rem",
                    lineHeight: "1.5",
                    fontFamily: "Montserrat",
                  }}
                >
                  des professionnels ont indiquent que les certifications
                  Kubernetes les ont aidés à décrocher un nouvel emploi
                </p>
                <p
                  style={{
                    color: "#4A90E2",
                    fontSize: "1rem",
                    marginTop: "1rem",
                    fontStyle: "italic",
                  }}
                >
                  CNCF (2024)
                </p>
              </div>

              {/* Third Stat */}
              <div style={{ flex: 1, textAlign: "center" }}>
                <h3
                  style={{
                    color: "rgb(232, 99, 69)",
                    fontSize: "4rem",
                    fontWeight: "bold",
                    marginBottom: "1rem",
                  }}
                >
                  66 %
                </h3>
                <p
                  style={{
                    fontSize: "1.2rem",
                    lineHeight: "1.5",
                    fontFamily: "Montserrat",
                  }}
                >
                  des organisations utilisent Kubernetes en production
                </p>
                <p
                  style={{
                    color: "#4A90E2",
                    fontSize: "1rem",
                    marginTop: "1rem",
                    fontStyle: "italic",
                  }}
                >
                  CNCF (2023)
                </p>
              </div>
            </div>
          </div>

          {/* WHY CLOUDIASYS SECTION */}
          <div
            className="why-cloudiasys-section"
            style={{
              padding: isMobile ? "0" : "2rem 7rem",
              marginTop: isMobile ? "0" : "5rem",
            }}
          >
            <h1
              className="sl-title text-center"
              style={{
                fontSize: isMobile ? "2.5rem" : "3rem",
                marginBottom: "5rem",
              }}
            >
              Pourquoi Préparer votre Certification Kubernetes avec CloudiaSys
              Academy ?
            </h1>

            <div
              style={{
                display: "flex",
                flexDirection: isMobile ? "column" : "row",
                alignItems: "stretch",
                gap: isMobile ? "2rem" : "4rem",
                padding: "2rem",
                borderRadius: "10px",
              }}
            >
              {/* LEFT SECTION */}
              <div
                style={{
                  flex: 1,
                  backgroundColor: "#f8f9fa",
                  padding: "2rem",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  order: isMobile ? "2" : "1",
                }}
              >
                <h3
                  className="sl-title text-center"
                  style={{
                    color: "#4A90E2",
                    fontSize: isMobile ? "1.5rem" : "2.5rem",
                    marginBottom: "2rem",
                  }}
                >
                  Formateurs Experts & Certifiés
                </h3>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "1.5rem",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "flex-start",
                      gap: "1rem",
                      marginTop: "1.5rem",
                    }}
                  >
                    <span style={{ color: "#E86345", fontSize: "1.5rem" }}>
                      ✓
                    </span>
                    <p
                      style={{
                        fontSize: isMobile ? "1rem" : "1.5rem",
                        lineHeight: "1.5",
                        fontFamily: "Montserrat",
                        margin: 0,
                        textAlign: "justify",
                      }}
                    >
                      Chaque formateur détient les certifications officielles
                      CNCF associées aux formations qu'il dispense, garantissant
                      la maîtrise des concepts et des meilleures pratiques
                    </p>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "flex-start",
                      gap: "1rem",
                      marginTop: "1.5rem",
                    }}
                  >
                    <span style={{ color: "#E86345", fontSize: "1.5rem" }}>
                      ✓
                    </span>
                    <p
                      style={{
                        fontSize: isMobile ? "1rem" : "1.5rem",
                        lineHeight: "1.5",
                        fontFamily: "Montserrat",
                        margin: 0,
                        textAlign: "justify",
                      }}
                    >
                      Nos formateurs experts ont plusieurs années d'expérience
                      sur la mise en œuvre des projets en entreprise avec la
                      plateforme Kubernetes
                    </p>
                  </div>
                </div>
              </div>

              {/* RIGHT SECTION */}
              <div
                style={{
                  flex: 1,
                  display: "flex",
                  justifyContent: "center",
                  order: isMobile ? "1" : "2",
                }}
              >
                <img
                  src={require("../../../assets/img/certifications/certified_kubernetes.png")}
                  alt="Certified Kubernetes Trainers"
                  style={{
                    maxWidth: "100%",
                    height: "auto",
                    borderRadius: "8px",
                  }}
                />
              </div>
            </div>
          </div>

          {/* EXCLUSIFS ADVANTAGES SECTION */}
          <div
            className="why-cloudiasys-section"
            style={{
              padding: isMobile ? "0" : "2rem 7rem",
              marginTop: isMobile ? "0" : "5rem",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: isMobile ? "column" : "row",
                alignItems: "stretch",
                gap: isMobile ? "2rem" : "4rem",
                padding: "2rem",
              }}
            >
              {/* LEFT SECTION */}
              <div
                style={{
                  flex: 1,
                  display: "flex",
                  justifyContent: "center",
                  order: isMobile ? "2" : "1",
                }}
              >
                <img
                  src={require("../../../assets/img/certifications/avantages.png")}
                  alt="Avantages Exclusifs"
                  style={{
                    maxWidth: "100%",
                    height: "auto",
                  }}
                />
              </div>

              {/* RIGHT SECTION */}
              <div
                style={{
                  flex: 1,
                  backgroundColor: "#f8f9fa",
                  padding: "2rem",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  order: isMobile ? "1" : "2",
                }}
              >
                <h3
                  className="sl-title text-center"
                  style={{
                    color: "#4A90E2",
                    fontSize: isMobile ? "1.5rem" : "2.5rem",
                    marginBottom: "2rem",
                  }}
                >
                  Avantages Exclusifs
                </h3>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "1.5rem",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "flex-start",
                      gap: "1rem",
                      marginTop: "1.5rem",
                    }}
                  >
                    <span style={{ color: "#E86345", fontSize: "1.5rem" }}>
                      ✓
                    </span>
                    <p
                      style={{
                        fontSize: isMobile ? "1rem" : "1.5rem",
                        lineHeight: "1.5",
                        fontFamily: "Montserrat",
                        margin: 0,
                        textAlign: "justify",
                      }}
                    >
                      Nous offrons du temps supplémentaire, (
                      <strong>jusqu'à une journée</strong>), pour vous permettre
                      de mieux vous préparer à votre examen de certification
                    </p>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "flex-start",
                      gap: "1rem",
                      marginTop: "1.5rem",
                    }}
                  >
                    <span style={{ color: "#E86345", fontSize: "1.5rem" }}>
                      ✓
                    </span>
                    <p
                      style={{
                        fontSize: isMobile ? "1rem" : "1.5rem",
                        lineHeight: "1.5",
                        fontFamily: "Montserrat",
                        margin: 0,
                        textAlign: "justify",
                      }}
                    >
                      Nous organisons des sessions de préparation avec les
                      simulateurs officiels des examens, au cours desquelles nos
                      formateurs partageront leurs meilleures astuces pour
                      réussir votre examen
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* KEY CERTIFICATIONS SECTION */}
          <div
            className="key-certifications-section"
            style={{
              padding: isMobile ? "0 2rem" : "2rem 7rem",
              marginTop: isMobile ? "0" : "5rem",
              marginBottom: "2rem",
            }}
          >
            <h1
              className="sl-title text-center"
              style={{
                fontSize: isMobile ? "2.5rem" : "3rem",
                marginBottom: "5rem",
              }}
            >
              Préparez-vous aux Certifications Clés
            </h1>

            <div
              style={{
                display: "flex",
                flexDirection: isMobile ? "column" : "row",
                gap: isMobile ? "2rem" : "4rem",
                justifyContent: "center",
              }}
            >
              {/* Certified Kubernetes Administrator */}
              <div
                style={{
                  flex: 1,
                  backgroundColor: "#f8f9fa",
                  padding: "2rem",
                  borderRadius: "10px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <img
                  src={require("../../../assets/img/certifications/cka.png")}
                  alt="Certified Kubernetes Administrator"
                  style={{
                    width: "100px",
                    height: "auto",
                    marginBottom: "2rem",
                  }}
                />
                <h3
                  className="sl-title text-center"
                  style={{
                    color: "#4A90E2",
                    fontSize: isMobile ? "1.5rem" : "1.8rem",
                    marginBottom: "1.5rem",
                  }}
                >
                  Certified Kubernetes Administrator
                </h3>
                <div style={{ width: "100%" }}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "flex-start",
                      gap: "1rem",
                      marginBottom: "1.5rem",
                      marginTop: "2rem",
                    }}
                  >
                    <span style={{ color: "#E86345", fontSize: "1.5rem" }}>
                      ✓
                    </span>
                    <p
                      style={{
                        fontSize: isMobile ? "1rem" : "1.5rem",
                        lineHeight: "1.5",
                        fontFamily: "Montserrat",
                        textAlign: "justify",
                        margin: 0,
                      }}
                    >
                      Démontrez vos compétences pour gérer et administrer les
                      clusters Kubernetes
                    </p>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "flex-start",
                      gap: "1rem",
                      marginTop: "2rem",
                    }}
                  >
                    <span style={{ color: "#E86345", fontSize: "1.5rem" }}>
                      ✓
                    </span>
                    <p
                      style={{
                        fontSize: isMobile ? "1rem" : "1.5rem",
                        lineHeight: "1.5",
                        fontFamily: "Montserrat",
                        textAlign: "justify",
                        margin: 0,
                      }}
                    >
                      Idéale pour ceux qui souhaitent démontrer leur expertise
                      en matière de déploiement et de sécurisation des
                      applications et infrastructures sur Kubernetes
                    </p>
                  </div>
                </div>
              </div>

              {/*Certified Kubernetes Security Specialist */}
              <div
                style={{
                  flex: 1,
                  backgroundColor: "#f8f9fa",
                  padding: "2rem",
                  borderRadius: "10px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <img
                  src={require("../../../assets/img/certifications/ckss.png")}
                  alt="Certified Kubernetes Security Specialist"
                  style={{
                    width: "100px",
                    height: "auto",
                    marginBottom: "2rem",
                  }}
                />
                <h3
                  className="sl-title text-center"
                  style={{
                    color: "#4A90E2",
                    fontSize: isMobile ? "1.5rem" : "1.8rem",
                    marginBottom: "1.5rem",
                  }}
                >
                  Certified Kubernetes Security Specialist
                </h3>
                <div style={{ width: "100%" }}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "flex-start",
                      gap: "1rem",
                      marginBottom: "1.5rem",
                      marginTop: "2rem",
                    }}
                  >
                    <span style={{ color: "#E86345", fontSize: "1.5rem" }}>
                      ✓
                    </span>
                    <p
                      style={{
                        fontSize: isMobile ? "1rem" : "1.5rem",
                        lineHeight: "1.5",
                        fontFamily: "Montserrat",
                        textAlign: "justify",
                        margin: 0,
                      }}
                    >
                      Démontrez vos compétences en matière de sécurisation des
                      applications conteneurisées et des plateformes Kubernetes
                    </p>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "flex-start",
                      gap: "1rem",
                      marginTop: "2rem",
                    }}
                  >
                    <span style={{ color: "#E86345", fontSize: "1.5rem" }}>
                      ✓
                    </span>
                    <p
                      style={{
                        fontSize: isMobile ? "1rem" : "1.5rem",
                        lineHeight: "1.5",
                        fontFamily: "Montserrat",
                        textAlign: "justify",
                        margin: 0,
                      }}
                    >
                      Idéale pour ceux souhaitant valider leurs connaissances
                      pratiques de sécurité au sein des environnements
                      Kubernetes
                    </p>
                  </div>
                </div>
              </div>
            </div>

            {/* Call to Action Button */}
            <div
              className="sl-btn mt-40"
              style={{
                width: isMobile ? "100%" : "50%",
                margin: "3rem auto 0",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Link
                to={{
                  pathname: "/course",
                  state: { certification: "linux" },
                }}
                className="readon orange-btn main-home"
                style={{
                  width: isMobile ? "100%" : "auto",
                  textAlign: "center",
                  padding: isMobile ? "1rem" : "1.5rem 3rem",
                  fontSize: isMobile ? "1rem" : "1.2rem",
                  display: "block",
                  textDecoration: "none",
                }}
              >
                CONSULTEZ TOUTES LES CERTIFICATIONS KUBERNETES
              </Link>
            </div>
          </div>
        </div>
      </div>

      {/* scrolltop-start */}
      <ScrollToTop scrollClassName="scrollup orange-color" />
      {/* scrolltop-end */}
    </React.Fragment>
  );
};

export default KubernetesDetailsMain;
