import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import emailjs from "@emailjs/browser";
import { GiCheckMark } from "react-icons/gi";
import img from "../../../assets/img/bootcamps/bootcamp_cloud_header.jpg";

const SiteBreadcrumb = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [showDownloadModal, setShowDownloadModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const sendEmail = async (formData) => {
    try {
      setIsSubmitting(true);

      const templateParams = {
        from_name: formData.fullName,
        from_email: formData.email,
        message: formData.message,
      };

      const response = await emailjs.send(
        "service_gmail_houcem",
        "template_rcihop6",
        templateParams,
        "-N1Go-oGKNUw9N77D"
      );

      if (response.status === 200) {
        alert("Message envoyé avec succès!");
        setShowModal(false);
        reset();
      }
    } catch (error) {
      console.error("Error sending email:", error);
      alert(
        "Une erreur est survenue lors de l'envoi du message. Veuillez réessayer."
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleDownloadRequest = async (data) => {
    try {
      const pdfUrl = `${process.env.PUBLIC_URL}/assets/bootcamps/Bootcamp Cloud & DevOps FR.pdf`;

      const link = document.createElement("a");
      link.href = pdfUrl;
      link.setAttribute("download", "Bootcamp Cloud & DevOps FR.pdf"); // Set the download attribute
      link.setAttribute("target", "_blank"); // Optional: open in new tab if download fails

      // Append to document, click and remove
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setShowDownloadModal(false);
    } catch (error) {
      console.error("Error downloading PDF:", error);
      setShowDownloadModal(false);
    }
  };

  const onSubmit = (data) => {
    sendEmail(data);
  };

  return (
    <div className="big-wrapper">
      <div
        class="card m-5"
        style={{ border: "none", backgroundColor: "#f2f0f4" }}
      >
        <div class="row g-0 justify-content-between">
          <div class="col-md-8 p-4">
            <div class="card-body">
              <h1
                style={{
                  fontSize: isMobile ? "2rem" : "3.7rem",
                  fontWeight: "bold",
                  color: "#333",
                }}
              >
                Devenez Ingénieur Cloud & DevOps en 10 Semaines
              </h1>

              <p
                className="desc"
                style={{
                  fontSize: isMobile ? "1rem" : "1.2rem",
                  marginBottom: "2rem",
                  color: "#666",
                  lineHeight: "1.6",
                }}
              >
                Ce Bootcamp vous prépare aux compétences essentielles pour
                exceller dans les domaines du Cloud Computing et du DevOps
              </p>

              <div style={{ marginBottom: isMobile ? "2rem" : "4rem" }}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "flex-start",
                    gap: "1rem",
                    marginBottom: "1rem",
                  }}
                >
                  <GiCheckMark
                    style={{
                      color: "#ff5421",
                      fontSize: "1.2rem",
                      marginTop: "4px",
                    }}
                  />
                  <p style={{ margin: 0 }}>
                    <strong>Un Bootcamp certifiant</strong> délivré par le
                    Ministère du Travail (RNCP niveau 6 équivalent bac+3/+4)
                  </p>
                </div>

                <div
                  style={{
                    display: "flex",
                    alignItems: "flex-start",
                    gap: "1rem",
                    marginBottom: "1rem",
                  }}
                >
                  <GiCheckMark
                    style={{
                      color: "#ff5421",
                      fontSize: "1.2rem",
                      marginTop: "4px",
                    }}
                  />
                  <p style={{ margin: 0 }}>
                    <strong>Projets Immersifs</strong> pour implémenter des
                    architectures Cloud <strong>Microsoft Azure</strong> et{" "}
                    <strong>Google GCP</strong>
                  </p>
                </div>

                <div
                  style={{
                    display: "flex",
                    alignItems: "flex-start",
                    gap: "1rem",
                    marginBottom: "1rem",
                  }}
                >
                  <GiCheckMark
                    style={{
                      color: "#ff5421",
                      fontSize: "1.2rem",
                      marginTop: "4px",
                    }}
                  />
                  <p style={{ margin: 0 }}>
                    Conçus par nos{" "}
                    <strong>Architectes de Solutions Cloud</strong> pour
                    préparer des professionnels opérationnels
                  </p>
                </div>

                <div
                  style={{
                    display: "flex",
                    alignItems: "flex-start",
                    gap: "1rem",
                    marginBottom: "1rem",
                  }}
                >
                  <GiCheckMark
                    style={{
                      color: "#ff5421",
                      fontSize: "1.2rem",
                      marginTop: "4px",
                    }}
                  />
                  <p style={{ margin: 0 }}>
                    <strong>Préparation</strong> à une certification du Cloud{" "}
                    <strong>Microsoft Azure</strong>
                  </p>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  gap: "1rem",
                  flexDirection: isMobile ? "column" : "row",
                }}
              >
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    setShowModal(true);
                  }}
                  className="readon orange-btn main-home"
                  style={{
                    padding: "1rem 2rem",
                    fontSize: "1rem",
                    border: "none",
                    cursor: "pointer",
                    flex: 0.5,
                  }}
                >
                  NOUS CONTACTER
                </button>

                <button
                  onClick={(e) => {
                    e.preventDefault();
                    setShowDownloadModal(true);
                  }}
                  className="readon orange-btn main-home"
                  style={{
                    padding: "1rem 2rem",
                    fontSize: "1rem",
                    border: "none",
                    cursor: "pointer",
                    flex: 0.5,
                  }}
                >
                  TÉLÉCHARGER LE PROGRAMME
                </button>
                <span style={{ flex: 0.5 }}></span>
              </div>
            </div>
          </div>

          <div class="col-md-3 p-4">
            <img src={img} class="img-fluid" alt="..." />
          </div>
        </div>
      </div>

      {/* Download Modal */}
      {showDownloadModal && (
        <div
          className="modal-overlay"
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 1000,
          }}
        >
          <div
            className="modal-content"
            style={{
              backgroundColor: "white",
              padding: "2rem",
              borderRadius: "8px",
              width: "90%",
              maxWidth: "500px",
            }}
          >
            <h3 style={{ marginBottom: "1.5rem" }}>Télécharger le Programme</h3>
            <form onSubmit={handleSubmit(handleDownloadRequest)}>
              <div style={{ marginBottom: "1rem" }}>
                <label
                  htmlFor="fullName"
                  style={{ display: "block", marginBottom: "0.5rem" }}
                >
                  Prénom & Nom *
                </label>
                <input
                  type="text"
                  id="fullName"
                  {...register("fullName", {
                    required: "Nom complet est requis",
                    minLength: {
                      value: 5,
                      message:
                        "Le nom complet doit contenir au moins 5 caractères",
                    },
                  })}
                  style={{
                    width: "100%",
                    padding: "0.5rem",
                    borderRadius: "4px",
                    border: errors.fullName
                      ? "1px solid red"
                      : "1px solid #ddd",
                  }}
                />
                {errors.fullName && (
                  <span style={{ color: "red", fontSize: "0.8rem" }}>
                    {errors.fullName.message}
                  </span>
                )}
              </div>
              <div style={{ marginBottom: "1rem" }}>
                <label
                  htmlFor="email"
                  style={{ display: "block", marginBottom: "0.5rem" }}
                >
                  Email *
                </label>
                <input
                  type="email"
                  id="email"
                  {...register("email", {
                    required: "Email est requis",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: "Email invalide",
                    },
                  })}
                  style={{
                    width: "100%",
                    padding: "0.5rem",
                    borderRadius: "4px",
                    border: errors.email ? "1px solid red" : "1px solid #ddd",
                  }}
                />

                {errors.email && (
                  <span style={{ color: "red", fontSize: "0.8rem" }}>
                    {errors.email.message}
                  </span>
                )}
              </div>
              <div style={{ marginBottom: "1.5rem" }}>
                <label
                  htmlFor="phoneNumber"
                  style={{ display: "block", marginBottom: "0.5rem" }}
                >
                  Numéro de téléphone *
                </label>
                <input
                  type="tel"
                  id="phoneNumber"
                  {...register("phoneNumber", {
                    required: "Numéro de téléphone est requis",
                    pattern: {
                      value: /^[0-9+\s-]{8,}$/,
                      message: "Numéro de téléphone invalide",
                    },
                  })}
                  style={{
                    width: "100%",
                    padding: "0.5rem",
                    borderRadius: "4px",
                    border: errors.phoneNumber
                      ? "1px solid red"
                      : "1px solid #ddd",
                  }}
                />
                {errors.phoneNumber && (
                  <span style={{ color: "red", fontSize: "0.8rem" }}>
                    {errors.phoneNumber.message}
                  </span>
                )}
              </div>
              <div>
                <input
                  type="hidden"
                  {...register("programme")}
                  defaultValue="Ansible - Automatiser la Gestion des Serveurs"
                />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: "1rem",
                }}
              >
                <button
                  type="button"
                  onClick={() => setShowDownloadModal(false)}
                  disabled={isSubmitting}
                  style={{
                    padding: "0.5rem 1rem",
                    borderRadius: "4px",
                    border: "1px solid #ddd",
                    backgroundColor: "#fff",
                    cursor: isSubmitting ? "not-allowed" : "pointer",
                    opacity: isSubmitting ? 0.7 : 1,
                  }}
                >
                  Fermer
                </button>
                <button
                  type="submit"
                  className="readon orange-btn main-home"
                  disabled={isSubmitting}
                  style={{
                    padding: "0.5rem 1rem",
                    borderRadius: "4px",
                    border: "none",
                    cursor: isSubmitting ? "not-allowed" : "pointer",
                    opacity: isSubmitting ? 0.7 : 1,
                  }}
                >
                  {isSubmitting ? "Traitement..." : "Télécharger"}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {/* Contact Modal */}
      {showModal && (
        <div
          className="modal-overlay"
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 1000,
          }}
        >
          <div
            className="modal-content"
            style={{
              backgroundColor: "white",
              padding: "2rem",
              borderRadius: "8px",
              width: "90%",
              maxWidth: "500px",
            }}
          >
            <h3 style={{ marginBottom: "1.5rem" }}>Contactez-nous</h3>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div style={{ marginBottom: "1rem" }}>
                <label
                  htmlFor="fullName"
                  style={{ display: "block", marginBottom: "0.5rem" }}
                >
                  Nom complet *
                </label>
                <input
                  type="text"
                  id="fullName"
                  name="fullName"
                  {...register("fullName", { required: true })}
                  style={{
                    width: "100%",
                    padding: "0.5rem",
                    borderRadius: "4px",
                    border: "1px solid #ddd",
                  }}
                />
              </div>
              <div style={{ marginBottom: "1rem" }}>
                <label
                  htmlFor="email"
                  style={{ display: "block", marginBottom: "0.5rem" }}
                >
                  Email *
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  {...register("email", { required: true })}
                  style={{
                    width: "100%",
                    padding: "0.5rem",
                    borderRadius: "4px",
                    border: "1px solid #ddd",
                  }}
                />
              </div>
              <div style={{ marginBottom: "1.5rem" }}>
                <label
                  htmlFor="message"
                  style={{ display: "block", marginBottom: "0.5rem" }}
                >
                  Message *
                </label>
                <textarea
                  id="message"
                  name="message"
                  {...register("message", { required: true })}
                  rows="4"
                  style={{
                    width: "100%",
                    padding: "0.5rem",
                    borderRadius: "4px",
                    border: "1px solid #ddd",
                  }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: "1rem",
                }}
              >
                <button
                  type="button"
                  onClick={() => setShowModal(false)}
                  disabled={isSubmitting}
                  style={{
                    padding: "0.5rem 1rem",
                    borderRadius: "4px",
                    border: "1px solid #ddd",
                    backgroundColor: "#fff",
                    cursor: isSubmitting ? "not-allowed" : "pointer",
                    opacity: isSubmitting ? 0.7 : 1,
                  }}
                >
                  Fermer
                </button>
                <button
                  type="submit"
                  className="readon orange-btn main-home"
                  disabled={isSubmitting}
                  style={{
                    padding: "0.5rem 1rem",
                    borderRadius: "4px",
                    border: "none",
                    cursor: isSubmitting ? "not-allowed" : "pointer",
                    opacity: isSubmitting ? 0.7 : 1,
                  }}
                >
                  {isSubmitting ? "Envoi en cours..." : "Envoyer"}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default SiteBreadcrumb;
