import React, { Component } from "react";
import SliderDefault from "../../components/Slider/SliderDefault";
import Courses from "../../CloudiaSys/home/courses/CoursesSection";
import Cta from "../../CloudiaSys/home/CtaSection";
import Testimonial from "./TestimonialSection";
import ScrollToTop from "../../components/Common/ScrollTop";
import Bootcamps from "./BootcampsSection";
import Domains from "./DomainsSection";
import Certifications from "../../CloudiaSys/home/certifications/CertificationsSection";
import Partners from "../../CloudiaSys/home/partners/PartnersSection";

class HomeMain extends Component {
  render() {
    return (
      <React.Fragment>
        {/* SliderDefault-start */}
        <SliderDefault />
        {/* SliderDefault-start */}

        {/* Bootcamps-area-start */}
        <div id="bootcamps">
          <Bootcamps />
        </div>
        {/* Bootcamps-area-end */}

        {/* Domains-area-start */}
        <Domains />
        {/* Domains-area-end */}

        {/* Courses-area-start */}
        <div id="trainings">
          <Courses />
        </div>
        {/* Courses-area-end */}

        {/* Certifications-area-start */}
        <div id="certifications">
          <Certifications />
        </div>
        {/* Certifications-area-end */}

        {/* Cta-area-start */}
        <Cta />
        {/* Cta-area-end */}

        {/* Partners-area-start */}
        <Partners />
        {/* Partners-area-end */}

        {/* FaqSection-area-start */}
        {/* <FaqSection /> */}
        {/* FaqSection-area-end */}

        {/* testmonial-area-start */}
        <div className="mb-40">
          <Testimonial />
        </div>
        {/* testmonial-area-end */}

        {/* blog-area-start */}
        {/* <Blog /> */}
        {/* blog-area-end */}

        {/* scrolltop-start */}
        <ScrollToTop scrollClassName="scrollup orange-color" />
        {/* scrolltop-end */}
      </React.Fragment>
    );
  }
}

export default HomeMain;
