import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import Header from "../../../components/Layout/Header/Header";
import Footer from "../../../components/Layout/Footer/Footer";
import OffWrap from "../../../components/Layout/Header/OffWrap";
import SearchModal from "../../../components/Layout/Header/SearchModal";
import Newsletter from "../../../components/Common/Newsletter";
import SiteBreadcrumb from "../../../components/Common/Breadcumb";
import CourseDetailsMain from "./CourseDetailsMain.js";

// Image
import Logo from "../../../assets/img/logo/csa.svg";
import footerLogo from "../../../assets/img/logo/csa.svg";
import bannerbg from "../../../assets/img/breadcrumbs/2.jpg";

const CourseSingle = () => {
  const { courseRef } = useParams();
  const [courseTitle, setCourseTitle] = useState("");
  const [courseDetails, setCourseDetails] = useState(null);
  useEffect(() => {
    // FETCH COURSE TITLE FROM API with courseRef
    const apiUrl =
      process.env.REACT_APP_API_URL + "/formations/details/" + courseRef;
    axios.get(apiUrl).then((response) => {
      console.log("DEBUG :: courseDetails", apiUrl);
      setCourseDetails(response.data);
      const { titre } = response.data;
      setCourseTitle(titre);
    });
  }, [courseRef]);

  return (
    <React.Fragment>
      <OffWrap />
      <Header
        parentMenu="course"
        headerNormalLogo={Logo}
        headerStickyLogo={Logo}
        CanvasLogo={Logo}
        mobileNormalLogo={Logo}
        CanvasClass="right_menu_togle hidden-md"
        headerClass="full-width-header header-style1 home8-style4"
        TopBar="enable"
        TopBarClass="topbar-area home8-topbar"
        emailAddress="cloudiasys.academy.yt@gmail.com"
        Location="78 AVENUE DES CHAMPS ELYSEES 75008 PARIS "
      />

      {/* breadcrumb-area-start */}
      <SiteBreadcrumb
        pageTitle={courseTitle}
        pageName="Détails formation"
        breadcrumbsImg={bannerbg}
      />
      {/* breadcrumb-area-start */}

      {/* Course Details Main */}
      <CourseDetailsMain courseDetails={courseDetails} />

      <Newsletter
        sectionClass="rs-newsletter style1 gray-bg orange-color mb--90 sm-mb-0 sm-pb-70"
        titleClass="title mb-0 white-color"
      />

      <Footer
        footerClass="rs-footer home9-style main-home"
        footerLogo={footerLogo}
      />
      <SearchModal />
    </React.Fragment>
  );
};

export default CourseSingle;
