import React from "react";
import Header from "../../components/Layout/Header/Header";
import Footer from "../../components/Layout/Footer/Footer";
import OffWrap from "../../components/Layout/Header/OffWrap";
import SearchModal from "../../components/Layout/Header/SearchModal";
import Newsletter from "../../components/Common/Newsletter";
import SiteBreadcrumb from "../../components/Common/Breadcumb";
import AboutMain from "./AboutMain";

// Image
import Logo from "../../assets/img/logo/csa.svg";
import footerLogo from "../../assets/img/logo/csa.svg";

import bannerbg from "../../assets/img/about/bannerBg.png";

const About = () => {
  return (
    <React.Fragment>
      <OffWrap />
      <Header
        parentMenu="about"
        headerNormalLogo={Logo}
        headerStickyLogo={Logo}
        CanvasLogo={Logo}
        mobileNormalLogo={Logo}
        CanvasClass="right_menu_togle hidden-md"
        headerClass="full-width-header header-style1 home8-style4"
        TopBar="enable"
        TopBarClass="topbar-area home8-topbar"
        emailAddress="cloudiasys.academy.yt@gmail.com"
        Location="78 AVENUE DES CHAMPS ELYSEES 75008 PARIS "
      />

      {/* breadcrumb-area-start */}
      <SiteBreadcrumb
        pageTitle="Qui Sommes-Nous ?"
        pageName="Qui Sommes-Nous ?"
        breadcrumbsImg={bannerbg}
      />
      {/* breadcrumb-area-start */}

      {/* About Main */}
      <AboutMain />
      {/* About Main */}

      <Newsletter
        sectionClass="rs-newsletter style1 orange-color mb--90 sm-mb-0 sm-pb-70"
        titleClass="title mb-0 white-color"
      />

      <Footer
        footerClass="rs-footer home9-style main-home"
        footerLogo={footerLogo}
      />
      <SearchModal />
    </React.Fragment>
  );
};

export default About;
