import React from "react";
import { Link } from "react-router-dom";

const CourseSingleOld = (props) => {
  const {
    courseDesc,
    courseClass,
    courseImg,
    courseTitle,
    coursePrix,
    courseThem,
    courseDuree,
  } = props;

  const defaultImg = "default-image-url"; // Replace with your default image URL
  const imageSrc = courseImg
    ? `${process.env.REACT_APP_API_URL}/uploads/${courseImg}`
    : defaultImg;

  const transformTitle = (title) => {
    return title.toLowerCase().replace(/\s+/g, "-");
  };

  const courseUrl = `/training/${transformTitle(courseTitle)}`;

  return (
    <div className={courseClass ? courseClass : "courses-item"}>
      <div className="img-part" style={{ width: "35%" }}>
        <img
          style={{ width: "350px", height: "170px" }}
          src={imageSrc}
          alt={courseTitle}
        />
      </div>
      <div className="content-part" style={{ width: "65%" }}>
        <ul className="meta-part">
          <li>
            <i className="fa fa-wrench mr-5" aria-hidden="true"></i>
            <strong>{courseThem ? courseThem : ""}</strong>
          </li>
        </ul>
        <h3 className="title">
          <Link to={courseUrl}>{courseTitle ?? ""}</Link>
        </h3>
        <span>{courseDesc ? courseDesc : ""}</span>
        <div className="bottom-part">
          <div className="info-meta">
            <ul>
              <strong>
                <li className="user">
                  <i className="fa fa-clock-o fa-1x"></i>
                  <span style={{ fontSize: "17px" }}>
                    {courseDuree ? courseDuree : ""}
                  </span>{" "}
                  |<span className="ml-5">{coursePrix ? coursePrix : ""}</span>
                </li>
              </strong>
            </ul>
          </div>
          <div className="btn-part">
            <Link to={courseUrl}>
              {props.btnText}
              <i className="flaticon-right-arrow"></i>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourseSingleOld;
