import SectionTitle from "../../../components/Common/SectionTitle";
import { GrMonitor, GrCertificate } from "react-icons/gr";
import { TbTargetArrow } from "react-icons/tb";
import { GiCheckMark, GiProgression } from "react-icons/gi";
import { RiBook3Line } from "react-icons/ri";
import { MdOutlineTimer } from "react-icons/md";
import { useState, useEffect } from "react";
import objectOrientedApproach from "../../../assets/img/about/objectOrientedApproach.png";
import progressionMaitrisée from "../../../assets/img/about/progress.png";
import bootcampsIntensifs from "../../../assets/img/about/bootcamps.png";
import efficacitéAvantTout from "../../../assets/img/about/efficace.png";
import environnementsRéels from "../../../assets/img/about/reelEnvs.png";
import certifiedSkills from "../../../assets/img/about/certifiedSkills.png";

const The6Pillars = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div
      id="rs-team"
      className="rs-team style1 inner-style orange-style "
      style={{
        margin: "2rem",
      }}
    >
      <div className="container">
        <SectionTitle
          sectionClass="sec-title mb-50 md-mb-30 text-center"
          titleClass="title mb-0"
          title="Les 6 Piliers de Notre Méthode"
        />

        {/* First Row */}
        <div className="row" style={{ gap: "3rem" }}>
          {/* Apprendre en Faisant */}
          <div
            className="col-lg-6 mb-4"
            style={{
              order: isMobile ? 1 : 1,
            }}
          >
            <div>
              <div className="row mb-2">
                <TbTargetArrow
                  className={`mt-1 ${isMobile ? "col-2" : "col-1"}`}
                  size={40}
                />
                <h2
                  className="col-8 sl-title font-bold "
                  style={{ color: "gray" }}
                >
                  Apprendre en Faisant
                </h2>
              </div>
              <div
                className="p-4 rounded-lg shadow-md h-full"
                style={{
                  borderRadius: "1rem",
                  backgroundColor: "#F1F8FD",
                }}
              >
                <h4 className="text-blue-500" style={{ color: "blue" }}>
                  Une Approche Orientée Projet
                </h4>
                <p className="text-gray-700">
                  Nos formations sont résolument tournées vers la pratique.
                  Chaque module s'appuie sur des projets concrets, des études de
                  cas réelles et des scénarios d'entreprise inspirés du monde
                  professionnel.
                </p>
                <p className="text-gray-700">
                  Grâce à cette immersion, nos apprenants développent des
                  réflexes d'experts, comprennent les enjeux métiers, et savent
                  réagir face aux vraies problématiques du terrain.
                </p>
              </div>
            </div>
          </div>
          {/* Image */}
          <div
            className="col-lg-5 mb-4"
            style={{
              backgroundImage: `url(${objectOrientedApproach})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              borderRadius: "3rem",
              height: isMobile ? "300px" : "400px",
              minHeight: isMobile ? "250px" : "350px",
              order: isMobile ? 2 : 2,
            }}
          ></div>
        </div>

        {/* Second Row */}
        <div
          className="row d-flex align-items-stretch"
          style={{
            marginTop: "4rem",
            gap: "3rem",
          }}
        >
          {/* Image */}
          <div
            className="col-lg-5"
            style={{
              backgroundImage: `url(${progressionMaitrisée})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              borderRadius: "3rem",
              height: isMobile ? "300px" : "400px",
              minHeight: isMobile ? "250px" : "350px",
              order: isMobile ? 2 : 1,
            }}
          ></div>
          {/* Content */}
          <div
            className="col-lg-6 d-flex flex-column"
            style={{
              order: isMobile ? 1 : 2,
            }}
          >
            <div className="row mb-2">
              <GiProgression
                className={`mt-1 ${isMobile ? "col-2" : "col-1"}`}
                size={40}
              />
              <h2
                className="col-8 sl-title font-bold"
                style={{ color: "gray" }}
              >
                Progression Maitrisée
              </h2>
            </div>
            <div
              className="p-4 rounded-lg shadow-md flex-grow-1"
              style={{
                borderRadius: "1rem",
                backgroundColor: "#F1F8FD",
              }}
            >
              <h4 className="text-blue-500" style={{ color: "blue" }}>
                Des Bases aux Concepts Avancés
              </h4>
              <p className="text-gray-700">
                Chaque parcours de formation est structuré de manière
                progressive.
              </p>
              <p className="text-gray-700">
                Nous commençons par les bases indispensables, puis nous guidons
                nos apprenants pas à pas vers des notions avancées, en
                renforçant les acquis à chaque étape.
              </p>
              <p className="text-gray-700">
                Ce rythme maîtrisé permet à chacun, quel que soit son niveau de
                départ, de bâtir une véritable expertise.
              </p>
            </div>
          </div>
        </div>

        {/* Third Row */}
        <div className="row" style={{ gap: "3rem", marginTop: "5rem" }}>
          {/* Content */}
          <div
            className="col-lg-6 mb-4"
            style={{
              order: isMobile ? 1 : 1,
            }}
          >
            <div>
              <div className="row mb-2">
                <RiBook3Line
                  className={`mt-1 ${isMobile ? "col-2" : "col-1"}`}
                  size={40}
                />
                <h2
                  className="col-8 sl-title font-bold "
                  style={{ color: "gray" }}
                >
                  Bootcamps intensifs
                </h2>
              </div>
              <div
                className="p-4 rounded-lg shadow-md h-full"
                style={{
                  borderRadius: "1rem",
                  backgroundColor: "#F1F8FD",
                }}
              >
                <h4 className="text-blue-500" style={{ color: "blue" }}>
                  Des Bootcamps pour se Réinventer
                </h4>
                <p className="text-gray-700">
                  Nos Bootcamps intensifs s'adressent à toutes celles et ceux
                  qui souhaitent se reconvertir dans un domaine d'avenir. Nous
                  les avons conçus comme de véritables accélérateurs de carrière
                  :
                </p>
                <span>
                  <GiCheckMark
                    style={{
                      color: "#ff5421",
                      fontSize: "1.2rem",
                      marginRight: "1rem",
                    }}
                  />
                  Une pédagogie condensée
                </span>
                <br />
                <span>
                  <GiCheckMark
                    style={{
                      color: "#ff5421",
                      fontSize: "1.2rem",
                      marginRight: "1rem",
                    }}
                  />
                  Un focus sur l'essentiel
                </span>
                <br />
                <span>
                  <GiCheckMark
                    style={{
                      color: "#ff5421",
                      fontSize: "1.2rem",
                      marginRight: "1rem",
                    }}
                  />
                  Et une mise en pratique immédiate
                </span>
                <br />
                <br />
                <p className="text-gray-700">
                  À la fin du programme, les participants sont prêts à intégrer
                  le marché de l'emploi, avec les compétences clés demandées par
                  les recruteurs.
                </p>
              </div>
            </div>
          </div>
          {/* Image */}
          <div
            className="col-lg-5 mb-4"
            style={{
              backgroundImage: `url(${bootcampsIntensifs})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              borderRadius: "3rem",
              height: isMobile ? "300px" : "400px",
              minHeight: isMobile ? "250px" : "350px",
              order: isMobile ? 2 : 2,
            }}
          ></div>
        </div>

        {/* Fourth Row */}
        <div
          className="row d-flex align-items-stretch"
          style={{
            marginTop: "4rem",
            gap: "3rem",
          }}
        >
          {/* Image */}
          <div
            className="col-lg-5"
            style={{
              backgroundImage: `url(${efficacitéAvantTout})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              borderRadius: "3rem",
              height: isMobile ? "300px" : "400px",
              minHeight: isMobile ? "250px" : "350px",
              order: isMobile ? 2 : 1,
            }}
          ></div>
          {/* Content */}
          <div
            className="col-lg-6 d-flex flex-column"
            style={{
              order: isMobile ? 1 : 2,
            }}
          >
            <div className="row mb-2">
              <MdOutlineTimer
                className={`mt-1 ${isMobile ? "col-2" : "col-1"}`}
                size={40}
              />
              <h2
                className="col-8 sl-title font-bold"
                style={{ color: "gray" }}
              >
                L'Efficacité avant tout
              </h2>
            </div>
            <div
              className="p-4 rounded-lg shadow-md flex-grow-1"
              style={{
                borderRadius: "1rem",
                backgroundColor: "#F1F8FD",
              }}
            >
              <h4 className="text-blue-500" style={{ color: "blue" }}>
                Devenir Opérationnel, Rapidement
              </h4>
              <p className="text-gray-700">
                Nous savons que votre temps est précieux. C'est pourquoi nous
                allons à l'essentiel, sans jamais sacrifier la qualité.
                <br></br>
                Chaque module est optimisé pour maximiser l'impact pédagogique :
              </p>
              <span>
                <GiCheckMark
                  style={{
                    color: "#ff5421",
                    fontSize: "1.2rem",
                    marginRight: "1rem",
                  }}
                />
                Pas de théorie inutile
              </span>
              <br />
              <span>
                <GiCheckMark
                  style={{
                    color: "#ff5421",
                    fontSize: "1.2rem",
                    marginRight: "1rem",
                  }}
                />
                Des compétences directement utilisables en entreprise
              </span>
              <br />
              <span>
                <GiCheckMark
                  style={{
                    color: "#ff5421",
                    fontSize: "1.2rem",
                    marginRight: "1rem",
                  }}
                />
                Une autonomie acquise dès la fin de la formation.
              </span>
              <br />
            </div>
          </div>
        </div>

        {/* Fifth Row */}
        <div className="row" style={{ gap: "3rem", marginTop: "5rem" }}>
          {/* Content */}
          <div
            className="col-lg-6 mb-4"
            style={{
              order: isMobile ? 1 : 1,
            }}
          >
            <div>
              <div className="row mb-2">
                <GrMonitor
                  className={`mt-1 ${isMobile ? "col-2" : "col-1"}`}
                  size={40}
                />
                <h2
                  className="col-8 sl-title font-bold "
                  style={{ color: "gray" }}
                >
                  Environnements Réels
                </h2>
              </div>
              <div
                className="p-4 rounded-lg shadow-md h-full"
                style={{
                  borderRadius: "1rem",
                  backgroundColor: "#F1F8FD",
                }}
              >
                <h4 className="text-blue-500" style={{ color: "blue" }}>
                  Grâce aux Licences et Abonnements Cloud
                </h4>
                <p className="text-gray-700">
                  Nous pensons que l'apprentissage passe par la réalisation de
                  projets dans un cadre réel.
                </p>
                <p className="text-gray-700">
                  C'est pourquoi nous investissons dans des abonnements
                  professionnels, comme Microsoft Azure, pour que chaque
                  apprenant bénéficie :
                </p>
                <span>
                  <GiCheckMark
                    style={{
                      color: "#ff5421",
                      fontSize: "1.2rem",
                      marginRight: "1rem",
                    }}
                  />
                  d'un accès à des environnements cloud réels
                </span>
                <br />
                <span>
                  <GiCheckMark
                    style={{
                      color: "#ff5421",
                      fontSize: "1.2rem",
                      marginRight: "1rem",
                    }}
                  />
                  de licences professionnelles
                </span>
                <br />
                <span>
                  <GiCheckMark
                    style={{
                      color: "#ff5421",
                      fontSize: "1.2rem",
                      marginRight: "1rem",
                    }}
                  />
                  d'exercices pratiques dans des conditions identiques à celles
                  du monde de l'entreprise
                </span>
                <br />
              </div>
            </div>
          </div>
          {/* Image */}
          <div
            className="col-lg-5 mb-4"
            style={{
              backgroundImage: `url(${environnementsRéels})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              borderRadius: "3rem",
              height: isMobile ? "300px" : "400px",
              minHeight: isMobile ? "250px" : "350px",
              order: isMobile ? 2 : 2,
            }}
          ></div>
        </div>

        {/* Sixth Row */}
        <div
          className="row d-flex align-items-stretch"
          style={{
            marginTop: "4rem",
            gap: "3rem",
          }}
        >
          {/* Image */}
          <div
            className="col-lg-5"
            style={{
              backgroundImage: `url(${certifiedSkills})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              borderRadius: "3rem",
              height: isMobile ? "300px" : "400px",
              minHeight: isMobile ? "250px" : "350px",
              order: isMobile ? 2 : 1,
            }}
          ></div>
          {/* Content */}
          <div
            className="col-lg-6 d-flex flex-column"
            style={{
              order: isMobile ? 1 : 2,
            }}
          >
            <div className="row mb-2">
              <GrCertificate
                className={`mt-1 ${isMobile ? "col-2" : "col-1"}`}
                size={40}
              />
              <h2
                className="col sl-title font-bold"
                style={{
                  color: "gray",
                }}
              >
                Certifier les Compétences
              </h2>
            </div>
            <div
              className="p-4 rounded-lg shadow-md flex-grow-1"
              style={{
                borderRadius: "1rem",
                backgroundColor: "#F1F8FD",
              }}
            >
              <h4 className="text-blue-500" style={{ color: "blue" }}>
                Préparation Renforcée aux Certifications Officielles
              </h4>
              <p className="text-gray-700">
                Après avoir suivi avec nous le programme officiel d'un éditeur
                tel que Microsoft ou la CNCF, nos apprenants bénéficient de
                temps supplémentaire (jusqu'à une journée) dédié à la
                préparation des examens, incluant des simulateurs, des tests
                blancs, ainsi que les meilleures astuces pour réussir leur
                certification tel que la CKA ou Azure Administrator.<br></br>
                Ces certifications sont de véritables leviers d'employabilité et
                valorisent immédiatement un profil auprès des recruteurs.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default The6Pillars;
