import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { getDomain } from "../../utils/helpers";

const CourseSingleTwo = (props) => {
  const {
    courseClass,
    courseImg,
    courseTitle,
    catLink,
    coursePrice,
    courseCategory,
    level,
    courseRef,
  } = props;
  return (
    <div className={courseClass ?? "courses-item"}>
      <div className="img-part" style={{ minWidth: "-webkit-fit-content" }}>
        <img
          src={courseImg}
          alt={courseTitle}
          style={{
            objectFit: "cover",
          }}
        />
      </div>
      <div className="content-part">
        <ul className="meta-part">
          <li>
            <span className="price">{coursePrice ?? "Free"}</span>
          </li>
          <li>
            <Link className="categorie" to={catLink ?? "course-categories"}>
              {getDomain(courseCategory) ?? "Web Development"}
            </Link>
          </li>
        </ul>
        <h3 className="title">
          <Link to={`/course/course-single/${courseRef}`}>
            {courseTitle ?? "Become a PHP Master and Make Money Fast"}
          </Link>
        </h3>
        <div className="bottom-part">
          <div className="info-meta">
            <ul>
              {/* <li className="user">
                <i className="fa fa-user"></i> {userCount ? userCount : "245"}
              </li> */}
              <li className="ratings">
                <i
                  className="fa fa-signal"
                  style={{ marginRight: "5px", color: "#555" }}
                ></i>
                <span>{level ?? "Fundamental"}</span>
              </li>
            </ul>
          </div>
          <div className="btn-part">
            <Link to={`/course/course-single/${courseRef}`}>
              {props.btnText}
              <i className="flaticon-right-arrow"></i>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

CourseSingleTwo.propTypes = {
  courseClass: PropTypes.string,
  courseImg: PropTypes.string.isRequired,
  courseTitle: PropTypes.string.isRequired,
  catLink: PropTypes.string,
  coursePrice: PropTypes.string,
  courseCategory: PropTypes.string,
  level: PropTypes.string,
  courseRef: PropTypes.string.isRequired,
  btnText: PropTypes.string,
};

export default CourseSingleTwo;
