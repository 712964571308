import React from "react";
import { getDomain, getPlatform, getCertification } from "../../utils/helpers";

const CourseSidebar = ({ selectedFilters, onFiltersChange, stats }) => {
  const handleThematicChange = (value, checked) => {
    const newThematiques = checked
      ? [...selectedFilters.thematiques, value]
      : selectedFilters.thematiques.filter((t) => t !== value);

    onFiltersChange({
      ...selectedFilters,
      thematiques: newThematiques,
    });
  };

  const handlePlatformChange = (value, checked) => {
    const newPlatforms = checked
      ? [...selectedFilters.plateformes, value]
      : selectedFilters.plateformes.filter((p) => p !== value);

    onFiltersChange({
      ...selectedFilters,
      plateformes: newPlatforms,
    });
  };

  const handleCertificationChange = (value, checked) => {
    const newCertifications = checked
      ? [...selectedFilters.certifications, value]
      : selectedFilters.certifications.filter((c) => c !== value);

    onFiltersChange({
      ...selectedFilters,
      certifications: newCertifications,
    });
  };

  return (
    <div className="widget-area">
      {/* <div className="search-widget mb-50">
        <h3 className="widget-title">Trouver une formation</h3>
        <div className="search-wrap">
          <input
            type="search"
            placeholder="Rechercher..."
            name="s"
            className="search-input"
            value=""
          />
          <button type="submit" value="Search">
            <i className="flaticon-search"></i>
          </button>
        </div>
      </div> */}
      <div className="widget-archives mb-50">
        <h3 className="widget-title">Filtrer par</h3>
        <div className="filter-widget">
          <div className="filter-form">
            <form method="post" action="mailer.php">
              {/* domain filter */}
              <div className="single-filter mb-30">
                <h5>Domaines</h5>
                <div className="check-box form-group mb-0">
                  <input
                    type="checkbox"
                    name="course"
                    id="check1"
                    value="cloud&Infra"
                    checked={selectedFilters.thematiques.includes(
                      "cloud&Infra"
                    )}
                    onChange={(e) =>
                      handleThematicChange("cloud&Infra", e.target.checked)
                    }
                  />
                  <label htmlFor="check1">
                    {getDomain("cloud&Infra")} (
                    {stats.thematiques["cloud&Infra"] || 0})
                  </label>
                </div>

                <div className="check-box form-group mb-0">
                  <input
                    type="checkbox"
                    name="course"
                    id="check3"
                    value="devops"
                    checked={selectedFilters.thematiques.includes("devops")}
                    onChange={(e) =>
                      handleThematicChange("devops", e.target.checked)
                    }
                  />
                  <label htmlFor="check3">
                    {getDomain("devops")} ({stats.thematiques["devops"] || 0})
                  </label>
                </div>

                <div className="check-box form-group mb-0">
                  <input
                    type="checkbox"
                    name="course"
                    id="check4"
                    value="IA"
                    checked={selectedFilters.thematiques.includes("IA")}
                    onChange={(e) =>
                      handleThematicChange("IA", e.target.checked)
                    }
                  />
                  <label htmlFor="check4">
                    {getDomain("IA")} ({stats.thematiques["IA"] || 0})
                  </label>
                </div>

                <div className="check-box form-group mb-0">
                  <input
                    type="checkbox"
                    name="course"
                    id="check5"
                    value="dataViz"
                    checked={selectedFilters.thematiques.includes("dataViz")}
                    onChange={(e) =>
                      handleThematicChange("dataViz", e.target.checked)
                    }
                  />
                  <label htmlFor="check5">
                    {getDomain("dataViz")} ({stats.thematiques["dataViz"] || 0})
                  </label>
                </div>

                <div className="check-box form-group mb-0">
                  <input
                    type="checkbox"
                    name="course"
                    id="check2"
                    value="dataAnalytics"
                    checked={selectedFilters.thematiques.includes(
                      "dataAnalytics"
                    )}
                    onChange={(e) =>
                      handleThematicChange("dataAnalytics", e.target.checked)
                    }
                  />
                  <label htmlFor="check2">
                    {getDomain("dataAnalytics")} (
                    {stats.thematiques["dataAnalytics"] || 0})
                  </label>
                </div>

                <div className="check-box form-group mb-0">
                  <input
                    type="checkbox"
                    name="course"
                    id="check6"
                    value="cybersecurity"
                    checked={selectedFilters.thematiques.includes(
                      "cybersecurity"
                    )}
                    onChange={(e) =>
                      handleThematicChange("cybersecurity", e.target.checked)
                    }
                  />
                  <label htmlFor="check6">
                    {getDomain("cybersecurity")} (
                    {stats.thematiques["cybersecurity"] || 0})
                  </label>
                </div>
              </div>

              {/* platform filter */}
              <div className="single-filter mb-30">
                <h5>Plateformes</h5>
                <div className="check-box form-group mb-0">
                  <input
                    type="checkbox"
                    name="course"
                    id="platform1"
                    value="microsoft"
                    checked={selectedFilters.plateformes.includes("microsoft")}
                    onChange={(e) =>
                      handlePlatformChange("microsoft", e.target.checked)
                    }
                  />
                  <label htmlFor="platform1">
                    {getPlatform("microsoft")} (
                    {stats.plateformes["microsoft"] || 0})
                  </label>
                </div>

                <div className="check-box form-group mb-0">
                  <input
                    type="checkbox"
                    name="course"
                    id="platform2"
                    value="gcp"
                    checked={selectedFilters.plateformes.includes("gcp")}
                    onChange={(e) =>
                      handlePlatformChange("gcp", e.target.checked)
                    }
                  />
                  <label htmlFor="platform2">
                    {getPlatform("gcp")} ({stats.plateformes["gcp"] || 0})
                  </label>
                </div>

                <div className="check-box form-group mb-0">
                  <input
                    type="checkbox"
                    name="course"
                    id="platform3"
                    value="kubernetes"
                    checked={selectedFilters.plateformes.includes("kubernetes")}
                    onChange={(e) =>
                      handlePlatformChange("kubernetes", e.target.checked)
                    }
                  />
                  <label htmlFor="platform3">
                    {getPlatform("kubernetes")} (
                    {stats.plateformes["kubernetes"] || 0})
                  </label>
                </div>
              </div>

              {/* Certification filter */}
              <div className="single-filter mb-30">
                <h5>Certification</h5>
                <div className="check-box form-group mb-0">
                  <input
                    type="checkbox"
                    name="course"
                    id="certification1"
                    value="microsoft"
                    checked={selectedFilters.certifications.includes(
                      "microsoft"
                    )}
                    onChange={(e) =>
                      handleCertificationChange("microsoft", e.target.checked)
                    }
                  />
                  <label htmlFor="certification1">
                    {getCertification("microsoft")} (
                    {stats.certifications["microsoft"] || 0})
                  </label>
                </div>

                <div className="check-box form-group mb-0">
                  <input
                    type="checkbox"
                    name="course"
                    id="certification2"
                    value="google"
                    checked={selectedFilters.certifications.includes("google")}
                    onChange={(e) =>
                      handleCertificationChange("google", e.target.checked)
                    }
                  />
                  <label htmlFor="certification2">
                    {getCertification("google")} (
                    {stats.certifications["google"] || 0})
                  </label>
                </div>

                <div className="check-box form-group mb-0">
                  <input
                    type="checkbox"
                    name="course"
                    id="certification3"
                    value="linux"
                    checked={selectedFilters.certifications.includes("linux")}
                    onChange={(e) =>
                      handleCertificationChange("linux", e.target.checked)
                    }
                  />
                  <label htmlFor="certification3">
                    {getCertification("linux")} (
                    {stats.certifications["linux"] || 0})
                  </label>
                </div>
              </div>

              {/* level filter */}
              <div className="single-filter mb-30">
                <h5>Niveau</h5>
                <div className="radio-box form-group">
                  <input
                    type="radio"
                    name="skill"
                    id="type1"
                    value="beginer"
                    defaultChecked
                  />
                  <label htmlFor="type1">Fondamentaux</label>
                </div>

                <div className="radio-box form-group">
                  <input
                    type="radio"
                    name="skill"
                    id="type2"
                    value="intermediate"
                  />
                  <label htmlFor="type2">Intermediaire</label>
                </div>

                <div className="radio-box form-group">
                  <input type="radio" name="skill" id="type3" value="expert" />
                  <label htmlFor="type3">Avancé</label>
                </div>
              </div>

              {/* price filter */}
              <div className="single-filter mb-30">
                <h5>Prix</h5>
                <div className="radio-box form-group">
                  <input type="radio" name="price" id="type4" value="free" />
                  <label for="type4">Gratuit (0)</label>
                </div>

                <div className="radio-box form-group">
                  <input
                    type="radio"
                    name="price"
                    id="type5"
                    value="paid"
                    checked
                  />
                  <label for="type5">Payant (30)</label>
                </div>
              </div>

              {/* duration filter */}
              <div className="single-filter mb-30">
                <h5>Durée</h5>
                <div className="radio-box form-group">
                  <input
                    type="radio"
                    name="duration"
                    id="type6"
                    value="six"
                    checked
                  />
                  <label for="type6"> {"<3 jours"} (15)</label>
                </div>

                <div className="radio-box form-group">
                  <input type="radio" name="duration" id="type7" value="paid" />
                  <label for="type7">3+ jours (15)</label>
                </div>

                <div className="radio-box form-group">
                  <input type="radio" name="duration" id="type8" value="paid" />
                  <label for="type8">5+ jours (5)</label>
                </div>
              </div>

              {/* <div className="single-filter mb-30">
                  <h5>Instructor</h5>
                  <div className="check-box form-group mb-0">
                    <input
                      type="checkbox"
                      id="check8"
                      name="check8"
                      value="check8"
                      checked
                    />
                    <label for="check8">Stuard (5)</label>
                  </div>
                  <div className="check-box form-group mb-0">
                    <input
                      type="checkbox"
                      id="check9"
                      name="check9"
                      value="check9"
                    />
                    <label for="check9">Benjamin (4)</label>
                  </div>
                  <div className="check-box form-group mb-0">
                    <input
                      type="checkbox"
                      id="check10"
                      name="check10"
                      value="check10"
                    />
                    <label for="check10">Mickel (7)</label>
                  </div>
                  <div className="check-box form-group mb-0">
                    <input
                      type="checkbox"
                      id="check11"
                      name="check11"
                      value="check11"
                    />
                    <label for="check11">Johnson (2)</label>
                  </div>
                  <div className="check-box form-group mb-0">
                    <input
                      type="checkbox"
                      id="check12"
                      name="check12"
                      value="check12"
                    />
                    <label for="check12">Elijabeth (5)</label>
                  </div>
                  <div className="check-box form-group mb-0">
                    <input
                      type="checkbox"
                      id="check13"
                      name="check13"
                      value="check13"
                    />
                    <label for="check13">Rebeka (4)</label>
                  </div>
                </div> */}

              {/* <div className="form-group mb-0">
                <input
                  className="readon2 orange"
                  type="submit"
                  value="Filtrer"
                />
              </div> */}
            </form>
          </div>
        </div>
      </div>
      {/* <div className="widget-archives md-mb-50">
          <h3 className="widget-title">Course Categories</h3>
          <ul className="categories">
            <li>
              <Link to="/course-categories">College</Link>
            </li>
            <li>
              <Link to="/course-categories">High School</Link>
            </li>
            <li>
              <Link to="/course-categories">Primary</Link>
            </li>
            <li>
              <Link to="/course-categories">School</Link>
            </li>
            <li>
              <Link to="/course-categories">University</Link>
            </li>
          </ul>
        </div> */}
    </div>
  );
};

export default CourseSidebar;
