import React from "react";
import OverviewPart from "./OverviewPart.js";

const CourseDetailsTab = () => {
  return (
    <div className="intro-info-tabs">
      <OverviewPart />
    </div>
  );
};

export default CourseDetailsTab;
