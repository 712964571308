import React from "react";
import PropTypes from "prop-types";
import CourseSidebar from "./CourseSidebar";
import CourseDetailsTab from "./CourseDetailsTab";

const CourseDetailsPart = ({ courseDetails }) => {
  return (
    <div className="intro-section gray-bg pt-94 pb-100 md-pt-80 md-pb-80 loaded">
      <div className="container">
        <div className="row clearfix">
          <div className="col-lg-8 md-mb-50">
            <CourseDetailsTab />
          </div>
          <div className="video-column col-lg-4">
            <CourseSidebar courseDetails={courseDetails} />
          </div>
        </div>
      </div>
    </div>
  );
};

CourseDetailsPart.propTypes = {
  courseDetails: PropTypes.object.isRequired,
};

export default CourseDetailsPart;
