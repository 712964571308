import React from "react";
import { Link } from "react-router-dom";
import SectionTitle from "../../../components/Common/SectionTitle";
import CourseSingle from "./CourseSingle";

// Courses Image
import image1 from "../../../assets/img/courses/main-home/featured_course_1.jpg";
import image2 from "../../../assets/img/courses/main-home/featured_course_2.jpg";
import image3 from "../../../assets/img/courses/main-home/featured_course_3.jpg";
import image4 from "../../../assets/img/courses/main-home/featured_course_4.jpg";
import image5 from "../../../assets/img/courses/main-home/featured_course_5.jpg";
import image6 from "../../../assets/img/courses/main-home/featured_course_6.jpg";

const Courses = () => {
  return (
    <div className="rs-popular-courses main-home event-bg pt-100 pb-100 md-pt-70 md-pb-70">
      <div className="container">
        <SectionTitle
          sectionClass="sec-title3 text-center mb-44"
          subtitleClass="sub-title"
          subtitle="NOS FORMATIONS"
          titleClass="title black-color"
          title="Découvrez nos formations populaires"
        />
        <div className="row">
          <div className="col-lg-4 col-md-6 mb-30">
            <CourseSingle
              itemClass="courses-item"
              image={image1}
              title="Terraform, Automatisation et Gestion d'Infrastructure"
              category="Cloud & Infra"
              pricing="1690 €"
              duration="2 jours"
              level="Fondamentaux"
              courseLink="/course/course-single/TF101"
            />
          </div>
          <div className="col-lg-4 col-md-6 mb-30">
            <CourseSingle
              itemClass="courses-item"
              image={image2}
              title="Orchestration des Conteneurs avec Azure Kubernetes Service"
              category="Cloud & Infra"
              pricing="2490 €"
              duration="3 jours"
              level="Intermédiaire"
              courseLink="/course/course-single/AK201"
            />
          </div>
          <div className="col-lg-4 col-md-6 mb-30">
            <CourseSingle
              itemClass="courses-item"
              image={image3}
              title="Microsoft Power BI, Prise en Main pour l'Analyse de Données"
              category="DataViz"
              pricing="1590 €"
              duration="2 jours"
              level="Intermédiaire"
              courseLink="/course/course-single/PB101"
            />
          </div>
          <div className="col-lg-4 col-md-6 md-mb-30">
            <CourseSingle
              itemClass="courses-item"
              image={image4}
              title="Ansible, Automatiser la Gestion des Serveurs"
              category="Cloud & Infra"
              pricing="1590 €"
              duration="2 jours"
              level="Intermédiaire"
              courseLink="/course/course-single/AS101"
            />
          </div>
          <div className="col-lg-4 col-md-6 sm-mb-30">
            <CourseSingle
              itemClass="courses-item"
              image={image5}
              title="IA Générative et LLM, les Fondamentaux"
              category="IA"
              pricing="2010 €"
              duration="2 jours"
              level="Intermédiaire"
              courseLink="/course/course-single/IG101"
            />
          </div>
          <div className="col-lg-4 col-md-6">
            <CourseSingle
              itemClass="courses-item"
              image={image6}
              title="Microsoft Fabric pour l'Analyse et l'Ingénierie de Données"
              category="Data Analytics"
              pricing="1590 €"
              duration="2 jours"
              level="Intermédiaire"
              courseLink="/course/course-single/FB101"
            />
          </div>
          <div className="text-center mt-30">
            <Link className="readon orange-btn main-home" to="/course">
              Consultez toutes nos Formations
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Courses;
