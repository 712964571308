import React from "react";
import SingleTeam from "./SingleTeam";
import SectionTitle from "../../components/Common/SectionTitle";

// Team Members
import manPlaceholder from "../../assets/img/about/placeholder-man.jpg";
import womanPlaceholder from "../../assets/img/about/placeholder-woman.jpg";

const Team = () => {
  return (
    <div
      id="rs-team"
      className="rs-team style1 inner-style orange-style pt-102 pb-110 md-pt-64 md-pb-70 gray-bg mx-40"
    >
      <div className="container">
        <SectionTitle
          sectionClass="sec-title mb-50 md-mb-30 text-center"
          subtitleClass="sub-title orange"
          titleClass="title mb-0"
          title="Notre Equipe Managériale"
        />
        <div className="row">
          <div className="col-lg-4 col-md-6 mb-30">
            <SingleTeam
              itemClass="team-item"
              Image={manPlaceholder}
              Title="Karim BELLIR"
              Designation="Président & Co-fondateur"
            />
          </div>
          <div className="col-lg-4 col-md-6 mb-30">
            <SingleTeam
              teamClass="team-item"
              Image={manPlaceholder}
              Title="Ahmed LAHMAR"
              Designation="Directeur Général & Co-fondateur"
            />
          </div>
          <div className="col-lg-4 col-md-6 mb-30">
            <SingleTeam
              teamClass="team-item"
              Image={manPlaceholder}
              Title="Zine Eddine ATIK"
              Designation="Directeur Général & Co-fondateur"
            />
          </div>
        </div>

        <div className="row">
          <div className="col-lg-4 col-md-6 md-mb-30">
            <SingleTeam
              teamClass="team-item"
              Image={manPlaceholder}
              Title="Nassim MERRAD"
              Designation="Responsable Commercial"
            />
          </div>
          <div className="col-lg-4 col-md-6 sm-mb-30">
            <SingleTeam
              teamClass="team-item"
              Image={manPlaceholder}
              Title="Khaled ATIK"
              Designation="Responsable Commercial"
            />
          </div>
          <div className="col-lg-4 col-md-6 md-mb-30">
            <SingleTeam
              teamClass="team-item"
              Image={womanPlaceholder}
              Title="Rahma BELLILI"
              Designation="Responsable RH"
            />
          </div>
        </div>
        <div className="row mt-30 justify-content-center">
          <div className="col-lg-4 col-md-6 md-mb-30 md-mt-30">
            <SingleTeam
              teamClass="team-item"
              Image={womanPlaceholder}
              Title="Ichrak MATHLOUTHI"
              Designation="Responsable RH"
            />
          </div>
          <div className="col-lg-4 col-md-6 md-mb-30 md-mt-30">
            <SingleTeam
              teamClass="team-item"
              Image={manPlaceholder}
              Title="Karim SELAMI"
              Designation="MCT & Concepteur Pédagogique"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Team;
