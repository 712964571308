import React from "react";
import Header from "../../../components/Layout/Header/Header";
import Footer from "../../../components/Layout/Footer/Footer";
import OffWrap from "../../../components/Layout/Header/OffWrap";
import SearchModal from "../../../components/Layout/Header/SearchModal";
import SiteBreadcrumb from "./Breadcumb";
import KubernetesDetailsMain from "./KubernetesDetailsMain";

// Image
import Logo from "../../../assets/img/logo/csa.svg";
import footerLogo from "../../../assets/img/logo/csa.svg";

import bannerbg from "../../../assets/img/breadcrumbs/2.jpg";

const Microsoft = () => {
  return (
    <React.Fragment>
      <OffWrap />
      <Header
        parentMenu="Certifications"
        headerNormalLogo={Logo}
        headerStickyLogo={Logo}
        CanvasLogo={Logo}
        mobileNormalLogo={Logo}
        CanvasClass="right_menu_togle hidden-md"
        headerClass="full-width-header header-style1 home8-style4"
        TopBar="enable"
        TopBarClass="topbar-area home8-topbar"
        emailAddress="cloudiasys.academy.yt@gmail.com"
        Location="78 AVENUE DES CHAMPS ELYSEES 75008 PARIS "
      />

      {/* breadcrumb-area-start */}
      <SiteBreadcrumb
        pageTitle="Certification Kubernetes"
        pageName="Kubernetes"
        breadcrumbsImg={bannerbg}
      />
      {/* breadcrumb-area-start */}

      {/* Course Details Main */}
      <KubernetesDetailsMain />
      {/* Course Details Main */}

      {/* <Newsletter
                sectionClass="rs-newsletter style1 gray-bg orange-color mb--90 sm-mb-0 sm-pb-70"
                titleClass="title mb-0 white-color"
            /> */}

      <Footer
        footerClass="rs-footer home9-style main-home"
        footerLogo={footerLogo}
      />
      <SearchModal />
    </React.Fragment>
  );
};

export default Microsoft;
