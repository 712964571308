import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";

import CourseSidebar from "../../pages/course/CourseSidebarSection";
import CourseSingleTwo from "./CourseSingleTwo";

// Course courseImg
import courseImg2 from "../../assets/img/courses/2.jpg";

const CoursePart = (props) => {
  const location = useLocation();
  const [courses, setCourses] = useState([]);
  const [stats, setStats] = useState({
    thematiques: {},
    plateformes: {},
    certifications: {},
  });
  const [selectedFilters, setSelectedFilters] = useState({
    thematiques: [],
    plateformes: [],
    certifications: [],
    duree: [],
  });

  // Fetch statistics
  useEffect(() => {
    const fetchStats = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/formations/statistics`
        );
        setStats(response.data);
      } catch (error) {
        console.error("Error fetching statistics:", error);
      }
    };
    fetchStats();
  }, []);

  // Initialize filters and fetch courses
  useEffect(() => {
    const domain = location.state?.domain;
    const certification = location.state?.certification;
    const platform = location.state?.platform;

    console.log("location.state :: ", location.state);

    if (domain) {
      setSelectedFilters((prev) => ({
        ...prev,
        thematiques: [domain],
      }));
    } else if (certification) {
      setSelectedFilters((prev) => ({
        ...prev,
        certifications: [certification],
      }));
    } else if (platform) {
      setSelectedFilters((prev) => ({
        ...prev,
        plateformes: [platform],
      }));
    } else {
      fetchCourses(selectedFilters);
    }
  }, [location.state]);

  useEffect(() => {
    fetchCourses(selectedFilters);
  }, [selectedFilters]);

  const handleFiltersChange = (newFilters) => {
    setSelectedFilters(newFilters);
  };

  const listClassAdd = () => {
    document.getElementById("rs-popular-course").classList.add("list-view");
  };

  const listClassRemove = () => {
    document.getElementById("rs-popular-course").classList.remove("list-view");
  };

  const fetchCourses = async (filters) => {
    try {
      let response;
      if (
        filters.thematiques.length === 0 &&
        filters.plateformes.length === 0 &&
        filters.certifications.length === 0
      ) {
        const apiUrl = `${process.env.REACT_APP_API_URL}/formations`;
        response = await axios.get(apiUrl);
      } else {
        const apiUrl = `${process.env.REACT_APP_API_URL}/formations/filter`;
        response = await axios.post(apiUrl, {
          thematiques: filters.thematiques,
          plateformes: filters.plateformes,
          certifications: filters.certifications,
        });
      }
      console.log("response :: ", response);

      setCourses(response.data);
    } catch (error) {
      console.error("Error fetching courses:", error);
    }
  };

  return (
    <div
      id="rs-popular-course"
      className="rs-popular-courses style1 list-view course-view-style orange-style rs-inner-blog white-bg pt-100 pb-100 md-pt-70 md-pb-80"
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-4 col-md-12 order-last">
            <CourseSidebar
              selectedFilters={selectedFilters}
              onFiltersChange={handleFiltersChange}
              stats={stats}
            />
          </div>
          <div className="col-lg-8 pr-50 md-pr-14">
            <div className="course-search-part">
              <div className="course-view-part">
                <div className="view-icons">
                  <button onClick={listClassRemove} className="view-grid mr-10">
                    <i className="fa fa-th-large"></i>
                  </button>
                  <button onClick={listClassAdd} className="view-list">
                    <i className="fa fa-list-ul"></i>
                  </button>
                </div>
                <div className="view-text">
                  Liste 0-{courses.length} de {courses.length} formation
                </div>
              </div>
              <div className="type-form">
                <form method="post" action="#">
                  <div className="form-group mb-0">
                    <div className="custom-select-box">
                      <select id="timing">
                        <option>Par défaut</option>
                        <option>Nouveautés</option>
                        <option>Plus anciennes</option>
                      </select>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="course-part clearfix">
              {courses.map((course, index) => (
                <CourseSingleTwo
                  key={course._id || index}
                  courseClass={`courses-item${index % 2 === 0 ? "" : " right"}`}
                  courseImg={`/images/courses/${course.reference}.jpg`}
                  courseCategory={course.thematique}
                  courseTitle={course.titre}
                  courseRef={course.reference}
                  coursePrice={course.prix}
                  level={course.level}
                  details={course.details}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CoursePart;
