import React, { useState, useEffect } from "react";
import ScrollToTop from "../../../components/Common/ScrollTop";
import InfoSection from "./components/CpfSection";

import CpfImage from "../../../assets/img/about/cpf-image.jpg";
import OpcoImage from "../../../assets/img/about/opco-image.jpg";
import PoeiImage from "../../../assets/img/about/poei-image.jpg";
import PaymentImage from "../../../assets/img/about/payment-image.jpg";

const FinancementMain = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const cpfDescription = (
    <>
      Le <strong>compte personnel de formation (CPF)</strong> permet{" "}
      <strong>d'acquérir des droits à la formation</strong>. Il a une{" "}
      <strong>vocation universelle</strong> et s'adresse à{" "}
      <strong>tous les actifs</strong>. Le CPF est un{" "}
      <strong>compte individuel et rechargeable en euros</strong>, utilisable
      tout au long de la vie professionnelle.
    </>
  );

  const opcoDescription = (
    <>
      Les actions collectives sont des dispositifs de financement proposés par
      les <strong>OPCO</strong> (Opérateurs de compétences), qui permettent
      l'accès à des formations <strong>pré-négociées</strong> avec des
      organismes de formation partenaires, sur des thématiques jugées{" "}
      <strong>prioritaires</strong> pour{" "}
      <strong>les entreprises et leurs salariés</strong>, en particulier les
      TPE/PME.
    </>
  );

  const poeiDescription = (
    <>
      La{" "}
      <strong>Préparation Opérationnelle à l'Emploi Individuelle (POEI)</strong>{" "}
      est un dispositif qui permet aux employeurs de former des demandeurs
      d'emploi inscrits à <strong>France Travail</strong> afin qu'ils acquièrent
      les compétences nécessaires pour occuper un poste spécifique au sein de
      leur entreprise.
    </>
  );

  const paymentDescription = (
    <>
      Même si vous ne bénéficiez pas de dispositifs de financement, nous tenons
      à rendre l'apprentissage accessible à tous. Réservez votre place avec un{" "}
      <strong>simple acompte</strong>, puis étalez le reste du paiement en
      plusieurs fois <strong>sans frais</strong>. Une solution simple et
      flexible pour avancer sereinement dans votre projet professionnel.
    </>
  );

  return (
    <React.Fragment>
      <div style={{ marginTop: "70px" }}>
        <div className="fixed-training-menu full-size-block bg-color-grey-50">
          {/* Main Title Section */}
          <div
            style={{
              padding: isMobile ? "2rem" : "4rem 7rem",
              textAlign: "center",
            }}
          >
            <h1
              style={{
                color: "#ff5421",
                fontSize: isMobile ? "2.5rem" : "3.5rem",
                fontWeight: "700",
                marginBottom: "1.5rem",
                fontFamily: "Montserrat",
                lineHeight: "1.2",
              }}
            >
              Activez vos dispositifs de financement
            </h1>
            <h2
              style={{
                color: "#333333",
                fontSize: isMobile ? "1.8rem" : "2.5rem",
                fontWeight: "600",
                marginBottom: "2rem",
                fontFamily: "Montserrat",
              }}
            >
              CPF, Actions Collectives, POEI, ...
            </h2>
            <p
              style={{
                fontSize: isMobile ? "1.2rem" : "1.5rem",
                color: "#666666",
                maxWidth: "1000px",
                margin: "0 auto",
                fontFamily: "Montserrat",
                lineHeight: "1.5",
              }}
            >
              Découvrez les différentes solutions disponibles pour financer
              votre formation.
            </p>
          </div>

          {/* CPF Section */}
          <InfoSection
            isMobile={isMobile}
            image={CpfImage}
            title={
              <>
                Compte personnel
                <br />
                de formation (CPF)
              </>
            }
            description={cpfDescription}
            imagePosition="left"
          />

          {/* OPCO Section */}
          <InfoSection
            isMobile={isMobile}
            image={OpcoImage}
            title="Actions Collectives (OPCO)"
            description={opcoDescription}
            imagePosition="right"
          />

          {/* POEI Section */}
          <InfoSection
            isMobile={isMobile}
            image={PoeiImage}
            title="POEI (France Travail)"
            description={poeiDescription}
            imagePosition="left"
          />

          {/* Payment Section */}
          <InfoSection
            isMobile={isMobile}
            image={PaymentImage}
            title="Paiement en plusieurs fois"
            description={paymentDescription}
            imagePosition="right"
          />
        </div>
      </div>

      {/* scrolltop-start */}
      <ScrollToTop scrollClassName="scrollup orange-color" />
      {/* scrolltop-end */}
    </React.Fragment>
  );
};

export default FinancementMain;
