import React from "react";
import ScrollToTop from "../../../components/Common/ScrollTop";
import LearnDifferentlySection from "./LearnDifferently";
import The6Pillars from "./The6Pillars";
import GoalSection from "./GoalSection";
const AboutMain = () => {
  return (
    <React.Fragment>
      {/* Learn Differently Start */}
      <LearnDifferentlySection />
      {/* Learn Differently End */}

      {/* The 6 Pillars Start */}
      <The6Pillars />
      {/* The 6 Pillars End */}

      {/* Goal Section Start */}
      <GoalSection />
      {/* Goal Section End */}

      {/* scrolltop-start */}
      <ScrollToTop scrollClassName="scrollup orange-color" />
      {/* scrolltop-end */}
    </React.Fragment>
  );
};

export default AboutMain;
