import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import ScrollToTop from "../../../components/Common/ScrollTop";
import "./style.css";

const MicrosoftDetailsMain = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Adjust breakpoint as needed
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const descStyle = {
    fontFamily: "Montserrat",
    fontSize: "1.5rem",
    lineHeight: "1.5",
    margin: isMobile ? "0px !important" : "1rem",
    padding: isMobile ? "0 2rem" : "0 7rem",
    textAlign: "justify",
  };

  return (
    <React.Fragment>
      <div style={{ "margin-top": "70px" }}>
        <div className="fixed-training-menu full-size-block bg-color-grey-50 ">
          <div className="width-100 justify-center">
            <p className="desc" style={descStyle}>
              Obtenir une certification Microsoft, c'est booster votre
              employabilité, prouver votre expertise et accéder à des
              opportunités de carrière exceptionnelles.
            </p>
            <p className="desc" style={descStyle}>
              Dans le domaine du Cloud Computing, Microsoft Azure consolide en
              2024 sa position de deuxième plus grand fournisseur de services
              Cloud au monde, derrière Amazon Web Services (AWS). Les études de
              marché récentes indiquent qu'Azure détient environ 20-25 % de part
              de marché mondiale des services cloud. (
              <a
                href="https://www.crn.com/news/cloud/microsoft-azure-grows-twice-as-fast-as-aws-in-q4-2023"
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  color: "blue",
                  textDecoration: "underline",
                }}
              >
                crn.com
              </a>
              )
            </p>
          </div>

          {/* STATISTICS SECTION */}
          <div
            className="statistics-section"
            style={{
              padding: isMobile ? "2rem" : "2rem 7rem",
              marginTop: "5rem",
            }}
          >
            <h1
              className="sl-title text-center"
              style={{
                fontSize: isMobile ? "2.5rem" : "3rem",
                marginBottom: "5rem",
              }}
            >
              Impacts des Certifications Officielles Editeurs
            </h1>

            <div
              style={{
                display: "flex",
                flexDirection: isMobile ? "column" : "row",
                justifyContent: "space-between",
                gap: isMobile ? "3rem" : "2rem",
                width: "100%",
              }}
            >
              {/* First Stat */}
              <div
                style={{
                  flex: 1,
                  textAlign: "center",
                  width: isMobile ? "100%" : "auto",
                  padding: isMobile ? "0 1rem" : 0,
                }}
              >
                <h3
                  style={{
                    color: "rgb(232, 99, 69)",
                    fontSize: "4rem",
                    fontWeight: "bold",
                    marginBottom: "1rem",
                  }}
                >
                  91 %
                </h3>
                <p
                  style={{
                    fontSize: "1.2rem",
                    lineHeight: "1.5",
                    fontFamily: "Montserrat",
                  }}
                >
                  des candidats certifiés ont déclaré se sentir plus confiants
                  dans leurs compétences après avoir obtenu leur certification
                </p>
                <p
                  style={{
                    color: "#4A90E2",
                    fontSize: "1rem",
                    marginTop: "1rem",
                    fontStyle: "italic",
                  }}
                >
                  Pearson VUE (2023)
                </p>
              </div>

              {/* Second Stat */}
              <div style={{ flex: 1, textAlign: "center" }}>
                <h3
                  style={{
                    color: "rgb(232, 99, 69)",
                    fontSize: "4rem",
                    fontWeight: "bold",
                    marginBottom: "1rem",
                  }}
                >
                  70 %
                </h3>
                <p
                  style={{
                    fontSize: "1.2rem",
                    lineHeight: "1.5",
                    fontFamily: "Montserrat",
                  }}
                >
                  des entreprises considèrent que les certifications IT
                  reconnues dans leur secteur sont des atouts essentiels et
                  fortement recommandés pour le développement des compétences
                </p>
                <p
                  style={{
                    color: "#4A90E2",
                    fontSize: "1rem",
                    marginTop: "1rem",
                    fontStyle: "italic",
                  }}
                >
                  IDC InfoBrief (2024)
                </p>
              </div>

              {/* Third Stat */}
              <div style={{ flex: 1, textAlign: "center" }}>
                <h3
                  style={{
                    color: "rgb(232, 99, 69)",
                    fontSize: "4rem",
                    fontWeight: "bold",
                    marginBottom: "1rem",
                  }}
                >
                  77 %
                </h3>
                <p
                  style={{
                    fontSize: "1.2rem",
                    lineHeight: "1.5",
                    fontFamily: "Montserrat",
                  }}
                >
                  des candidats certifiés développent une plus grande capacité
                  d'innovation après l'obtention de leur certification
                </p>
                <p
                  style={{
                    color: "#4A90E2",
                    fontSize: "1rem",
                    marginTop: "1rem",
                    fontStyle: "italic",
                  }}
                >
                  Pearson VUE (2023)
                </p>
              </div>
            </div>
          </div>

          {/* WHY CLOUDIASYS SECTION */}
          <div
            className="why-cloudiasys-section"
            style={{
              padding: isMobile ? "0" : "2rem 7rem",
              marginTop: isMobile ? "0" : "5rem",
            }}
          >
            <h1
              className="sl-title text-center"
              style={{
                fontSize: isMobile ? "2.5rem" : "3rem",
                marginBottom: "5rem",
              }}
            >
              Pourquoi Préparer votre Certification Microsoft avec CloudiaSys
              Academy ?
            </h1>

            <div
              style={{
                display: "flex",
                flexDirection: isMobile ? "column" : "row",
                alignItems: "stretch",
                gap: isMobile ? "2rem" : "4rem",
                padding: "2rem",
                borderRadius: "10px",
              }}
            >
              {/* LEFT SECTION */}
              <div
                style={{
                  flex: 1,
                  backgroundColor: "#f8f9fa",
                  padding: "2rem",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  order: isMobile ? "2" : "1",
                }}
              >
                <h3
                  className="sl-title text-center"
                  style={{
                    color: "#4A90E2",
                    fontSize: isMobile ? "1.5rem" : "2.5rem",
                    marginBottom: "2rem",
                  }}
                >
                  Formateurs Experts & Certifiés
                </h3>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "1.5rem",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "flex-start",
                      gap: "1rem",
                      marginTop: "1.5rem",
                    }}
                  >
                    <span style={{ color: "#E86345", fontSize: "1.5rem" }}>
                      ✓
                    </span>
                    <p
                      style={{
                        fontSize: isMobile ? "1rem" : "1.5rem",
                        lineHeight: "1.5",
                        fontFamily: "Montserrat",
                        margin: 0,
                        textAlign: "justify",
                      }}
                    >
                      Nos formations sont animées par des Formateurs Certifiés
                      Microsoft (MCT). Microsoft reconnait avec ce titre
                      l'expertise techniques et l'excellence pédagogique
                    </p>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "flex-start",
                      gap: "1rem",
                      marginTop: "1.5rem",
                    }}
                  >
                    <span style={{ color: "#E86345", fontSize: "1.5rem" }}>
                      ✓
                    </span>
                    <p
                      style={{
                        fontSize: isMobile ? "1rem" : "1.5rem",
                        lineHeight: "1.5",
                        fontFamily: "Montserrat",
                        margin: 0,
                        textAlign: "justify",
                      }}
                    >
                      Nos formateurs experts ont plusieurs années d'expérience
                      sur la mise en œuvre des projets en entreprise avec Azure
                      et les solutions Microsoft
                    </p>
                  </div>
                </div>
              </div>

              {/* RIGHT SECTION */}
              <div
                style={{
                  flex: 1,
                  display: "flex",
                  justifyContent: "center",
                  order: isMobile ? "1" : "2",
                }}
              >
                <img
                  src={require("../../../assets/img/certifications/certified_trainers.png")}
                  alt="Microsoft Certified Trainers"
                  style={{
                    maxWidth: "100%",
                    height: "auto",
                  }}
                />
              </div>
            </div>
          </div>

          {/* EXCLUSIFS ADVANTAGES SECTION */}
          <div
            className="why-cloudiasys-section"
            style={{
              padding: isMobile ? "0" : "2rem 7rem",
              marginTop: isMobile ? "0" : "5rem",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: isMobile ? "column" : "row",
                alignItems: "stretch",
                gap: isMobile ? "2rem" : "4rem",
                padding: "2rem",
              }}
            >
              {/* LEFT SECTION */}
              <div
                style={{
                  flex: 1,
                  display: "flex",
                  justifyContent: "center",
                  order: isMobile ? "2" : "1",
                }}
              >
                <img
                  src={require("../../../assets/img/certifications/avantages.png")}
                  alt="Microsoft Certified Trainers"
                  style={{
                    maxWidth: "100%",
                    height: "auto",
                  }}
                />
              </div>

              {/* RIGHT SECTION */}
              <div
                style={{
                  flex: 1,
                  backgroundColor: "#f8f9fa",
                  padding: "2rem",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  order: isMobile ? "1" : "2",
                }}
              >
                <h3
                  className="sl-title text-center"
                  style={{
                    color: "#4A90E2",
                    fontSize: isMobile ? "1.5rem" : "2.5rem",
                    marginBottom: "2rem",
                  }}
                >
                  Avantages Exclusifs
                </h3>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "1.5rem",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "flex-start",
                      gap: "1rem",
                      marginTop: "1.5rem",
                    }}
                  >
                    <span style={{ color: "#E86345", fontSize: "1.5rem" }}>
                      ✓
                    </span>
                    <p
                      style={{
                        fontSize: isMobile ? "1rem" : "1.5rem",
                        lineHeight: "1.5",
                        fontFamily: "Montserrat",
                        margin: 0,
                        textAlign: "justify",
                      }}
                    >
                      Nous offrons du temps supplémentaire,
                      <strong>(jusqu'à une journée)</strong>, pour vous
                      permettre de mieux vous préparer à votre examen de
                      certification
                    </p>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "flex-start",
                      gap: "1rem",
                      marginTop: "1.5rem",
                    }}
                  >
                    <span style={{ color: "#E86345", fontSize: "1.5rem" }}>
                      ✓
                    </span>
                    <p
                      style={{
                        fontSize: isMobile ? "1rem" : "1.5rem",
                        lineHeight: "1.5",
                        fontFamily: "Montserrat",
                        margin: 0,
                        textAlign: "justify",
                      }}
                    >
                      Nous organisons des sessions de révision avec des examens
                      blancs, au cours desquelles nos formateurs MCT partageront
                      leurs meilleures astuces pour réussir votre examen
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* KEY CERTIFICATIONS SECTION */}
          <div
            className="key-certifications-section"
            style={{
              padding: isMobile ? "0 2rem" : "2rem 7rem",
              marginTop: isMobile ? "0" : "5rem",
              marginBottom: "2rem",
            }}
          >
            <h1
              className="sl-title text-center"
              style={{
                fontSize: "3rem",
                marginBottom: "5rem",
              }}
            >
              Préparez-vous aux Certifications Clés
            </h1>

            <div
              style={{
                display: "flex",
                flexDirection: isMobile ? "column" : "row",
                gap: isMobile ? "2rem" : "4rem",
                justifyContent: "center",
              }}
            >
              {/* Azure Administrator Card */}
              <div
                style={{
                  flex: 1,
                  backgroundColor: "#f8f9fa",
                  padding: "2rem",
                  borderRadius: "10px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <img
                  src={require("../../../assets/img/certifications/azure-administrator.png")}
                  alt="Azure Administrator"
                  style={{
                    width: "100px",
                    height: "auto",
                    marginBottom: "2rem",
                  }}
                />

                <h3
                  className="sl-title text-center"
                  style={{
                    color: "#4A90E2",
                    fontSize: isMobile ? "1.5rem" : "1.8rem",
                    marginBottom: "1.5rem",
                  }}
                >
                  AZ-104 : Microsoft Azure Administrator
                </h3>
                <div style={{ width: "100%" }}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "flex-start",
                      gap: "1rem",
                      marginBottom: "1.5rem",
                      marginTop: "2rem",
                    }}
                  >
                    <span style={{ color: "#E86345", fontSize: "1.5rem" }}>
                      ✓
                    </span>
                    <p
                      style={{
                        fontSize: isMobile ? "1rem" : "1.5rem",
                        lineHeight: "1.5",
                        fontFamily: "Montserrat",
                        textAlign: "justify",
                        margin: 0,
                      }}
                    >
                      Démontrez vos compétences essentielles pour configurer,
                      gérer, sécuriser et administrer les services clés de
                      Microsoft Azure
                    </p>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "flex-start",
                      gap: "1rem",
                      marginTop: "2rem",
                    }}
                  >
                    <span style={{ color: "#E86345", fontSize: "1.5rem" }}>
                      ✓
                    </span>
                    <p
                      style={{
                        fontSize: isMobile ? "1rem" : "1.5rem",
                        lineHeight: "1.5",
                        fontFamily: "Montserrat",
                        textAlign: "justify",
                        margin: 0,
                      }}
                    >
                      Idéale pour ceux qui souhaitent gérer les ressources Azure
                      efficacement
                    </p>
                  </div>
                </div>
              </div>

              {/* Power BI Data Analyst Card */}
              <div
                style={{
                  flex: 1,
                  backgroundColor: "#f8f9fa",
                  padding: "2rem",
                  borderRadius: "10px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <img
                  src={require("../../../assets/img/certifications/azure-administrator.png")}
                  alt="Power BI Data Analyst"
                  style={{
                    width: "100px",
                    height: "auto",
                    marginBottom: "2rem",
                  }}
                />
                <h3
                  className="sl-title text-center"
                  style={{
                    color: "#4A90E2",
                    fontSize: isMobile ? "1.5rem" : "1.8rem",
                    marginBottom: "1.5rem",
                  }}
                >
                  PL-300 : Microsoft Power BI Data Analyst
                </h3>
                <div style={{ width: "100%" }}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "flex-start",
                      gap: "1rem",
                      marginBottom: "1.5rem",
                      marginTop: "2rem",
                    }}
                  >
                    <span style={{ color: "#E86345", fontSize: "1.5rem" }}>
                      ✓
                    </span>
                    <p
                      style={{
                        fontSize: isMobile ? "1rem" : "1.5rem",
                        lineHeight: "1.5",
                        fontFamily: "Montserrat",
                        textAlign: "justify",
                        margin: 0,
                      }}
                    >
                      Démontrez vos compétences dans la modélisation, la
                      visualisation et l'analyse des données avec Microsoft
                      Power BI
                    </p>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "flex-start",
                      gap: "1rem",
                      marginTop: "2rem",
                    }}
                  >
                    <span style={{ color: "#E86345", fontSize: "1.5rem" }}>
                      ✓
                    </span>
                    <p
                      style={{
                        fontSize: isMobile ? "1rem" : "1.5rem",
                        lineHeight: "1.5",
                        fontFamily: "Montserrat",
                        textAlign: "justify",
                        margin: 0,
                      }}
                    >
                      Idéale pour ceux qui souhaitent analyser les données avec
                      Power BI
                    </p>
                  </div>
                </div>
              </div>
            </div>

            {/* Call to Action Button */}
            <div
              className="sl-btn mt-40"
              style={{
                width: isMobile ? "100%" : "50%",
                margin: "3rem auto 0",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Link
                to={{
                  pathname: "/course",
                  state: { certification: "microsoft" },
                }}
                className="readon orange-btn main-home"
                style={{
                  width: isMobile ? "100%" : "auto",
                  textAlign: "center",
                  padding: isMobile ? "1rem" : "1.5rem 3rem",
                  fontSize: isMobile ? "1rem" : "1.2rem",
                  display: "block",
                  textDecoration: "none",
                }}
              >
                CONSULTEZ TOUTES LES CERTIFICATIONS MICROSOFT
              </Link>
            </div>
          </div>
        </div>
      </div>

      {/* scrolltop-start */}
      <ScrollToTop scrollClassName="scrollup orange-color" />
      {/* scrolltop-end */}
    </React.Fragment>
  );
};

export default MicrosoftDetailsMain;
