const SingleTeam = (props) => {
  const { teamClass, Image, Title, Designation } = props;
  return (
    <div className={teamClass ?? "team-item"}>
      <img src={Image} alt={Title} />
      <div className="content-part">
        <h4 className="designation">{Title ?? "Jhon Pedrocas"}</h4>
        <span className="designation text-center text-white">
          {Designation ?? "Professor"}
        </span>
        {/* <ul className="social-links">
                    <li>
                        <Link to="#">
                            <i className="fa fa-facebook-f"></i>
                        </Link>
                    </li>
                    <li>
                        <Link to="#">
                            <i className="fa fa-twitter"></i>
                        </Link>
                    </li>
                    <li>
                        <Link to="#">
                            <i className="fa fa-pinterest"></i>
                        </Link>
                    </li>
                    <li>
                        <Link to="#">
                            <i className="fa fa-instagram"></i>
                        </Link>
                    </li>
                </ul> */}
      </div>
    </div>
  );
};

export default SingleTeam;
