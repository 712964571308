import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import emailjs from "@emailjs/browser";
import { Link } from "react-router-dom";
import { GiCheckMark } from "react-icons/gi";

import ScrollToTop from "../../../components/Common/ScrollTop";
import kubernetesLogo from "../../../assets/img/bootcamps/kubernetes.png";
import devopsLogo from "../../../assets/img/bootcamps/devopsLogo.png";
import azureAndGcpLogo from "../../../assets/img/bootcamps/azure_gcp.png";
import systemAdminLogo from "../../../assets/img/bootcamps/systemAdminLogo.png";
import postgresSqlLogo from "../../../assets/img/bootcamps/postgresql6815.jpg";
import mongoDbLogo from "../../../assets/img/bootcamps/mongodbLogo.png";
import gitLogo from "../../../assets/img/bootcamps/gitLogo.png";
import githubLogo from "../../../assets/img/bootcamps/githubLogo.png";
import ansibleLogo from "../../../assets/img/bootcamps/ansibleLogo.png";
import azureEntraIDLogo from "../../../assets/img/bootcamps/azureEntraID_icon.png";
import azureLogo from "../../../assets/img/bootcamps/azure_icon.png";
import storageAccountIcon from "../../../assets/img/bootcamps/storageAccount_icon.png";
import vNetIcon from "../../../assets/img/bootcamps/vnet_icon.png";
import azureVmIcon from "../../../assets/img/bootcamps/azure_vm_icon.png";
import aksLogo from "../../../assets/img/bootcamps/azure-kubernetes-service.png";
import gcpLogo from "../../../assets/img/bootcamps/gcp_icon.png";
import azureGoogleAuthLogo from "../../../assets/img/bootcamps/azure_GoogleAuth.png";
import azureDisasterRecoveryIcon from "../../../assets/img/bootcamps/azure_disasterRecovery_logo.png";
import azureVpcIcon from "../../../assets/img/bootcamps/azure_vpc.png";
import azureComputeIcon from "../../../assets/img/bootcamps/azure_compute_icon.png";
import azureGkeIcon from "../../../assets/img/bootcamps/azure_gke_icon.png";
import azureApplicationGateway from "../../../assets/img/bootcamps/azure-application-gateway.png";
import terraformIcon from "../../../assets/img/bootcamps/terraform_icon.png";
import promotheusIcon from "../../../assets/img/bootcamps/prometheus_icon.png";
import grafanaIcon from "../../../assets/img/bootcamps/gafana_icon.png";

import linuxLogo from "../../../assets/img/bootcamps/linux_logo.png";
import dockerLogo from "../../../assets/img/bootcamps/docker_logo.png";

import LearningByDoing from "../../../assets/img/bootcamps/learning_by_doing.jpg";
import InstructorImage from "../../../assets/img/bootcamps/03-Photo-Formateurs-Experts.jpg";
import ConsultantImage from "../../../assets/img/bootcamps/conseiller.png";

import "./style.css";

const CourseDetailsMain = () => {
  const [showModal, setShowModal] = useState(false);
  const [showDownloadModal, setShowDownloadModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedSection, setSelectedSection] = useState("cloud_intro");
  const [isMobile, setIsMobile] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const sendEmail = async (formData) => {
    try {
      setIsSubmitting(true);

      const templateParams = {
        from_name: formData.fullName,
        from_email: formData.email,
        message: formData.message,
      };

      const response = await emailjs.send(
        "service_gmail_houcem",
        "template_rcihop6",
        templateParams,
        "-N1Go-oGKNUw9N77D"
      );

      if (response.status === 200) {
        alert("Message envoyé avec succès!");
        setShowModal(false);
        reset();
      }
    } catch (error) {
      console.error("Error sending email:", error);
      alert(
        "Une erreur est survenue lors de l'envoi du message. Veuillez réessayer."
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  const onSubmit = (data) => {
    sendEmail(data);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Adjust breakpoint as needed
    };
    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleMenuClick = (e, section) => {
    e.preventDefault();
    setSelectedSection(section);
    const targetSection = document.getElementById(section);
    if (targetSection) {
      targetSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  const isSectionActive = (section) => {
    return selectedSection === section;
  };

  const handleDownloadRequest = async (data) => {
    try {
      const pdfUrl = `${process.env.PUBLIC_URL}/assets/bootcamps/Bootcamp Cloud & DevOps FR.pdf`;

      const link = document.createElement("a");
      link.href = pdfUrl;
      link.setAttribute("download", "Bootcamp Cloud & DevOps FR.pdf"); // Set the download attribute
      link.setAttribute("target", "_blank"); // Optional: open in new tab if download fails

      // Append to document, click and remove
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setShowDownloadModal(false);
    } catch (error) {
      console.error("Error downloading PDF:", error);
      setShowDownloadModal(false);
    }
  };

  return (
    <React.Fragment>
      <div style={{ "margin-top": "70px" }}>
        <div className="fixed-training-menu full-size-block bg-color-grey-50 hide-on-tablet menu-area">
          <div className="hero-section width-100 plr-8vw justify-center no-padding">
            <div className="wrapper-menu-slide">
              <a
                href="#cloud_intro"
                onClick={(e) => handleMenuClick(e, "cloud_intro")}
                className={`item-menu height-5vh ${
                  isSectionActive("cloud_intro") ? "active" : ""
                }`}
              >
                Intro
              </a>
              <a
                href="#cloud_programme"
                onClick={(e) => handleMenuClick(e, "cloud_programme")}
                className={`item-menu height-5vh ${
                  isSectionActive("cloud_programme") ? "active" : ""
                }`}
              >
                Programme
              </a>
              <a
                href="#cloud_méthodologie"
                onClick={(e) => handleMenuClick(e, "cloud_méthodologie")}
                className={`item-menu height-5vh ${
                  isSectionActive("cloud_méthodologie") ? "active" : ""
                }`}
              >
                Méthodologie
              </a>
              <a
                href="#cloud_financement"
                onClick={(e) => handleMenuClick(e, "cloud_financement")}
                className={`item-menu height-5vh ${
                  isSectionActive("cloud_financement") ? "active" : ""
                }`}
              >
                Financement
              </a>
              <a
                href="#cloud_admission"
                onClick={(e) => handleMenuClick(e, "admission")}
                className={`item-menu height-5vh ${
                  isSectionActive("admission") ? "active" : ""
                }`}
              >
                Admission
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* INTRO SECTION */}
      <section id="cloud_intro" className="hero-section">
        <div className="wrapper-1600 flex flex-col items-center space-y-6">
          <h1 className="sl-title text-center" style={{ fontSize: "3rem" }}>
            10 Semaines Intensives pour maitriser le Cloud et le DevOps
          </h1>
          <div
            style={{
              marginTop: "3vh",
              display: "flex",
              flexDirection: "row", // Default direction for larger screens
              justifyContent: "space-around",
              alignItems: "stretch",
              gap: "5rem",
              flexWrap: "wrap", // Allows wrapping for responsiveness
            }}
          >
            {/* First Item */}
            <div
              style={{
                textAlign: "center",
                width: "100%",
                maxWidth: "300px",
              }}
            >
              <img
                src={devopsLogo}
                alt="Devops"
                style={{
                  objectFit: "contain",
                }}
              />
              <p className="desc" style={{ color: "#4a4a4a" }}>
                Maitriser les outils et la pratique DevOps tels que le{" "}
                <strong>CI/CD</strong>, l'
                <strong>Infrastructure as Code</strong> et l'
                <strong>Orchestration des Conteneurs</strong>
              </p>
            </div>

            {/* Empty Spacer */}
            <div
              style={{
                width: "60px",
                display: "none", // Hide spacers for mobile
              }}
              className="spacer"
            ></div>

            {/* Second Item */}
            <div
              style={{
                textAlign: "center",
                width: "100%",
                maxWidth: "300px",
              }}
            >
              <img
                src={azureAndGcpLogo}
                alt="Azure"
                style={{
                  objectFit: "contain",
                }}
              />

              <p className="desc" style={{ color: "#4a4a4a" }}>
                Maitriser la mise en œuvre d'Infrastructures et d'Applications
                Modernes et Multi-Cloud avec les <strong>Cloud Azure</strong> et{" "}
                <strong>GCP</strong>
              </p>
            </div>

            {/* Empty Spacer */}
            <div
              style={{
                width: "60px",
                display: "none", // Hide spacers for mobile
              }}
              className="spacer"
            ></div>

            {/* Third Item */}
            <div
              style={{
                textAlign: "center",
                width: "100%",
                maxWidth: "300px",
              }}
            >
              <img
                src={systemAdminLogo}
                alt="Administrateur Système DevOps"
                style={{
                  objectFit: "contain",
                }}
              />
              <p className="desc" style={{ color: "#4a4a4a" }}>
                Valider le titre<strong> Administrateur Système DevOps</strong>{" "}
                reconnu par l'Etat et devenir Certifié{" "}
                <strong>Microsoft Administrateur Azure</strong>
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* PROGRAMME SECTION */}
      <section
        id="cloud_programme"
        className="hero-section mb-4"
        style={{
          paddingRight: "2vw",
          paddingLeft: "2vw",
        }}
      >
        <h6 style={{ color: "grey", fontSize: "2rem" }}>Programme</h6>
        <h2 className="text-center sl-title" style={{ fontSize: "3rem" }}>
          Ce que vous allez apprendre pendant ce Bootcamp
        </h2>
        <p
          className="desc text-center text-muted fs-5 mb-4 mx-lg-20"
          style={{ padding: "0px 4rem " }}
        >
          Ce programme intensif est conçu pour équiper les professionnels
          actuels et futurs des compétences essentielles pour exceller dans les
          domaines du Cloud Computing et du DevOps
        </p>

        {/* GRID POUR AFFICHAGE EN DEUX COLONNES */}
        <div
          className="container mt-40"
          style={{
            maxWidth: "fit-content",
          }}
        >
          <div className="row">
            {/* Onglets à gauche */}
            <div className="col-md-5">
              {/* Mobile View Tabs */}
              <div className={isMobile ? "mobile-tabs" : "d-none"}>
                <div
                  className="list-group"
                  id="list-tab-mobile"
                  role="tablist"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    overflowX: "auto",
                    WebkitOverflowScrolling: "touch",
                    scrollbarWidth: "none",
                    msOverflowStyle: "none",
                  }}
                >
                  <button
                    className="list-group-item list-group-item-action active"
                    id="systems-list-mobile"
                    data-bs-toggle="list"
                    data-bs-target="#systems"
                    type="button"
                    role="tab"
                    aria-controls="systems"
                    style={{
                      fontSize: "large",
                      padding: "1rem",
                      whiteSpace: "nowrap",
                      minWidth: "fit-content",
                    }}
                  >
                    Systèmes, Réseaux et Bases de Données
                  </button>
                  <button
                    className="list-group-item list-group-item-action"
                    id="devops-list-mobile"
                    data-bs-toggle="list"
                    data-bs-target="#devops"
                    type="button"
                    role="tab"
                    aria-controls="devops"
                    style={{
                      fontSize: "large",
                      padding: "1rem",
                      whiteSpace: "nowrap",
                      minWidth: "fit-content",
                    }}
                  >
                    DevOps, Conteneurs & Orchestration, CI/CD
                  </button>
                  <button
                    className="list-group-item list-group-item-action"
                    id="azureAndGcp-list-mobile"
                    data-bs-toggle="list"
                    data-bs-target="#azureAndGcp"
                    type="button"
                    role="tab"
                    aria-controls="azureAndGcp"
                    style={{
                      fontSize: "large",
                      padding: "1rem",
                      whiteSpace: "nowrap",
                      minWidth: "fit-content",
                    }}
                  >
                    Cloud Computing avec Azure et GCP
                  </button>
                  <button
                    className="list-group-item list-group-item-action"
                    id="archiAndInfra-list-mobile"
                    data-bs-toggle="list"
                    data-bs-target="#archiAndInfra"
                    type="button"
                    role="tab"
                    aria-controls="archiAndInfra"
                    style={{
                      fontSize: "large",
                      padding: "1rem",
                      whiteSpace: "nowrap",
                      minWidth: "fit-content",
                    }}
                  >
                    Architecture et Infrastructure Cloud en Production
                  </button>
                  <button
                    className="list-group-item list-group-item-action"
                    id="finalPRoject-list-mobile"
                    data-bs-toggle="list"
                    data-bs-target="#finalPRoject"
                    type="button"
                    role="tab"
                    aria-controls="finalPRoject"
                    style={{
                      fontSize: "large",
                      padding: "1rem",
                      whiteSpace: "nowrap",
                      minWidth: "fit-content",
                    }}
                  >
                    Projet Final DevOps & Cloud
                  </button>
                  <button
                    className="list-group-item list-group-item-action"
                    id="careerService-list-mobile"
                    data-bs-toggle="list"
                    data-bs-target="#careerService"
                    type="button"
                    role="tab"
                    aria-controls="careerService"
                    style={{
                      fontSize: "large",
                      padding: "1rem",
                      whiteSpace: "nowrap",
                      minWidth: "fit-content",
                    }}
                  >
                    Service Carrière
                  </button>
                </div>
              </div>

              {/* Desktop View Tabs */}
              <div className={!isMobile ? "desktop-tabs" : "d-none"}>
                <div className="list-group" id="list-tab" role="tablist">
                  <button
                    className="list-group-item list-group-item-action active"
                    id="systems-list"
                    data-bs-toggle="list"
                    data-bs-target="#systems"
                    type="button"
                    role="tab"
                    aria-controls="systems"
                    style={{
                      fontSize: "large",
                      padding: "1rem",
                    }}
                  >
                    Systèmes, Réseaux et Bases de Données
                  </button>
                  <button
                    className="list-group-item list-group-item-action"
                    id="devops-list"
                    data-bs-toggle="list"
                    data-bs-target="#devops"
                    type="button"
                    role="tab"
                    aria-controls="devops"
                    style={{
                      fontSize: "large",
                      padding: "1rem",
                    }}
                  >
                    DevOps, Conteneurs & Orchestration, CI/CD
                  </button>
                  <button
                    className="list-group-item list-group-item-action"
                    id="azureAndGcp-list"
                    data-bs-toggle="list"
                    data-bs-target="#azureAndGcp"
                    type="button"
                    role="tab"
                    aria-controls="azureAndGcp"
                    style={{
                      fontSize: "large",
                      padding: "1rem",
                    }}
                  >
                    Cloud Computing avec Azure et GCP
                  </button>
                  <button
                    className="list-group-item list-group-item-action"
                    id="archiAndInfra-list"
                    data-bs-toggle="list"
                    data-bs-target="#archiAndInfra"
                    type="button"
                    role="tab"
                    aria-controls="archiAndInfra"
                    style={{
                      fontSize: "large",
                      padding: "1rem",
                    }}
                  >
                    Architecture et Infrastructure Cloud en Production
                  </button>
                  <button
                    className="list-group-item list-group-item-action"
                    id="finalPRoject-list"
                    data-bs-toggle="list"
                    data-bs-target="#finalPRoject"
                    type="button"
                    role="tab"
                    aria-controls="finalPRoject"
                    style={{
                      fontSize: "large",
                      padding: "1rem",
                    }}
                  >
                    Projet Final DevOps & Cloud
                  </button>
                  <button
                    className="list-group-item list-group-item-action"
                    id="careerService-list"
                    data-bs-toggle="list"
                    data-bs-target="#careerService"
                    type="button"
                    role="tab"
                    aria-controls="careerService"
                    style={{
                      fontSize: "large",
                      padding: "1rem",
                    }}
                  >
                    Service Carrière
                  </button>
                </div>
              </div>

              <div
                className="sl-btn mb-20"
                style={{
                  marginTop: "3rem",
                  marginBottom: "2rem",
                  display: isMobile ? "none" : "block",
                }}
              >
                <Link
                  onClick={(e) => {
                    e.preventDefault();
                    setShowDownloadModal(true);
                  }}
                  className="program_download readon orange-btn main-home"
                  to="#"
                >
                  Télécharger le programme
                </Link>
              </div>
            </div>

            {/* Contenu à droite */}
            <div
              className="col-md-7"
              style={{ marginTop: isMobile ? "2.5rem" : "0" }}
            >
              <div
                className="tab-content"
                id="nav-tabContent"
                style={{
                  paddingTop: "unset",
                  border: "none",
                }}
              >
                {/* FIRST ITEM  */}
                <div
                  className="tab-pane fade show active"
                  id="systems"
                  role="tabpanel"
                  aria-labelledby="systems-list"
                >
                  <h3>
                    Les Fondamentaux pour bien entamer sa carrière dans le
                    DevOps{" "}
                    <span
                      style={{
                        backgroundColor: "#ff5421",
                        color: "white",
                        padding: "4px 12px",
                        borderRadius: "20px",
                        fontSize: "0.9rem",
                        marginLeft: "10px",
                        verticalAlign: "middle",
                      }}
                    >
                      40h
                    </span>
                  </h3>
                  <p className="desc" style={{ fontSize: "1.2rem" }}>
                    Après une introduction aux principes fondamentaux des
                    systèmes, réseaux et bases de données, vous allez explorer
                    les concepts essentiels pour administrer et sécuriser ces
                    environnements et maitriser les fondamentaux de l'Agilité
                  </p>
                  <h4>Concrètement, vous allez :</h4>
                  <ul className="desc">
                    <li className="mb-5">
                      <GiCheckMark
                        style={{ marginRight: "8px", color: "#ff5421" }}
                      />
                      <span style={{ fontSize: "1.2rem" }}>
                        Administrer et configurer des systèmes Linux (Shell,
                        ligne de commandes)
                      </span>
                    </li>
                    <li className="mb-5">
                      <GiCheckMark
                        style={{ marginRight: "8px", color: "#ff5421" }}
                      />

                      <span style={{ fontSize: "1.2rem" }}>
                        Déployer et gérer des services réseaux (DNS, DHCP, etc.)
                      </span>
                    </li>
                    <li className="mb-5">
                      <GiCheckMark
                        style={{ marginRight: "8px", color: "#ff5421" }}
                      />
                      <span style={{ fontSize: "1.2rem" }}>
                        Concevoir et optimiser des bases de données
                        relationnelles et NoSQL
                      </span>
                    </li>
                    <li className="mb-5">
                      <GiCheckMark
                        style={{ marginRight: "8px", color: "#ff5421" }}
                      />
                      <span style={{ fontSize: "1.2rem" }}>
                        Sécuriser un environnement informatique (SSH, types
                        d'attaques, ...)
                      </span>
                    </li>
                  </ul>
                  <h4 className="mt-30">Outils Utilisés</h4>
                  <div
                    className="d-flex justify-content-start align-items-center flex-wrap"
                    style={{ gap: "20px" }}
                  >
                    <div
                      className="d-flex align-items-center"
                      style={{
                        backgroundColor: "white",
                        padding: "10px 20px",
                        borderRadius: "10px",
                        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                        minWidth: "150px",
                      }}
                    >
                      <img
                        src={linuxLogo}
                        alt="Linux"
                        style={{
                          width: "40px",
                          height: "40px",
                          objectFit: "contain",
                          marginRight: "10px",
                        }}
                      />
                      <h5
                        className="fw-bold"
                        style={{ fontSize: "0.9rem", marginBottom: "0" }}
                      >
                        Linux
                      </h5>
                    </div>

                    <div
                      className="d-flex align-items-center"
                      style={{
                        backgroundColor: "white",
                        padding: "10px 20px",
                        borderRadius: "10px",
                        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                        minWidth: "150px",
                      }}
                    >
                      <img
                        src={postgresSqlLogo}
                        alt="PostgresSQL"
                        style={{
                          width: "40px",
                          height: "40px",
                          objectFit: "contain",
                          marginRight: "10px",
                        }}
                      />
                      <h5
                        className="fw-bold"
                        style={{ fontSize: "0.9rem", marginBottom: "0" }}
                      >
                        Postgres
                      </h5>
                    </div>

                    <div
                      className="d-flex align-items-center"
                      style={{
                        backgroundColor: "white",
                        padding: "10px 20px",
                        borderRadius: "10px",
                        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                        minWidth: "150px",
                      }}
                    >
                      <img
                        src={mongoDbLogo}
                        alt="MongoDb"
                        style={{
                          width: "40px",
                          height: "40px",
                          objectFit: "contain",
                          marginRight: "10px",
                        }}
                      />
                      <h5
                        className="fw-bold"
                        style={{ fontSize: "0.9rem", marginBottom: "0" }}
                      >
                        MongoDB
                      </h5>
                    </div>
                  </div>
                </div>

                {/* SECOND ITEM */}
                <div
                  className="tab-pane fade"
                  id="devops"
                  role="tabpanel"
                  aria-labelledby="devops-list"
                >
                  <h3>
                    Maîtriser les pratiques DevOps, la conteneurisation et le
                    CI/CD
                    <span
                      style={{
                        backgroundColor: "#ff5421",
                        color: "white",
                        padding: "4px 12px",
                        borderRadius: "20px",
                        fontSize: "0.9rem",
                        marginLeft: "10px",
                        verticalAlign: "middle",
                      }}
                    >
                      80h
                    </span>
                  </h3>
                  <p
                    className="desc"
                    style={{
                      fontSize: "1.2rem",
                    }}
                  >
                    Apprenez à automatiser le déploiement et la gestion des
                    applications avec les pratiques DevOps. Maîtrisez la
                    conteneurisation avec Docker et l'orchestration avec
                    Kubernetes, tout en optimisant vos workflows CI/CD pour des
                    livraisons rapides et fiables.
                  </p>
                  <h4>Concrètement, vous allez :</h4>
                  <ul className="desc">
                    <li className="mb-5">
                      <GiCheckMark
                        style={{ marginRight: "8px", color: "#ff5421" }}
                      />
                      <span style={{ fontSize: "1.2rem" }}>
                        Créer et gérer des conteneurs Docker (Images, Gestion
                        des volumes,...)
                      </span>
                    </li>
                    <li className="mb-5">
                      <GiCheckMark
                        style={{ marginRight: "8px", color: "#ff5421" }}
                      />
                      <span style={{ fontSize: "1.2rem" }}>
                        Orchestrer une application conteneurisée avec Kubernetes
                        (Pods, Ingress,…)
                      </span>
                    </li>
                    <li className="mb-5">
                      <GiCheckMark
                        style={{ marginRight: "8px", color: "#ff5421" }}
                      />

                      <span style={{ fontSize: "1.2rem" }}>
                        Automatiser la configuration d'environnements avec
                        Ansible
                      </span>
                    </li>
                    <li className="mb-5">
                      <GiCheckMark
                        style={{ marginRight: "8px", color: "#ff5421" }}
                      />
                      <span style={{ fontSize: "1.2rem" }}>
                        Mettre en place des pipelines CI/CD (Git/GitHub, GitHub
                        Actions)
                      </span>
                    </li>
                  </ul>
                  <h4 className="mt-30">Outils Utilisés</h4>
                  <div className="d-flex justify-content-start align-items-start flex-wrap gap-5">
                    {[
                      { src: dockerLogo, alt: "Docker", label: "Linux" },
                      {
                        src: kubernetesLogo,
                        alt: "Kubernetes",
                        label: "Kubernetes",
                      },
                      { src: gitLogo, alt: "Git", label: "Git" },
                      { src: githubLogo, alt: "Github", label: "Github" },
                      { src: ansibleLogo, alt: "Ansible", label: "Ansible" },
                    ].map((tool) => (
                      <div
                        key={tool.alt.replace(/\s+/g, "_").toLowerCase()}
                        className="d-flex align-items-center"
                        style={{
                          backgroundColor: "white",
                          padding: "10px 20px",
                          borderRadius: "10px",
                          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                        }}
                      >
                        <img
                          src={tool.src}
                          alt={tool.alt}
                          style={{
                            width: "40px",
                            height: "40px",
                            objectFit: "contain",
                            marginRight: "10px",
                          }}
                        />
                        <h5
                          className="fw-bold"
                          style={{ fontSize: "0.9rem", marginBottom: "0" }}
                        >
                          {tool.label}
                        </h5>
                      </div>
                    ))}
                  </div>
                </div>

                {/* THIRD ITEM :: Azure And GCP */}
                <div
                  className="tab-pane fade"
                  id="azureAndGcp"
                  role="tabpanel"
                  aria-labelledby="azureAndGcp-list"
                >
                  <h3>
                    Déployer des infrastructures et des Applications sur Azure
                    et GCP
                    <span
                      style={{
                        backgroundColor: "#ff5421",
                        color: "white",
                        padding: "4px 12px",
                        borderRadius: "20px",
                        fontSize: "0.9rem",
                        marginLeft: "10px",
                        verticalAlign: "middle",
                      }}
                    >
                      100h
                    </span>
                  </h3>
                  <p className="desc" style={{ fontSize: "1.2rem" }}>
                    Apprenez à concevoir, déployer et gérer des infrastructures
                    et des applications sur les Cloud Azure et GCP. Maîtrisez
                    les services clés tels que les machines virtuelles, le
                    stockage, les bases de données, la conteneurisation et
                    l'orchestration des conteneurs pour optimiser la performance
                    et la scalabilité de vos applications.
                  </p>
                  <h4>Concrètement, vous allez :</h4>
                  <ul className="desc">
                    <li className="mb-5">
                      <GiCheckMark
                        style={{ marginRight: "8px", color: "#ff5421" }}
                      />
                      <span style={{ fontSize: "1.2rem" }}>
                        Provisionner et Administrer les services Core et Avancés
                        sur Azure AKS
                      </span>
                    </li>
                    <li className="mb-5">
                      <GiCheckMark
                        style={{ marginRight: "8px", color: "#ff5421" }}
                      />{" "}
                      <span style={{ fontSize: "1.2rem" }}>
                        Orchestrer les Conteneurs et Configurer l'Identité et la
                        Conformité sur Azure
                      </span>
                    </li>
                    <li className="mb-5">
                      <GiCheckMark
                        style={{ marginRight: "8px", color: "#ff5421" }}
                      />
                      <span style={{ fontSize: "1.2rem" }}>
                        Provisionner et Administrer les services les plus
                        utilisés sur GCP
                      </span>
                    </li>
                    <li className="mb-5">
                      <GiCheckMark
                        style={{ marginRight: "8px", color: "#ff5421" }}
                      />
                      <span style={{ fontSize: "1.2rem" }}>
                        Préparer la certification Administrateur Azure - Partie
                        1
                      </span>
                    </li>
                  </ul>
                  <h4 className="mt-30">Outils Utilisés</h4>
                  <div className="d-flex justify-content-start align-items-start flex-wrap gap-5">
                    {[
                      { src: azureLogo, alt: "Azure", title: "Azure" },
                      {
                        src: azureEntraIDLogo,
                        alt: "Azure Entra ID",
                        title: "Entra",
                      },
                      {
                        src: storageAccountIcon,
                        alt: "Azure Storage Account",
                        title: "Storage",
                      },
                      {
                        src: vNetIcon,
                        alt: "Azure Virtual Network",
                        title: "vNet",
                      },
                      {
                        src: azureVmIcon,
                        alt: "Azure Virtual Machine",
                        title: "VM",
                      },
                      { src: aksLogo, alt: "AKS", title: "AKS" },
                      { src: gcpLogo, alt: "GCP", title: "GCP" },
                      {
                        src: azureGoogleAuthLogo,
                        alt: "Azure Google Auth",
                        title: "Cloud",
                      },
                      {
                        src: azureDisasterRecoveryIcon,
                        alt: "Azure Disaster Recovery",
                        title: "Recovery",
                      },
                      { src: azureVpcIcon, alt: "Azure VPC", title: "VPC" },
                      {
                        src: azureComputeIcon,
                        alt: "Azure Compute",
                        title: "Compute",
                      },
                      { src: azureGkeIcon, alt: "Azure GKE", title: "GKE" },
                    ].map((tool) => (
                      <div
                        key={tool.alt.replace(/\s+/g, "_").toLowerCase()}
                        className="d-flex align-items-center"
                        style={{
                          backgroundColor: "white",
                          padding: "10px 20px",
                          borderRadius: "10px",
                          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                        }}
                      >
                        <img
                          src={tool.src}
                          alt={tool.alt}
                          style={{
                            width: "40px",
                            height: "40px",
                            objectFit: "contain",
                            marginRight: "10px",
                          }}
                        />
                        <h5
                          className="fw-bold"
                          style={{ fontSize: "0.9rem", marginBottom: "0" }}
                        >
                          {tool.title}
                        </h5>
                      </div>
                    ))}
                  </div>
                </div>

                {/* FIRTH ITEM :: Archi And Infra */}
                <div
                  className="tab-pane fade"
                  id="archiAndInfra"
                  role="tabpanel"
                  aria-labelledby="archiAndInfra-list"
                >
                  <h3>
                    Déployer des infras et des Applications Cloud en Production
                    <span
                      style={{
                        backgroundColor: "#ff5421",
                        color: "white",
                        padding: "4px 12px",
                        borderRadius: "20px",
                        fontSize: "0.9rem",
                        marginLeft: "10px",
                        verticalAlign: "middle",
                      }}
                    >
                      100h
                    </span>
                  </h3>
                  <p
                    className="desc"
                    style={{
                      fontSize: "1.2rem",
                    }}
                  >
                    Apprenez à concevoir une architecture Cloud, automatiser et
                    sécuriser le déploiement d'infrastructures et d'applications
                    sur le Cloud Azure dans un environnement de production.
                    Maîtrisez les bonnes pratiques de scalabilité, haute
                    disponibilité et surveillance pour assurer des services
                    fiables et performants.
                  </p>
                  <h4>Concrètement, vous allez :</h4>
                  <ul>
                    <li className="mb-5">
                      <GiCheckMark
                        style={{ marginRight: "8px", color: "#ff5421" }}
                      />
                      <span style={{ fontSize: "1.2rem" }}>
                        Concevoir une architecture Cloud Azure avec les
                        standards de Microsoft
                      </span>
                    </li>
                    <li className="mb-5">
                      <GiCheckMark
                        style={{ marginRight: "8px", color: "#ff5421" }}
                      />
                      <span style={{ fontSize: "1.2rem" }}>
                        Automatiser le déploiement d'infrastructures Cloud (IaC)
                        avec Terraform
                      </span>
                    </li>
                    <li className="mb-5">
                      {" "}
                      <GiCheckMark
                        style={{ marginRight: "8px", color: "#ff5421" }}
                      />
                      <span style={{ fontSize: "1.2rem" }}>
                        Configurer la scalabilité et la haute disponibilité des
                        infras et applications
                      </span>
                    </li>
                    <li className="mb-5">
                      <GiCheckMark
                        style={{ marginRight: "8px", color: "#ff5421" }}
                      />
                      <span style={{ fontSize: "1.2rem" }}>
                        Superviser et sécuriser l'infrastructure et les
                        applications en Production
                      </span>
                    </li>
                  </ul>
                  <h4 className="mt-30">Outils Utilisés</h4>
                  <div className="d-flex justify-content-start align-items-start flex-wrap gap-5">
                    {[
                      { src: azureLogo, alt: "Azure", label: "Azure" },
                      {
                        src: azureEntraIDLogo,
                        alt: "Azure Entra ID",
                        label: "Entra",
                      },
                      {
                        src: azureApplicationGateway,
                        alt: "Azure Application Gateway",
                        label: "Application",
                      },
                      { src: aksLogo, alt: "AKS", label: "Aks" },
                      {
                        src: terraformIcon,
                        alt: "Terraform",
                        label: "Terraform",
                      },
                      {
                        src: promotheusIcon,
                        alt: "Prometheus",
                        label: "Prometheus",
                      },
                      { src: grafanaIcon, alt: "Grafana", label: "Grafana" },
                    ].map((tool) => (
                      <div
                        key={tool.alt.replace(/\s+/g, "_").toLowerCase()}
                        className="d-flex align-items-center"
                        style={{
                          backgroundColor: "white",
                          padding: "10px 20px",
                          borderRadius: "10px",
                          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                        }}
                      >
                        <img
                          src={tool.src}
                          alt={tool.alt}
                          style={{
                            width: "40px",
                            height: "40px",
                            objectFit: "contain",
                            marginRight: "10px",
                          }}
                        />
                        <h5
                          className="fw-bold"
                          style={{ fontSize: "0.9rem", marginBottom: "0" }}
                        >
                          {tool.label}
                        </h5>
                      </div>
                    ))}
                  </div>
                </div>

                {/* FIFTH ITEM :: Final project */}
                <div
                  className="tab-pane fade"
                  id="finalPRoject"
                  role="tabpanel"
                  aria-labelledby="finalPRoject-list"
                >
                  <h3>
                    Concevoir et implémenter un projet DevOps & Cloud de A à Z
                    <span
                      style={{
                        backgroundColor: "#ff5421",
                        color: "white",
                        padding: "4px 12px",
                        borderRadius: "20px",
                        fontSize: "0.9rem",
                        marginLeft: "10px",
                        verticalAlign: "middle",
                      }}
                    >
                      80h
                    </span>
                  </h3>
                  <p
                    className="desc"
                    style={{
                      fontSize: "1.2rem",
                    }}
                  >
                    Vous allez concevoir l'architecture et implémenter le
                    déploiement automatisé de l'infrastructure et des
                    applications d'un projet d'entreprise avec scénario réel.
                    Vous allez mettre en pratique ce que vous avez appris sur le
                    DevOps et le Cloud avec une immersion complète, de la
                    planification à la mise en production.
                  </p>
                  <h4>Concrètement, vous allez :</h4>
                  <ul>
                    <li className="mb-5">
                      <GiCheckMark
                        style={{ marginRight: "8px", color: "#ff5421" }}
                      />
                      <span style={{ fontSize: "1.2rem" }}>
                        Concevoir l'architecture Cloud avec les services adaptés
                        aux besoins
                      </span>
                    </li>
                    <li className="mb-5">
                      <GiCheckMark
                        style={{ marginRight: "8px", color: "#ff5421" }}
                      />
                      <span style={{ fontSize: "1.2rem" }}>
                        Automatiser le déploiement de l'infra et implémenter les
                        pipelines CI/CD
                      </span>
                    </li>
                    <li className="mb-5">
                      {" "}
                      <GiCheckMark
                        style={{ marginRight: "8px", color: "#ff5421" }}
                      />
                      <span style={{ fontSize: "1.2rem" }}>
                        Superviser et optimiser les performances et la
                        disponibilité en Production
                      </span>
                    </li>
                    <li className="mb-5">
                      <GiCheckMark
                        style={{ marginRight: "8px", color: "#ff5421" }}
                      />
                      <span style={{ fontSize: "1.2rem" }}>
                        Préparer et passer l'examen de la certification
                        Administrateur Azure (AZ-104)
                      </span>
                    </li>
                  </ul>
                </div>

                {/* LAST ITEM :: Career service */}
                <div
                  className="tab-pane fade"
                  id="careerService"
                  role="tabpanel"
                  aria-labelledby="careerService-list"
                >
                  <h3>
                    Booster votre Nouvelle Carrière d'Ingénieur Cloud & DevOps
                  </h3>
                  <p
                    className="desc"
                    style={{
                      fontSize: "1.2rem",
                    }}
                  >
                    Notre Service Carrière est dédié à propulser votre carrière
                    d'Ingénieur Cloud & DevOps. Vous bénéficiez d'un Coaching
                    personnalisé et accédez à notre réseau de partenaires pour
                    multiplier vos opportunités professionnelles. Participez à
                    des ateliers pratiques et des événements de Networking pour
                    faciliter votre insertion sur le marché du travail.
                  </p>
                  <h4>Concrètement, vous allez :</h4>
                  <ul>
                    <li className="mb-5">
                      <GiCheckMark
                        style={{ marginRight: "8px", color: "#ff5421" }}
                      />
                      <span style={{ fontSize: "1.2rem" }}>
                        Bénéficier d'un accompagnement individuel et des
                        ateliers pratiques
                      </span>
                    </li>
                    <li className="mb-5">
                      <GiCheckMark
                        style={{ marginRight: "8px", color: "#ff5421" }}
                      />
                      <span style={{ fontSize: "1.2rem" }}>
                        Êtres mis en relation avec nos entreprises partenaires
                        qui recrutent
                      </span>
                    </li>
                    <li className="mb-5">
                      {" "}
                      <GiCheckMark
                        style={{ marginRight: "8px", color: "#ff5421" }}
                      />
                      <span style={{ fontSize: "1.2rem" }}>
                        Assister à des simulations d'entretiens techniques
                      </span>
                    </li>
                    <li className="mb-5">
                      <GiCheckMark
                        style={{ marginRight: "8px", color: "#ff5421" }}
                      />
                      <span style={{ fontSize: "1.2rem" }}>
                        Améliorer votre communication, votre gestion du stress
                        et autres Soft Skills
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            {/* Download programme button */}
            <div
              className="sl-btn mb-20"
              style={{
                marginTop: "3rem",
                marginBottom: "2rem",
                display: isMobile ? "block" : "none",
              }}
            >
              <Link
                onClick={(e) => {
                  e.preventDefault();
                  setShowDownloadModal(true);
                }}
                className="program_download readon orange-btn main-home"
                to="#"
              >
                Télécharger le programme
              </Link>
            </div>
          </div>
        </div>
      </section>

      {/* PEDAGOGIE SECTION */}
      <section id="cloud_méthodologie" className="hero-section">
        <h6 style={{ color: "grey", fontSize: "2rem" }}>Pédagogie</h6>
        <h2 className="text-center sl-title" style={{ fontSize: "3rem" }}>
          Learning by Doing - Un Bootcamp axé sur la Pratique
        </h2>
        <div className="d-flex flex-column flex-lg-row align-items-center">
          {/* Image Section */}
          <div className="col-md-4 mb-3 mb-lg-0 me-lg-3">
            <img
              src={LearningByDoing}
              alt="Learning by Doing"
              style={{
                objectFit: "contain",
              }}
            />
          </div>
          {/* Text Section */}
          <div>
            <p
              className="desc"
              style={{
                fontSize: "1.2rem",
                textAlign: "justify",
              }}
            >
              La démarche <strong>Learning by Doing</strong> fait de chaque
              nouveau concept un prétexte à l'action.
            </p>
            <p
              className="desc"
              style={{
                fontSize: "1.2rem",
                textAlign: "justify",
              }}
            >
              Que ce soit pour déployer une application conteneurisée sur un
              cluster Kubernetes, configurer le stockage ou déboguer des
              problèmes applicatifs et d'infrastructure, vous le mettez
              immédiatement en pratique à travers des exercices et des ateliers
              concrets.
            </p>
            <p
              className="desc"
              style={{
                fontSize: "1.2rem",
                textAlign: "justify",
              }}
            >
              Cette approche concrète vous plonge dans des situations
              professionnelles réelles, pour une mise en pratique immédiate, et
              grâce aux ateliers en petits groupes, vous consolidez vos
              connaissances tout en profitant d'échanges constructifs. Au fil de
              la formation, vous devenez progressivement opérationnel sur
              l'ensemble des briques essentielles de Kubernetes.
            </p>
          </div>
        </div>
        <h2 className="text-center sl-title mt-50" style={{ fontSize: "3rem" }}>
          Conçu et Enseigné par les Meilleurs Experts
        </h2>
        <div className="d-flex flex-column flex-lg-row align-items-start gap-60">
          {/* Text Section */}
          <div style={{ marginTop: "4rem", marginRight: "2rem" }}>
            <p
              className="desc"
              style={{
                fontSize: "1.2rem",
                marginBottom: "40px",
                textAlign: "justify",
              }}
            >
              Ce Bootcamp a été soigneusement conçu par nos experts Kubernetes,
              qui cumulent plusieurs dizaines d'années d'expérience dans la mise
              en œuvre des architectures et des implémentations de projets Cloud
              Native en entreprise.
            </p>
            <p
              className="desc"
              style={{
                fontSize: "1.2rem",
                marginBottom: "40px",
                textAlign: "justify",
              }}
            >
              Tous les formateurs de ce Bootcamp sont certifiés Certified
              Kubernetes Administrator (CKA) afin de mieux vous préparer à cet
              examen.
            </p>
            <p
              className="desc"
              style={{
                fontSize: "1.2rem",
                marginBottom: "40px",
                textAlign: "justify",
              }}
            >
              Vous voulez rencontrez les auteurs de ce Bootcamp ? Consultez les
              dates des prochaines sessions d'information
            </p>
            <div className="sl-btn my-20">
              <Link
                onClick={(e) => {
                  e.preventDefault();
                  setShowModal(true);
                }}
                className="program_download readon orange-btn main-home"
                href="#"
              >
                Contactez-nous
              </Link>
            </div>
          </div>

          {/* Image Section */}
          <div className="col-md-4 my-3 mb-lg-0 me-lg-3 col-40">
            <img
              className="instructorImage"
              src={InstructorImage}
              alt=""
              style={{
                objectFit: "contain",
              }}
            />
          </div>
        </div>
      </section>

      {/* FINANCEMENT SECTION */}
      <section id="cloud_financement" className="hero-section mb-4">
        <h6 style={{ color: "grey", fontSize: "2rem" }}>Financement</h6>
        <h2
          className="text-center sl-title"
          style={{
            fontSize: "3rem",
            fontWeight: "bolder",
          }}
        >
          Financer sa Formation
        </h2>
        <div className="d-flex flex-column flex-lg-row align-items-center">
          {/* Text Section */}
          <div>
            <p
              className="desc pr-20"
              style={{
                fontSize: "1.2rem",
                textAlign: "justify",
              }}
            >
              Bénéficiez de nos conseils d'experts pour trouver la solution de
              financement la plus adaptée quelque soit votre situation, avec la
              possibilité de couvrir jusqu'à 100% de vos frais de formation.
            </p>
            <p className="desc" style={{ fontSize: "1.2rem" }}>
              Contactez-nous pour plus d'information.
            </p>

            <div className="sl-btn my-20">
              <Link
                onClick={(e) => {
                  e.preventDefault();
                  setShowModal(true);
                }}
                className="program_download readon orange-btn main-home"
                href="#"
              >
                Contacter un conseiller
              </Link>
            </div>
          </div>

          {/* Image Section */}
          <div className="col-md-6 my-3 mb-lg-0 me-lg-3">
            <img
              className="instructorImage"
              src={ConsultantImage}
              alt="Contacter un conseiller CloudiaSys Academy"
              style={{
                objectFit: "contain",
                width: "50vw",
              }}
            />
          </div>
        </div>
      </section>

      {/* Admission section */}
      <section>
        <div
          id="cloud_admission"
          className="hero-section bg-color-grey-100 hide-on-tablet"
          style={{
            marginRight: "10rem",
            marginLeft: "10rem",
            marginBottom: "10rem",
          }}
        >
          <h6 className="mt-20" style={{ color: "grey", fontSize: "2rem" }}>
            Admission
          </h6>
          <div className="wrapper-1600 content-align-center">
            <h2 className="sl-title" style={{ fontSize: "3rem" }}>
              Comment Intégrer ce Bootcamp
            </h2>
          </div>
          <div className="wrapper-920 content-align-center mb-s3">
            <p
              className="desc"
              style={{
                fontSize: "1.2rem",
                textAlign: "center",
              }}
            >
              Notre processus d'admission garantit que vous possédez les bases
              indispensables pour évoluer dans l'environnement exigeant du
              Bootcamp Ingénieur Cloud & DevOps. Il validera vos connaissances
              informatiques nécessaires et votre capacité à assimiler et
              appliquer les concepts abordés tout au long de ce programme.
            </p>
          </div>

          <div className={isMobile ? "hidden" : "web-view"}>
            {/* Original timeline for web view */}
            <div className="wrapper-1600 flex-inline mb-s3 mt-s1">
              <div className="wrapper-250" style={{ width: "20vw" }}>
                <div className="typical-day-timeline-horizontal mb-s3">
                  <div className="timeline-divider-horizontal start"></div>
                  <div
                    className="typical-day-hour"
                    style={{
                      backgroundColor: "#ff5421",
                    }}
                  >
                    <span
                      className="sl-title"
                      style={{
                        fontSize: "1.5rem",
                        color: "white",
                        fontWeight: "bold",
                      }}
                    >
                      1
                    </span>
                  </div>
                </div>
                <div
                  className="timeline-horizontal-content"
                  style={{
                    width: "auto",
                    margin: "auto",
                  }}
                >
                  <h4 className="mb-20">Candidature</h4>
                  <p className="desc">
                    Candidatez en ligne. Vous serez contacté par notre équipe
                    d'admission sous 24 heures.
                  </p>
                </div>
              </div>
              <div className="wrapper-250" style={{ width: "20vw" }}>
                <div className="typical-day-timeline-horizontal mb-s3">
                  <div className="timeline-divider-horizontal"></div>
                  <div
                    className="typical-day-hour"
                    style={{
                      backgroundColor: "#ff5421",
                    }}
                  >
                    <span
                      className="sl-title"
                      style={{
                        fontSize: "1.5rem",
                        color: "white",
                        fontWeight: "bold",
                      }}
                    >
                      2
                    </span>
                  </div>
                </div>
                <div
                  className="timeline-horizontal-content"
                  style={{
                    width: "auto",
                    margin: "auto",
                  }}
                >
                  <h4 className="mb-20">Échange rapide</h4>
                  <p className="desc">
                    Posez toutes vos questions et partagez vos objectifs, pour
                    que nous puissions évaluer vos motivations.
                  </p>
                </div>
              </div>
              <div className="wrapper-250" style={{ width: "20vw" }}>
                <div className="typical-day-timeline-horizontal mb-s3">
                  <div className="timeline-divider-horizontal"></div>
                  <div
                    className="typical-day-hour"
                    style={{
                      backgroundColor: "#ff5421",
                    }}
                  >
                    <span
                      className="sl-title"
                      style={{
                        fontSize: "1.5rem",
                        color: "white",
                        fontWeight: "bold",
                      }}
                    >
                      3
                    </span>
                  </div>
                </div>
                <div
                  className="timeline-horizontal-content"
                  style={{
                    width: "auto",
                    margin: "auto",
                  }}
                >
                  <h4 className="mb-20">Test technique</h4>
                  <p className="desc">
                    40 minutes en ligne pour vérifier que vous disposez des
                    prérequis techniques nécessaires avant d'intégrer le
                    Bootcamp.
                  </p>
                </div>
              </div>
              <div className="wrapper-250" style={{ width: "20vw" }}>
                <div className="typical-day-timeline-horizontal mb-s3">
                  <div className="timeline-divider-horizontal end"></div>
                  <div
                    className="typical-day-hour"
                    style={{
                      backgroundColor: "#ff5421",
                    }}
                  >
                    <span
                      className="sl-title"
                      style={{
                        fontSize: "1.5rem",
                        color: "white",
                        fontWeight: "bold",
                      }}
                    >
                      4
                    </span>
                  </div>
                </div>
                <div
                  className="timeline-horizontal-content"
                  style={{
                    width: "auto",
                    margin: "auto",
                  }}
                >
                  <h4 className="mb-20">Options de financement</h4>
                  <p className="desc">
                    Identifions ensemble les solutions de prise en charge les
                    plus adaptées à votre situation.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className={isMobile ? "mobile-view" : "hidden"}>
            {/* Original timeline for web view */}
            <div className="wrapper-1600 flex-inline mb-s3 mt-s1">
              <div className="wrapper-250" style={{ width: "100%" }}>
                <div className="typical-day-timeline-horizontal mb-s3">
                  <div className="timeline-divider-horizontal start"></div>
                  <div className="typical-day-hour">
                    <span
                      className="sl-title"
                      style={{ fontSize: "1.5rem", color: "#ff5421" }}
                    >
                      1
                    </span>
                  </div>
                </div>
                <div className="timeline-horizontal-content w-100">
                  <div className="h6-style margin-bottom-4">Candidature</div>
                  <p className="desc">
                    Candidatez en ligne. Vous serez contacté par notre équipe
                    d'admission sous 24 heures.
                  </p>
                </div>
              </div>
              <div className="wrapper-250" style={{ width: "100%" }}>
                <div className="typical-day-timeline-horizontal mb-s3">
                  <div className="timeline-divider-horizontal"></div>
                  <div className="typical-day-hour">
                    <span
                      className="sl-title"
                      style={{ fontSize: "1.5rem", color: "#ff5421" }}
                    >
                      2
                    </span>
                  </div>
                </div>
                <div className="timeline-horizontal-content w-100">
                  <div className="h6-style margin-bottom-4">Échange rapide</div>
                  <p className="desc">
                    Posez toutes vos questions et partagez vos objectifs, pour
                    que nous puissions évaluer vos motivations.
                  </p>
                </div>
              </div>
              <div className="wrapper-250" style={{ width: "100%" }}>
                <div className="typical-day-timeline-horizontal mb-s3">
                  <div className="timeline-divider-horizontal"></div>
                  <div className="typical-day-hour">
                    <span
                      className="sl-title"
                      style={{ fontSize: "1.5rem", color: "#ff5421" }}
                    >
                      3
                    </span>
                  </div>
                </div>
                <div className="timeline-horizontal-content w-100">
                  <div className="h6-style margin-bottom-4">Test technique</div>
                  <p className="desc">
                    40 minutes en ligne pour vérifier que vous disposez des
                    prérequis techniques nécessaires avant d'intégrer le
                    Bootcamp.
                  </p>
                </div>
              </div>
              <div className="wrapper-250" style={{ width: "100%" }}>
                <div className="typical-day-timeline-horizontal mb-s3">
                  <div className="timeline-divider-horizontal end"></div>
                  <div className="typical-day-hour">
                    <span
                      className="sl-title"
                      style={{ fontSize: "1.5rem", color: "#ff5421" }}
                    >
                      4
                    </span>
                  </div>
                </div>
                <div
                  className="timeline-horizontal-content"
                  style={{ width: "100%" }}
                >
                  <div className="h6-style margin-bottom-4">
                    Options de financement
                  </div>
                  <p className="desc">
                    Identifions ensemble les solutions de prise en charge les
                    plus adaptées à votre situation.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="sl-btn mb-30 postuler_btn">
            <Link
              onClick={(e) => {
                e.preventDefault();
                setShowModal(true);
              }}
              className="readon orange-btn main-home"
              to="#"
            >
              Postuler
            </Link>
          </div>

          {/* <a href="/postuler" className="hero-button mt-s2 w-button">
              Postuler
            </a> */}
        </div>
      </section>

      {/* Contact Modal */}
      {showModal && (
        <div
          className="modal-overlay"
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 1000,
          }}
        >
          <div
            className="modal-content"
            style={{
              backgroundColor: "white",
              padding: "2rem",
              borderRadius: "8px",
              width: "90%",
              maxWidth: "500px",
            }}
          >
            <h3 style={{ marginBottom: "1.5rem" }}>Contactez-nous</h3>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div style={{ marginBottom: "1rem" }}>
                <label
                  htmlFor="fullName"
                  style={{ display: "block", marginBottom: "0.5rem" }}
                >
                  Nom complet *
                </label>
                <input
                  type="text"
                  id="fullName"
                  name="fullName"
                  {...register("fullName", { required: true })}
                  style={{
                    width: "100%",
                    padding: "0.5rem",
                    borderRadius: "4px",
                    border: "1px solid #ddd",
                  }}
                />
              </div>
              <div style={{ marginBottom: "1rem" }}>
                <label
                  htmlFor="email"
                  style={{ display: "block", marginBottom: "0.5rem" }}
                >
                  Email *
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  {...register("email", { required: true })}
                  style={{
                    width: "100%",
                    padding: "0.5rem",
                    borderRadius: "4px",
                    border: "1px solid #ddd",
                  }}
                />
              </div>
              <div style={{ marginBottom: "1.5rem" }}>
                <label
                  htmlFor="message"
                  style={{ display: "block", marginBottom: "0.5rem" }}
                >
                  Message *
                </label>
                <textarea
                  id="message"
                  name="message"
                  {...register("message", { required: true })}
                  rows="4"
                  style={{
                    width: "100%",
                    padding: "0.5rem",
                    borderRadius: "4px",
                    border: "1px solid #ddd",
                  }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: "1rem",
                }}
              >
                <button
                  type="button"
                  onClick={() => setShowModal(false)}
                  disabled={isSubmitting}
                  style={{
                    padding: "0.5rem 1rem",
                    borderRadius: "4px",
                    border: "1px solid #ddd",
                    backgroundColor: "#fff",
                    cursor: isSubmitting ? "not-allowed" : "pointer",
                    opacity: isSubmitting ? 0.7 : 1,
                  }}
                >
                  Fermer
                </button>
                <button
                  type="submit"
                  className="readon orange-btn main-home"
                  disabled={isSubmitting}
                  style={{
                    padding: "0.5rem 1rem",
                    borderRadius: "4px",
                    border: "none",
                    cursor: isSubmitting ? "not-allowed" : "pointer",
                    opacity: isSubmitting ? 0.7 : 1,
                  }}
                >
                  {isSubmitting ? "Envoi en cours..." : "Envoyer"}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {/* Download Modal */}
      {showDownloadModal && (
        <div
          className="modal-overlay"
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 1000,
          }}
        >
          <div
            className="modal-content"
            style={{
              backgroundColor: "white",
              padding: "2rem",
              borderRadius: "8px",
              width: "90%",
              maxWidth: "500px",
            }}
          >
            <h3 style={{ marginBottom: "1.5rem" }}>Télécharger le Programme</h3>
            <form onSubmit={handleSubmit(handleDownloadRequest)}>
              <div style={{ marginBottom: "1rem" }}>
                <label
                  htmlFor="fullName"
                  style={{ display: "block", marginBottom: "0.5rem" }}
                >
                  Prénom & Nom *
                </label>
                <input
                  type="text"
                  id="fullName"
                  {...register("fullName", {
                    required: "Nom complet est requis",
                    minLength: {
                      value: 5,
                      message:
                        "Le nom complet doit contenir au moins 5 caractères",
                    },
                  })}
                  style={{
                    width: "100%",
                    padding: "0.5rem",
                    borderRadius: "4px",
                    border: errors.fullName
                      ? "1px solid red"
                      : "1px solid #ddd",
                  }}
                />
                {errors.fullName && (
                  <span style={{ color: "red", fontSize: "0.8rem" }}>
                    {errors.fullName.message}
                  </span>
                )}
              </div>
              <div style={{ marginBottom: "1rem" }}>
                <label
                  htmlFor="email"
                  style={{ display: "block", marginBottom: "0.5rem" }}
                >
                  Email *
                </label>
                <input
                  type="email"
                  id="email"
                  {...register("email", {
                    required: "Email est requis",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: "Email invalide",
                    },
                  })}
                  style={{
                    width: "100%",
                    padding: "0.5rem",
                    borderRadius: "4px",
                    border: errors.email ? "1px solid red" : "1px solid #ddd",
                  }}
                />

                {errors.email && (
                  <span style={{ color: "red", fontSize: "0.8rem" }}>
                    {errors.email.message}
                  </span>
                )}
              </div>
              <div style={{ marginBottom: "1.5rem" }}>
                <label
                  htmlFor="phoneNumber"
                  style={{ display: "block", marginBottom: "0.5rem" }}
                >
                  Numéro de téléphone *
                </label>
                <input
                  type="tel"
                  id="phoneNumber"
                  {...register("phoneNumber", {
                    required: "Numéro de téléphone est requis",
                    pattern: {
                      value: /^[0-9+\s-]{8,}$/,
                      message: "Numéro de téléphone invalide",
                    },
                  })}
                  style={{
                    width: "100%",
                    padding: "0.5rem",
                    borderRadius: "4px",
                    border: errors.phoneNumber
                      ? "1px solid red"
                      : "1px solid #ddd",
                  }}
                />
                {errors.phoneNumber && (
                  <span style={{ color: "red", fontSize: "0.8rem" }}>
                    {errors.phoneNumber.message}
                  </span>
                )}
              </div>
              <div>
                <input
                  type="hidden"
                  {...register("programme")}
                  defaultValue="Ansible - Automatiser la Gestion des Serveurs"
                />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: "1rem",
                }}
              >
                <button
                  type="button"
                  onClick={() => setShowDownloadModal(false)}
                  disabled={isSubmitting}
                  style={{
                    padding: "0.5rem 1rem",
                    borderRadius: "4px",
                    border: "1px solid #ddd",
                    backgroundColor: "#fff",
                    cursor: isSubmitting ? "not-allowed" : "pointer",
                    opacity: isSubmitting ? 0.7 : 1,
                  }}
                >
                  Fermer
                </button>
                <button
                  type="submit"
                  className="readon orange-btn main-home"
                  disabled={isSubmitting}
                  style={{
                    padding: "0.5rem 1rem",
                    borderRadius: "4px",
                    border: "none",
                    cursor: isSubmitting ? "not-allowed" : "pointer",
                    opacity: isSubmitting ? 0.7 : 1,
                  }}
                >
                  {isSubmitting ? "Traitement..." : "Télécharger"}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {/* scrolltop-start */}
      <ScrollToTop scrollClassName="scrollup orange-color" />
      {/* scrolltop-end */}
    </React.Fragment>
  );
};

export default CourseDetailsMain;
