import React from "react";
import { Link } from "react-router-dom";

const SiteBreadcrumb = (props) => {
  const {
    breadcrumbsClass,
    innerClass,
    titleClass,
    pageTitle,
    parentCategory,
    pageCategory,
    pageName,
    breadcrumbsImg,
  } = props;

  return (
    <div className={breadcrumbsClass ?? "rs-breadcrumbs breadcrumbs-overlay"}>
      <div className="breadcrumbs-img">
        <img
          src={breadcrumbsImg}
          alt={pageTitle}
          style={{
            objectFit: "cover",
          }}
        />
      </div>
      <div className={innerClass ?? "breadcrumbs-text white-color"}>
        <h1 className={titleClass ?? "page-title"}>{pageTitle}</h1>
        <ul>
          <li>
            <Link to="/" className="active">
              {parentCategory ?? "Home"}
            </Link>
          </li>
          {pageCategory ? (
            <>
              <li>
                <Link to="/" className="active">
                  {pageCategory ?? "Category"}
                </Link>
              </li>
              <li>{pageName ?? "Page Name"}</li>
            </>
          ) : (
            <li>{pageName ?? "Page Name"}</li>
          )}
        </ul>
      </div>
    </div>
  );
};

export default SiteBreadcrumb;
