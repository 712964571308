import React, { useState } from "react";
import { useForm } from "react-hook-form";
import emailjs from "@emailjs/browser";
import PropTypes from "prop-types";
import { getDomain } from "../../../utils/helpers";
// Image
import videoImg from "../../../assets/img/about/about-video-bg2.png";

const CourseSidebar = ({ courseDetails }) => {
  const [showModal, setShowModal] = useState(false);
  const [showDownloadModal, setShowDownloadModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const sendEmail = async (formData) => {
    try {
      setIsSubmitting(true);

      const templateParams = {
        from_name: formData.fullName,
        from_email: formData.email,
        message: formData.message,
      };

      const response = await emailjs.send(
        "service_gmail_houcem",
        "template_rcihop6",
        templateParams,
        "-N1Go-oGKNUw9N77D"
      );

      if (response.status === 200) {
        alert("Message envoyé avec succès!");
        setShowModal(false);
        reset();
      }
    } catch (error) {
      console.error("Error sending email:", error);
      alert(
        "Une erreur est survenue lors de l'envoi du message. Veuillez réessayer."
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleDownloadRequest = async (data) => {
    try {
      setIsSubmitting(true);

      // Send email with download request info
      const templateParams = {
        programme: data.programme,
        email: data.email,
        phone: data.phoneNumber,
        fullName: data.fullName,
        request_type: "Programme Download Request",
      };

      await emailjs.send(
        "service_gmail_houcem",
        "template_a7sa338",
        templateParams,
        "-N1Go-oGKNUw9N77D"
      );

      // Download from backend API
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/formations/download/${courseDetails?.reference}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      // Get the blob from the response
      const blob = await response.blob();

      // Create a URL for the blob
      const pdfUrl = window.URL.createObjectURL(blob);

      // Create a temporary link and trigger download
      const link = document.createElement("a");
      link.href = pdfUrl;
      link.download = `formation-${
        courseDetails?.reference || "programme"
      }.pdf`;
      document.body.appendChild(link);
      link.click();

      // Clean up
      document.body.removeChild(link);
      window.URL.revokeObjectURL(pdfUrl); // Free up memory

      // Hide modal and reset form
      setShowDownloadModal(false);
      reset();
    } catch (error) {
      console.error("Error processing download request:", error);
      alert(
        "Une erreur est survenue lors du téléchargement. Veuillez réessayer."
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  const onSubmit = (data) => {
    sendEmail(data);
  };

  return (
    <div className="inner-column">
      <div className="intro-video media-icon orange-color2">
        <img
          src={`/images/courses/${courseDetails?.reference}.jpg`}
          alt={courseDetails?.titre}
          style={{ objectFit: "cover", width: "100%", height: "100%" }}
        />
      </div>
      <div className="course-features-info">
        <ul>
          {courseDetails?.thematique && (
            <li className="lectures-feature">
              <i className="fa fa-sitemap" style={{ width: "1.2rem" }}></i>
              <span className="label">Domaine</span>
              <span className="value">
                {getDomain(courseDetails.thematique)}
              </span>
            </li>
          )}

          {courseDetails?.plateforme && (
            <li className="lectures-feature">
              <i className="fa fa-desktop" style={{ width: "1.2rem" }}></i>
              <span className="label">Plateforme</span>
              <span className="value">{courseDetails.plateforme}</span>
            </li>
          )}
          {courseDetails?.prix && (
            <li className="lectures-feature">
              <i className="fa fa-eur" style={{ width: "1.2rem" }}></i>
              <span className="label">Prix</span>
              <span className="value">{courseDetails.prix} HT</span>
            </li>
          )}

          {courseDetails?.tarif && (
            <li className="quizzes-feature">
              <i className="fa fa-puzzle-piece" style={{ width: "1.2rem" }}></i>
              <span className="label">Tarif</span>
              <span className="value">{courseDetails.tarif} HT</span>
            </li>
          )}

          {courseDetails?.durée && (
            <li className="duration-feature">
              <i className="fa fa-clock-o" style={{ width: "1.2rem" }}></i>
              <span className="label">Durée</span>
              <span className="value">{courseDetails.durée}</span>
            </li>
          )}

          {courseDetails?.modalité && (
            <li className="students-feature">
              <i className="fa fa-video-camera" style={{ width: "1.2rem" }}></i>
              <span className="label">Modalité</span>
              <span className="value">{courseDetails.modalité}</span>
            </li>
          )}

          {courseDetails?.reference && (
            <li className="students-feature">
              <i className="fa fa-hashtag" style={{ width: "1.2rem" }}></i>
              <span className="label">Référence</span>
              <span className="value">{courseDetails.reference}</span>
            </li>
          )}
        </ul>
      </div>
      <div
        className="btn-part"
        style={{
          paddingLeft: "40px",
          paddingRight: "40px",
        }}
      >
        <div className="sl-btn">
          <a
            className="readon orange-btn main-home"
            href="#"
            onClick={(e) => {
              e.preventDefault();
              setShowDownloadModal(true);
            }}
            style={{ width: "-webkit-fill-available", textAlign: "center" }}
          >
            TÉLÉCHARGER LE PROGRAMME
          </a>
        </div>
        <div className="sl-btn mt-20 w-100">
          <a
            className="readon orange-btn main-home"
            href="#"
            onClick={(e) => {
              e.preventDefault();
              setShowModal(true);
            }}
            style={{ width: "-webkit-fill-available", textAlign: "center" }}
          >
            NOUS CONTACTER
          </a>
        </div>
      </div>

      {/* Download Modal */}
      {showDownloadModal && (
        <div
          className="modal-overlay"
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 1000,
          }}
        >
          <div
            className="modal-content"
            style={{
              backgroundColor: "white",
              padding: "2rem",
              borderRadius: "8px",
              width: "90%",
              maxWidth: "500px",
            }}
          >
            <h3 style={{ marginBottom: "1.5rem" }}>Télécharger le Programme</h3>
            <form onSubmit={handleSubmit(handleDownloadRequest)}>
              <div style={{ marginBottom: "1rem" }}>
                <label
                  htmlFor="fullName"
                  style={{ display: "block", marginBottom: "0.5rem" }}
                >
                  Prénom & Nom *
                </label>
                <input
                  type="text"
                  id="fullName"
                  {...register("fullName", {
                    required: "Nom complet est requis",
                    minLength: {
                      value: 5,
                      message:
                        "Le nom complet doit contenir au moins 5 caractères",
                    },
                  })}
                  style={{
                    width: "100%",
                    padding: "0.5rem",
                    borderRadius: "4px",
                    border: errors.fullName
                      ? "1px solid red"
                      : "1px solid #ddd",
                  }}
                />
                {errors.fullName && (
                  <span style={{ color: "red", fontSize: "0.8rem" }}>
                    {errors.fullName.message}
                  </span>
                )}
              </div>
              <div style={{ marginBottom: "1rem" }}>
                <label
                  htmlFor="email"
                  style={{ display: "block", marginBottom: "0.5rem" }}
                >
                  Email *
                </label>
                <input
                  type="email"
                  id="email"
                  {...register("email", {
                    required: "Email est requis",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: "Email invalide",
                    },
                  })}
                  style={{
                    width: "100%",
                    padding: "0.5rem",
                    borderRadius: "4px",
                    border: errors.email ? "1px solid red" : "1px solid #ddd",
                  }}
                />

                {errors.email && (
                  <span style={{ color: "red", fontSize: "0.8rem" }}>
                    {errors.email.message}
                  </span>
                )}
              </div>
              <div style={{ marginBottom: "1.5rem" }}>
                <label
                  htmlFor="phoneNumber"
                  style={{ display: "block", marginBottom: "0.5rem" }}
                >
                  Numéro de téléphone *
                </label>
                <input
                  type="tel"
                  id="phoneNumber"
                  {...register("phoneNumber", {
                    required: "Numéro de téléphone est requis",
                    pattern: {
                      value: /^[0-9+\s-]{8,}$/,
                      message: "Numéro de téléphone invalide",
                    },
                  })}
                  style={{
                    width: "100%",
                    padding: "0.5rem",
                    borderRadius: "4px",
                    border: errors.phoneNumber
                      ? "1px solid red"
                      : "1px solid #ddd",
                  }}
                />
                {errors.phoneNumber && (
                  <span style={{ color: "red", fontSize: "0.8rem" }}>
                    {errors.phoneNumber.message}
                  </span>
                )}
              </div>
              <div>
                <input
                  type="hidden"
                  {...register("programme")}
                  defaultValue="Ansible - Automatiser la Gestion des Serveurs"
                />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: "1rem",
                }}
              >
                <button
                  type="button"
                  onClick={() => setShowDownloadModal(false)}
                  disabled={isSubmitting}
                  style={{
                    padding: "0.5rem 1rem",
                    borderRadius: "4px",
                    border: "1px solid #ddd",
                    backgroundColor: "#fff",
                    cursor: isSubmitting ? "not-allowed" : "pointer",
                    opacity: isSubmitting ? 0.7 : 1,
                  }}
                >
                  Fermer
                </button>
                <button
                  type="submit"
                  className="readon orange-btn main-home"
                  disabled={isSubmitting}
                  style={{
                    padding: "0.5rem 1rem",
                    borderRadius: "4px",
                    border: "none",
                    cursor: isSubmitting ? "not-allowed" : "pointer",
                    opacity: isSubmitting ? 0.7 : 1,
                  }}
                >
                  {isSubmitting ? "Traitement..." : "Télécharger"}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {/* Contact Modal */}
      {showModal && (
        <div
          className="modal-overlay"
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 1000,
          }}
        >
          <div
            className="modal-content"
            style={{
              backgroundColor: "white",
              padding: "2rem",
              borderRadius: "8px",
              width: "90%",
              maxWidth: "500px",
            }}
          >
            <h3 style={{ marginBottom: "1.5rem" }}>Contactez-nous</h3>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div style={{ marginBottom: "1rem" }}>
                <label
                  htmlFor="fullName"
                  style={{ display: "block", marginBottom: "0.5rem" }}
                >
                  Nom complet *
                </label>
                <input
                  type="text"
                  id="fullName"
                  name="fullName"
                  {...register("fullName", { required: true })}
                  style={{
                    width: "100%",
                    padding: "0.5rem",
                    borderRadius: "4px",
                    border: "1px solid #ddd",
                  }}
                />
              </div>
              <div style={{ marginBottom: "1rem" }}>
                <label
                  htmlFor="email"
                  style={{ display: "block", marginBottom: "0.5rem" }}
                >
                  Email *
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  {...register("email", { required: true })}
                  style={{
                    width: "100%",
                    padding: "0.5rem",
                    borderRadius: "4px",
                    border: "1px solid #ddd",
                  }}
                />
              </div>
              <div style={{ marginBottom: "1.5rem" }}>
                <label
                  htmlFor="message"
                  style={{ display: "block", marginBottom: "0.5rem" }}
                >
                  Message *
                </label>
                <textarea
                  id="message"
                  name="message"
                  {...register("message", { required: true })}
                  rows="4"
                  style={{
                    width: "100%",
                    padding: "0.5rem",
                    borderRadius: "4px",
                    border: "1px solid #ddd",
                  }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: "1rem",
                }}
              >
                <button
                  type="button"
                  onClick={() => setShowModal(false)}
                  disabled={isSubmitting}
                  style={{
                    padding: "0.5rem 1rem",
                    borderRadius: "4px",
                    border: "1px solid #ddd",
                    backgroundColor: "#fff",
                    cursor: isSubmitting ? "not-allowed" : "pointer",
                    opacity: isSubmitting ? 0.7 : 1,
                  }}
                >
                  Fermer
                </button>
                <button
                  type="submit"
                  className="readon orange-btn main-home"
                  disabled={isSubmitting}
                  style={{
                    padding: "0.5rem 1rem",
                    borderRadius: "4px",
                    border: "none",
                    cursor: isSubmitting ? "not-allowed" : "pointer",
                    opacity: isSubmitting ? 0.7 : 1,
                  }}
                >
                  {isSubmitting ? "Envoi en cours..." : "Envoyer"}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

CourseSidebar.propTypes = {
  courseDetails: PropTypes.object.isRequired,
};

export default CourseSidebar;
