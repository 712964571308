import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";

const RSMobileMenu = ({
  setMenuOpen,
  menuOpen,
  parentMenu,
  secondParentMenu,
  headerFullWidth,
}) => {
  const location = useLocation();

  const [bootcamps, setBootcamps] = useState(false);
  const [formations, setFormations] = useState(false);
  const [certifications, setCertifications] = useState(false);
  const [nousConnaitre, setNousConnaitre] = useState(false);
  const [contact, setContact] = useState(false);

  const openMobileMenu = (menu) => {
    if (menu === "bootcamps") {
      setBootcamps(!bootcamps);
      setFormations(false);
      setCertifications(false);
      setNousConnaitre(false);
      setContact(false);
    } else if (menu === "formations") {
      setBootcamps(false);
      setFormations(!formations);
      setCertifications(false);
      setNousConnaitre(false);
      setContact(false);
    } else if (menu === "certifications") {
      setBootcamps(false);
      setFormations(false);
      setCertifications(!certifications);
      setNousConnaitre(false);
      setContact(false);
    } else if (menu === "nousConnaitre") {
      setBootcamps(false);
      setFormations(false);
      setCertifications(false);
      setNousConnaitre(!nousConnaitre);
      setContact(false);
    } else if (menu === "contact") {
      setBootcamps(false);
      setFormations(false);
      setCertifications(false);
      setNousConnaitre(false);
      setContact(!contact);
    }
  };

  return (
    <div
      className={
        headerFullWidth ? "container-fluid relative" : "container relative"
      }
    >
      <div className={menuOpen ? "mobile-menu-part open" : "mobile-menu-part"}>
        <div className="mobile-menu">
          <ul className="nav-menu">
            <li
              className={
                bootcamps
                  ? "menu-item-has-children current-menu-item"
                  : "menu-item-has-children"
              }
            >
              <Link
                to="#"
                onClick={() => {
                  openMobileMenu("bootcamps");
                }}
                className={parentMenu === "bootcamps" ? "active-menu" : ""}
              >
                Bootcamps
              </Link>
              <ul className={bootcamps ? "sub-menu current-menu" : "sub-menu"}>
                <li>
                  <Link
                    to="/bootcamps/kubernetes"
                    className={
                      location.pathname === "/bootcamps/kubernetes"
                        ? "active-menu"
                        : ""
                    }
                  >
                    Orchestration Avec Kubernetes
                  </Link>
                </li>
                <li>
                  <Link
                    to="/Bootcamps/Cloud"
                    className={
                      location.pathname === "/bootcamps/cloud"
                        ? "active-menu"
                        : ""
                    }
                  >
                    Ingénieur Cloud & DevOps
                  </Link>
                </li>
              </ul>
            </li>
            <li
              className={
                formations
                  ? "menu-item-has-children current-menu-item"
                  : "menu-item-has-children"
              }
            >
              <Link
                to="#"
                onClick={() => {
                  openMobileMenu("formations");
                }}
                className={parentMenu === "formations" ? "active-menu" : ""}
              >
                Formations
              </Link>
              <ul className={formations ? "sub-menu current-menu" : "sub-menu"}>
                <li className="menu-group-title ml-40">Par thématique</li>
                <li>
                  <Link
                    to={{
                      pathname: "/course",
                      state: { domain: "cloud&Infra" },
                    }}
                  >
                    Cloud & Infrastructure
                  </Link>
                </li>
                <li>
                  <Link
                    to={{
                      pathname: "/course",
                      state: { domain: "dataAnalytics" },
                    }}
                  >
                    Data Analytics
                  </Link>
                </li>
                <li>
                  <Link
                    to={{
                      pathname: "/course",
                      state: { domain: "devops" },
                    }}
                  >
                    DevOps & DevSecOps
                  </Link>
                </li>
                <li>
                  <Link
                    to={{
                      pathname: "/course",
                      state: { domain: "IA" },
                    }}
                  >
                    Intelligence Artificielle
                  </Link>
                </li>
                <li>
                  <Link
                    to={{
                      pathname: "/course",
                      state: { domain: "dataViz" },
                    }}
                  >
                    Data Visualization
                  </Link>
                </li>
                <li>
                  <Link
                    to={{
                      pathname: "/course",
                      state: { domain: "cybersecurity" },
                    }}
                  >
                    Cyber Sécurité
                  </Link>
                </li>
                <li className="menu-group-title ml-40">Par Plateforme</li>
                <li>
                  <Link
                    to={{
                      pathname: "/course",
                      state: { platform: "azure" },
                    }}
                  >
                    Azure ( Microsoft )
                  </Link>
                </li>
                <li>
                  <Link
                    to={{
                      pathname: "/course",
                      state: { platform: "gcp" },
                    }}
                  >
                    GCP ( Google )
                  </Link>
                </li>
                <li>
                  <Link
                    to={{
                      pathname: "/course",
                      state: { platform: "kubernetes" },
                    }}
                  >
                    Kubernetes ( Linux Foundation )
                  </Link>
                </li>
              </ul>
            </li>
            <li
              className={
                certifications
                  ? "menu-item-has-children current-menu-item"
                  : "menu-item-has-children"
              }
            >
              <Link
                to="#"
                onClick={() => {
                  openMobileMenu("certifications");
                }}
                className={parentMenu === "certifications" ? "active-menu" : ""}
              >
                Certifications
              </Link>
              <ul
                className={
                  certifications ? "sub-menu current-menu" : "sub-menu"
                }
              >
                <li className="menu-group-title ml-40">Par Editeur</li>

                <li>
                  <Link to="/certifications/microsoft">
                    Microsoft ( Azure )
                  </Link>
                </li>
                <li>
                  <Link to="/certifications/google">Google ( GCP )</Link>
                </li>
                <li>
                  <Link to="/certifications/kubernetes">
                    Linux Foundation ( Kubernetes )
                  </Link>
                </li>
              </ul>
            </li>
            <li
              className={
                nousConnaitre
                  ? "menu-item-has-children current-menu-item"
                  : "menu-item-has-children"
              }
            >
              <Link
                to="#"
                onClick={() => {
                  openMobileMenu("nousConnaitre");
                }}
                className={parentMenu === "nousConnaitre" ? "active-menu" : ""}
              >
                Nous Connaitre
              </Link>
              <ul
                className={nousConnaitre ? "sub-menu current-menu" : "sub-menu"}
              >
                <li className="menu-group-title ml-40">CloudiaSys Academy</li>
                <li>
                  <Link to="/about/nousConnaitre">Qui Sommes-Nous</Link>
                </li>
                <li>
                  <Link to="/about/methode">Notre Méthode</Link>
                </li>
                {/* <li>
                  <Link to="/nous-connaitre/nos-partenaires">
                    Nos Partenaires
                  </Link>
                </li> */}
                <li className="menu-group-title ml-40">Financement</li>
                <li>
                  <Link to="/about/financement">
                    Dispositifs De Financement
                  </Link>
                </li>
              </ul>
            </li>
            <li
              className={
                contact
                  ? "menu-item-has-children current-menu-item"
                  : "menu-item-has-children"
              }
            >
              <Link
                to="#"
                onClick={() => {
                  openMobileMenu("contact");
                }}
                className={parentMenu === "contact" ? "active-menu" : ""}
              >
                Contact
              </Link>
              <ul className={contact ? "sub-menu current-menu" : "sub-menu"}>
                <li>
                  <Link
                    to="/contact"
                    className={
                      location.pathname === "/contact" ? "active-menu" : ""
                    }
                  >
                    Nous contacter
                  </Link>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default RSMobileMenu;
