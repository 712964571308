import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import emailjs from "@emailjs/browser";
import { GiCheckMark } from "react-icons/gi";

import ScrollToTop from "../../../components/Common/ScrollTop";
import kubernetesLogo from "../../../assets/img/bootcamps/kubernetes.png";
import aksLogo from "../../../assets/img/bootcamps/azure-kubernetes-service.png";
import ckaLogo from "../../../assets/img/bootcamps/CKALogo.png";
import linuxLogo from "../../../assets/img/bootcamps/linux_logo.png";
import dockerLogo from "../../../assets/img/bootcamps/docker_logo.png";
import aagLogo from "../../../assets/img/bootcamps/azure-application-gateway.png";
import azureEntraIDLogo from "../../../assets/img/bootcamps/azure_entra_ID.png";
import LearningByDoing from "../../../assets/img/bootcamps/learning_by_doing.jpg";
import InstructorImage from "../../../assets/img/bootcamps/03-Photo-Formateurs-Experts.jpg";
import ConsultantImage from "../../../assets/img/bootcamps/conseiller.png";

import "./style.css";

const CourseDetailsMain = () => {
  const [showModal, setShowModal] = useState(false);
  const [showDownloadModal, setShowDownloadModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedSection, setSelectedSection] = useState("intro");
  const [isMobile, setIsMobile] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const sendEmail = async (formData) => {
    try {
      setIsSubmitting(true);

      const templateParams = {
        from_name: formData.fullName,
        from_email: formData.email,
        message: formData.message,
      };

      const response = await emailjs.send(
        "service_gmail_houcem",
        "template_rcihop6",
        templateParams,
        "-N1Go-oGKNUw9N77D"
      );

      if (response.status === 200) {
        alert("Message envoyé avec succès!");
        setShowModal(false);
        reset();
      }
    } catch (error) {
      console.error("Error sending email:", error);
      alert(
        "Une erreur est survenue lors de l'envoi du message. Veuillez réessayer."
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  const onSubmit = (data) => {
    sendEmail(data);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Adjust breakpoint as needed
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleMenuClick = (e, section) => {
    e.preventDefault();
    setSelectedSection(section);
    const targetSection = document.getElementById(section);
    if (targetSection) {
      targetSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  const isSectionActive = (section) => {
    return selectedSection === section;
  };

  const handleDownloadRequest = async (data) => {
    try {
      const pdfUrl = `${process.env.PUBLIC_URL}/assets/bootcamps/Bootcamp Kubernetes FR.pdf`;

      const link = document.createElement("a");
      link.href = pdfUrl;
      link.setAttribute("download", "Bootcamp Kubernetes FR.pdf"); // Set the download attribute
      link.setAttribute("target", "_blank"); // Optional: open in new tab if download fails

      // Append to document, click and remove
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setShowDownloadModal(false);
    } catch (error) {
      console.error("Error downloading PDF:", error);
      setShowDownloadModal(false);
      // You can add error handling here, like showing a notification to the user
    }
  };

  return (
    <React.Fragment>
      <div style={{ "margin-top": "70px" }}>
        <div className="fixed-training-menu full-size-block bg-color-grey-50 hide-on-tablet menu-area">
          <div className="hero-section width-100 plr-8vw justify-center no-padding">
            <div className="wrapper-menu-slide">
              <a
                href="#intro"
                onClick={(e) => handleMenuClick(e, "intro")}
                className={`item-menu height-5vh ${
                  isSectionActive("intro") ? "active" : ""
                }`}
              >
                Intro
              </a>
              <a
                href="#programme"
                onClick={(e) => handleMenuClick(e, "programme")}
                className={`item-menu height-5vh ${
                  isSectionActive("programme") ? "active" : ""
                }`}
              >
                Programme
              </a>
              <a
                href="#méthodologie"
                onClick={(e) => handleMenuClick(e, "méthodologie")}
                className={`item-menu height-5vh ${
                  isSectionActive("méthodologie") ? "active" : ""
                }`}
              >
                Méthodologie
              </a>
              <a
                href="#financement"
                onClick={(e) => handleMenuClick(e, "financement")}
                className={`item-menu height-5vh ${
                  isSectionActive("financement") ? "active" : ""
                }`}
              >
                Financement
              </a>
              <a
                href="#admission"
                onClick={(e) => handleMenuClick(e, "admission")}
                className={`item-menu height-5vh ${
                  isSectionActive("admission") ? "active" : ""
                }`}
              >
                Admission
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* INTRO SECTION */}
      <section id="intro" className="hero-section">
        <div className="wrapper-1600 flex flex-col items-center space-y-6">
          <h1 className="sl-title text-center" style={{ fontSize: "3rem" }}>
            4 Semaines Intensives pour maitriser Kubernetes
          </h1>
          <div
            style={{
              marginTop: "3vh",
              display: "flex",
              flexDirection: "row", // Default direction for larger screens
              justifyContent: "space-around",
              alignItems: "stretch",
              gap: "5rem",
              flexWrap: "wrap", // Allows wrapping for responsiveness
            }}
          >
            {/* First Item */}
            <div
              style={{
                textAlign: "center",
                width: "100%",
                maxWidth: "300px",
              }}
            >
              <img
                src={kubernetesLogo}
                alt="Kubernetes"
                style={{
                  height: "100px",
                  objectFit: "contain",
                }}
              />
              <p className="desc" style={{ color: "#4a4a4a" }}>
                Maitriser l'orchestration des applications conteneurisées et
                l'administration des <strong>Clusters Kubernetes</strong>
              </p>
            </div>

            {/* Empty Spacer */}
            <div
              style={{
                width: "60px",
                display: "none", // Hide spacers for mobile
              }}
              className="spacer"
            ></div>

            {/* Second Item */}
            <div
              style={{
                textAlign: "center",
                width: "100%",
                maxWidth: "300px",
              }}
            >
              <img
                src={aksLogo}
                alt="Azure Kubernetes Service"
                style={{
                  height: "100px",
                  objectFit: "contain",
                }}
              />
              <p className="desc" style={{ color: "#4a4a4a" }}>
                Maitriser Kubernetes sur le Cloud <strong>Azure</strong> avec
                son service managé{" "}
                <strong>Azure Kubernetes Service (AKS)</strong>
              </p>
            </div>

            {/* Empty Spacer */}
            <div
              style={{
                width: "60px",
                display: "none", // Hide spacers for mobile
              }}
              className="spacer"
            ></div>

            {/* Third Item */}
            <div
              style={{
                textAlign: "center",
                width: "100%",
                maxWidth: "300px",
              }}
            >
              <img
                src={ckaLogo}
                alt="CKA"
                style={{
                  height: "100px",
                  objectFit: "contain",
                }}
              />
              <p className="desc" style={{ color: "#4a4a4a" }}>
                Bénéficiez d'une préparation à la certification{" "}
                <strong>CKA</strong>, fortement plébiscitée sur le marché
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* PROGRAMME SECTION */}
      <section
        id="programme"
        className="hero-section mb-4"
        style={{
          paddingRight: "2vw",
          paddingLeft: "2vw",
        }}
      >
        <h6 style={{ color: "grey", fontSize: "2rem" }}>Programme</h6>
        <h2 className="text-center sl-title" style={{ fontSize: "3rem" }}>
          Ce que vous allez apprendre pendant ce Bootcamp
        </h2>
        <p
          className="desc text-center text-muted fs-5 mb-4 mx-lg-20"
          style={{ padding: "0px 4rem " }}
        >
          Maitriser l'orchestration des applications conteneurisées, administrer
          des clusters Kubernetes et préparer l'examen de la certification
          Certified Kubernetes Administrator (CKA)
        </p>

        {/* GRID POUR AFFICHAGE EN DEUX COLONNES */}
        <div
          className="container mt-40"
          style={{
            maxWidth: "fit-content",
          }}
        >
          <div className="row">
            {/* Onglets à gauche */}
            <div className="col-md-5">
              {/* Mobile View Tabs */}
              <div className={isMobile ? "mobile-tabs" : "d-none"}>
                <div
                  className="list-group"
                  id="list-tab-mobile"
                  role="tablist"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    overflowX: "auto",
                    WebkitOverflowScrolling: "touch",
                    scrollbarWidth: "none",
                    msOverflowStyle: "none",
                  }}
                >
                  <button
                    className="list-group-item list-group-item-action active"
                    id="list-home-list-mobile"
                    data-bs-toggle="list"
                    data-bs-target="#list-home"
                    type="button"
                    role="tab"
                    aria-controls="list-home"
                    style={{
                      fontSize: "large",
                      padding: "1rem",
                      whiteSpace: "nowrap",
                      minWidth: "fit-content",
                    }}
                  >
                    Orchestration des Conteneurs avec Kubernetes
                  </button>
                  <button
                    className="list-group-item list-group-item-action"
                    id="list-profile-list-mobile"
                    data-bs-toggle="list"
                    data-bs-target="#list-profile"
                    type="button"
                    role="tab"
                    aria-controls="list-profile"
                    style={{
                      fontSize: "large",
                      padding: "1rem",
                      whiteSpace: "nowrap",
                      minWidth: "fit-content",
                    }}
                  >
                    Gérer des Clusters Kubernetes en Production
                  </button>
                  <button
                    className="list-group-item list-group-item-action"
                    id="list-messages-list-mobile"
                    data-bs-toggle="list"
                    data-bs-target="#list-messages"
                    type="button"
                    role="tab"
                    aria-controls="list-messages"
                    style={{
                      fontSize: "large",
                      padding: "1rem",
                      whiteSpace: "nowrap",
                      minWidth: "fit-content",
                    }}
                  >
                    Kubernetes sur Microsoft Azure avec AKS
                  </button>
                  <button
                    className="list-group-item list-group-item-action"
                    id="list-messages-list-mobile-2"
                    data-bs-toggle="list"
                    data-bs-target="#list-messages-2"
                    type="button"
                    role="tab"
                    aria-controls="list-messages-2"
                    style={{
                      fontSize: "large",
                      padding: "1rem",
                      whiteSpace: "nowrap",
                      minWidth: "fit-content",
                    }}
                  >
                    Préparation à la certification CKA
                  </button>
                </div>
              </div>

              {/* Desktop View Tabs */}
              <div className={!isMobile ? "desktop-tabs" : "d-none"}>
                <div className="list-group" id="list-tab" role="tablist">
                  <button
                    className="list-group-item list-group-item-action active"
                    id="list-home-list"
                    data-bs-toggle="list"
                    data-bs-target="#list-home"
                    type="button"
                    role="tab"
                    aria-controls="list-home"
                    style={{
                      fontSize: "large",
                      padding: "1rem",
                    }}
                  >
                    Orchestration des Conteneurs avec Kubernetes
                  </button>
                  <button
                    className="list-group-item list-group-item-action"
                    id="list-profile-list"
                    data-bs-toggle="list"
                    data-bs-target="#list-profile"
                    type="button"
                    role="tab"
                    aria-controls="list-profile"
                    style={{
                      fontSize: "large",
                      padding: "1rem",
                    }}
                  >
                    Gérer des Clusters Kubernetes en Production
                  </button>
                  <button
                    className="list-group-item list-group-item-action"
                    id="list-messages-list"
                    data-bs-toggle="list"
                    data-bs-target="#list-messages"
                    type="button"
                    role="tab"
                    aria-controls="list-messages"
                    style={{
                      fontSize: "large",
                      padding: "1rem",
                    }}
                  >
                    Kubernetes sur Microsoft Azure avec AKS
                  </button>
                  <button
                    className="list-group-item list-group-item-action"
                    id="list-messages-list-2"
                    data-bs-toggle="list"
                    data-bs-target="#list-messages-2"
                    type="button"
                    role="tab"
                    aria-controls="list-messages-2"
                    style={{
                      fontSize: "large",
                      padding: "1rem",
                    }}
                  >
                    Préparation à la certification CKA
                  </button>
                </div>
              </div>

              <div
                className="sl-btn mb-20"
                style={{
                  marginTop: "3rem",
                  marginBottom: "2rem",
                  display: isMobile ? "none" : "block",
                }}
              >
                <Link
                  onClick={(e) => {
                    e.preventDefault();
                    setShowDownloadModal(true);
                  }}
                  className="program_download readon orange-btn main-home"
                  to="#"
                >
                  Télécharger le programme
                </Link>
              </div>
            </div>

            {/* Contenu à droite */}
            <div
              className="col-md-7"
              style={{ marginTop: isMobile ? "2.5rem" : "0" }}
            >
              <div
                className="tab-content"
                id="nav-tabContent"
                style={{
                  paddingTop: "unset",
                  border: "none",
                }}
              >
                <div
                  className="tab-pane fade show active"
                  id="list-home"
                  role="tabpanel"
                  aria-labelledby="list-home-list"
                >
                  <h3>
                    Les Fondamentaux de Kubernetes pour Orchestrer les
                    Conteneurs
                  </h3>
                  <p className="desc" style={{ fontSize: "1.2rem" }}>
                    Après une présentation des aspects liés à la
                    conteneurisation et à l'orchestration des conteneurs, vous
                    allez plonger dans l'univers de Kubernetes en maitrisant les
                    fondamentaux de cette plateforme
                  </p>
                  <h4>Concrètement, vous allez :</h4>
                  <ul className="desc">
                    <li className="mb-5">
                      <GiCheckMark
                        style={{ marginRight: "8px", color: "#ff5421" }}
                      />
                      <span style={{ fontSize: "1.2rem" }}>
                        Installer et Configurer un Cluster Kubernetes
                      </span>
                    </li>
                    <li className="mb-5">
                      <GiCheckMark
                        style={{ marginRight: "8px", color: "#ff5421" }}
                      />

                      <span style={{ fontSize: "1.2rem" }}>
                        Déployer une applications conteneurisée sur un Cluster
                        Kubernetes
                      </span>
                    </li>
                    <li className="mb-5">
                      <GiCheckMark
                        style={{ marginRight: "8px", color: "#ff5421" }}
                      />
                      <span style={{ fontSize: "1.2rem" }}>
                        Planifier, Configurer et Mettre à l'échelle les Pods et
                        le cluster Kubernetes
                      </span>
                    </li>
                    <li className="mb-5">
                      <GiCheckMark
                        style={{ marginRight: "8px", color: "#ff5421" }}
                      />
                      <span style={{ fontSize: "1.2rem" }}>
                        Implémentez le Networking sur Kubernetes
                      </span>
                    </li>
                  </ul>
                  <h4 className="mt-30">Outils Utilisés</h4>
                  <div className="d-flex justify-content-start align-items-start flex-wrap gap-5">
                    {[
                      { src: linuxLogo, alt: "Linux", label: "Linux" },
                      { src: dockerLogo, alt: "Docker", label: "Docker" },
                      {
                        src: kubernetesLogo,
                        alt: "Kubernetes",
                        label: "Kubernetes",
                      },
                    ].map((tool) => (
                      <div
                        key={tool.alt.replace(/\s+/g, "_").toLowerCase()}
                        className="d-flex align-items-center"
                        style={{
                          backgroundColor: "white",
                          padding: "10px 20px",
                          borderRadius: "10px",
                          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                        }}
                      >
                        <img
                          src={tool.src}
                          alt={tool.alt}
                          style={{
                            width: "40px",
                            height: "40px",
                            objectFit: "contain",
                            marginRight: "10px",
                          }}
                        />
                        <h5
                          className="fw-bold"
                          style={{ fontSize: "0.9rem", marginBottom: "0" }}
                        >
                          {tool.label}
                        </h5>
                      </div>
                    ))}
                  </div>
                </div>

                <div
                  className="tab-pane fade"
                  id="list-profile"
                  role="tabpanel"
                  aria-labelledby="list-profile-list"
                >
                  <h3>
                    Administrer Kubernetes dans un environnement de Production
                  </h3>
                  <p className="desc" style={{ fontSize: "1.2rem" }}>
                    Apprenez à administrer et à maintenir en conditions
                    opérationnelles un Cluster Kubernetes et les applications
                    conteneurisées qui y sont déployées en production et
                    sécurisez l'accès à votre cluster Kubernetes.
                  </p>
                  <h4>Concrètement, vous allez :</h4>
                  <ul className="desc">
                    <li className="mb-5">
                      <GiCheckMark
                        style={{ marginRight: "8px", color: "#ff5421" }}
                      />
                      <span style={{ fontSize: "1.2rem" }}>
                        Effectuer une mise à niveau de version sur un cluster
                        Kubernetes
                      </span>
                    </li>
                    <li className="mb-5">
                      <GiCheckMark
                        style={{ marginRight: "8px", color: "#ff5421" }}
                      />
                      <span style={{ fontSize: "1.2rem" }}>
                        Sauvegarder et une restaurer la base etcd de Kubernetes
                      </span>
                    </li>
                    <li className="mb-5">
                      <GiCheckMark
                        style={{ marginRight: "8px", color: "#ff5421" }}
                      />
                      <span style={{ fontSize: "1.2rem" }}>
                        Surveiller, déboguer et résoudre les problèmes sur un
                        cluster Kubernetes
                      </span>
                    </li>
                    <li className="mb-5">
                      <GiCheckMark
                        style={{ marginRight: "8px", color: "#ff5421" }}
                      />
                      <span style={{ fontSize: "1.2rem" }}>
                        Sécuriser l'accès sur un cluster Kubernetes avec RBAC et
                        Network Policy
                      </span>
                    </li>
                  </ul>
                  <h4 className="mt-30">Outils Utilisés</h4>
                  <div className="d-flex justify-content-start align-items-start flex-wrap gap-5">
                    {[
                      { src: linuxLogo, alt: "Linux", label: "Linux" },
                      {
                        src: kubernetesLogo,
                        alt: "Kubernetes",
                        label: "Kubernetes",
                      },
                    ].map((tool) => (
                      <div
                        key={tool.alt.replace(/\s+/g, "_").toLowerCase()}
                        className="d-flex align-items-center"
                        style={{
                          backgroundColor: "white",
                          padding: "10px 20px",
                          borderRadius: "10px",
                          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                        }}
                      >
                        <img
                          src={tool.src}
                          alt={tool.alt}
                          style={{
                            width: "40px",
                            height: "40px",
                            objectFit: "contain",
                            marginRight: "10px",
                          }}
                        />
                        <h5
                          className="fw-bold"
                          style={{ fontSize: "0.9rem", marginBottom: "0" }}
                        >
                          {tool.label}
                        </h5>
                      </div>
                    ))}
                  </div>
                </div>

                <div
                  className="tab-pane fade"
                  id="list-messages"
                  role="tabpanel"
                  aria-labelledby="list-messages-list"
                >
                  <h3>
                    Maitriser Kubernetes sur le Cloud Azure avec son service AKS
                  </h3>
                  <p className="desc" style={{ fontSize: "1.2rem" }}>
                    Provisionner et utiliser{" "}
                    <strong>Azure Kubernetes Service (AKS)</strong>, le service
                    géré Kubernetes en mode PaaS (Platform as a Service) du
                    Cloud Microsoft Azure, déployer une application
                    conteneurisée sur AKS, et sécuriser l'accès.
                  </p>
                  <h4>Concrètement, vous allez :</h4>
                  <ul className="desc">
                    <li className="mb-5">
                      <GiCheckMark
                        style={{ marginRight: "8px", color: "#ff5421" }}
                      />
                      <span style={{ fontSize: "1.2rem" }}>
                        Provisionner et configurer un cluster Kubernetes avec
                        AKS
                      </span>
                    </li>
                    <li className="mb-5">
                      <GiCheckMark
                        style={{ marginRight: "8px", color: "#ff5421" }}
                      />{" "}
                      <span style={{ fontSize: "1.2rem" }}>
                        Déployer et maintenir une application conteneurisée sur
                        AKS
                      </span>
                    </li>
                    <li className="mb-5">
                      <GiCheckMark
                        style={{ marginRight: "8px", color: "#ff5421" }}
                      />
                      <span style={{ fontSize: "1.2rem" }}>
                        Surveiller, déboguer et résoudre les problèmes sur un
                        cluster AKS
                      </span>
                    </li>
                    <li className="mb-5">
                      <GiCheckMark
                        style={{ marginRight: "8px", color: "#ff5421" }}
                      />
                      <span style={{ fontSize: "1.2rem" }}>
                        Sécuriser l'accès sur un cluster AKS avec RBAC et Azure
                        Entra ID
                      </span>
                    </li>
                  </ul>
                  <h4 className="mt-30">Outils Utilisés</h4>
                  <div className="d-flex justify-content-start align-items-start flex-wrap gap-5">
                    {[
                      {
                        src: aksLogo,
                        alt: "Azure Kubernetes Service",
                        label: "AKS",
                      },
                      {
                        src: aagLogo,
                        alt: "Azure Application Gateway",
                        label: "Application Gateway",
                      },
                      {
                        src: azureEntraIDLogo,
                        alt: "Azure Entra ID",
                        label: "Entra ID",
                      },
                    ].map((tool) => (
                      <div
                        key={tool.alt.replace(/\s+/g, "_").toLowerCase()}
                        className="d-flex align-items-center"
                        style={{
                          backgroundColor: "white",
                          padding: "10px 20px",
                          borderRadius: "10px",
                          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                        }}
                      >
                        <img
                          src={tool.src}
                          alt={tool.alt}
                          style={{
                            width: "40px",
                            height: "40px",
                            objectFit: "contain",
                            marginRight: "10px",
                          }}
                        />
                        <h5
                          className="fw-bold"
                          style={{ fontSize: "0.9rem", marginBottom: "0" }}
                        >
                          {tool.label}
                        </h5>
                      </div>
                    ))}
                  </div>
                </div>

                <div
                  className="tab-pane fade"
                  id="list-messages-2"
                  role="tabpanel"
                  aria-labelledby="list-messages-list-2"
                >
                  <h3>
                    Préparer l'examen Certified Kubernetes Administrator (CKA)
                  </h3>
                  <p className="desc" style={{ fontSize: "1.2rem" }}>
                    Suivez une procédure méthodique pour maximiser vos chances
                    de réussite de l'examen Certified Kubernetes Administrator
                    (CKA) proposé par CNCF et Linux Foundation afin de couronner
                    ce parcours d'apprentissage intensif de Kubernetes
                  </p>
                  <h4>Concrètement, vous allez :</h4>
                  <ul>
                    <li className="mb-5">
                      <GiCheckMark
                        style={{ marginRight: "8px", color: "#ff5421" }}
                      />
                      <span style={{ fontSize: "1.2rem" }}>
                        Suivre un plan de révision qui couvre tout le programme
                        de l'examen
                      </span>
                    </li>
                    <li className="mb-5">
                      <GiCheckMark
                        style={{ marginRight: "8px", color: "#ff5421" }}
                      />
                      <span style={{ fontSize: "1.2rem" }}>
                        Pratiquer des Labs sur Kubernetes avec le même format de
                        l'examen
                      </span>
                    </li>
                    <li className="mb-5">
                      <GiCheckMark
                        style={{ marginRight: "8px", color: "#ff5421" }}
                      />
                      <span style={{ fontSize: "1.2rem" }}>
                        Utiliser le simulateur d'examen officiel pour préparer
                        l'examen
                      </span>
                    </li>
                    <li className="mb-5">
                      <GiCheckMark
                        style={{ marginRight: "8px", color: "#ff5421" }}
                      />
                      <span style={{ fontSize: "1.2rem" }}>
                        Apprendre les meilleures astuces avant d'aborder
                        l'examen
                      </span>
                    </li>
                  </ul>
                  <h4 className="mt-30">Outils Utilisés</h4>
                  <div className="d-flex justify-content-start align-items-start flex-wrap gap-5">
                    {[
                      { src: linuxLogo, alt: "Linux", label: "Linux" },
                      {
                        src: kubernetesLogo,
                        alt: "Kubernetes",
                        label: "Kubernetes",
                      },
                    ].map((tool) => (
                      <div
                        key={tool.alt.replace(/\s+/g, "_").toLowerCase()}
                        className="d-flex align-items-center"
                        style={{
                          backgroundColor: "white",
                          padding: "10px 20px",
                          borderRadius: "10px",
                          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                        }}
                      >
                        <img
                          src={tool.src}
                          alt={tool.alt}
                          style={{
                            width: "40px",
                            height: "40px",
                            objectFit: "contain",
                            marginRight: "10px",
                          }}
                        />
                        <h5
                          className="fw-bold"
                          style={{ fontSize: "0.9rem", marginBottom: "0" }}
                        >
                          {tool.label}
                        </h5>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>

            {/* Download programme button */}
            <div
              className="sl-btn mb-20"
              style={{
                marginTop: "3rem",
                marginBottom: "2rem",
                display: isMobile ? "block" : "none",
                textAlign: "center",
              }}
            >
              <Link
                onClick={(e) => {
                  e.preventDefault();
                  setShowDownloadModal(true);
                }}
                className="program_download readon orange-btn main-home"
                to="#"
              >
                Télécharger le programme
              </Link>
            </div>
          </div>
        </div>
      </section>

      {/* PEDAGOGIE SECTION */}
      <section id="méthodologie" className="hero-section">
        <h6 style={{ color: "grey", fontSize: "2rem" }}>Pédagogie</h6>
        <h2 className="text-center sl-title" style={{ fontSize: "3rem" }}>
          Learning by Doing - Un Bootcamp axé sur la Pratique
        </h2>
        <div className="d-flex flex-column flex-lg-row align-items-center">
          {/* Image Section */}
          <div className="col-md-4 mb-3 mb-lg-0 me-lg-3">
            <img
              src={LearningByDoing}
              alt="Learning by Doing"
              style={{
                objectFit: "contain",
              }}
            />
          </div>
          {/* Text Section */}
          <div>
            <p
              className="desc"
              style={{
                fontSize: "1.2rem",
                textAlign: "justify",
              }}
            >
              La démarche <strong>Learning by Doing</strong> fait de chaque
              nouveau concept un prétexte à l'action.
            </p>
            <p
              className="desc"
              style={{
                fontSize: "1.2rem",
                textAlign: "justify",
              }}
            >
              Que ce soit pour déployer une application conteneurisée sur un
              cluster Kubernetes, configurer le stockage ou déboguer des
              problèmes applicatifs et d'infrastructure, vous le mettez
              immédiatement en pratique à travers des exercices et des ateliers
              concrets.
            </p>
            <p
              className="desc"
              style={{
                fontSize: "1.2rem",
                textAlign: "justify",
              }}
            >
              Cette approche concrète vous plonge dans des situations
              professionnelles réelles, pour une mise en pratique immédiate, et
              grâce aux ateliers en petits groupes, vous consolidez vos
              connaissances tout en profitant d'échanges constructifs. Au fil de
              la formation, vous devenez progressivement opérationnel sur
              l'ensemble des briques essentielles de Kubernetes.
            </p>
          </div>
        </div>
        <h2 className="text-center sl-title mt-50" style={{ fontSize: "3rem" }}>
          Conçu et Enseigné par les Meilleurs Experts
        </h2>
        <div className="d-flex flex-column flex-lg-row align-items-start gap-60">
          {/* Text Section */}
          <div style={{ marginTop: "4rem", marginRight: "2rem" }}>
            <p
              className="desc"
              style={{
                fontSize: "1.2rem",
                marginBottom: "40px",
                textAlign: "justify",
              }}
            >
              Ce Bootcamp a été soigneusement conçu par nos experts Kubernetes,
              qui cumulent plusieurs dizaines d'années d'expérience dans la mise
              en œuvre des architectures et des implémentations de projets Cloud
              Native en entreprise.
            </p>
            <p
              className="desc"
              style={{
                fontSize: "1.2rem",
                marginBottom: "40px",
                textAlign: "justify",
              }}
            >
              Tous les formateurs de ce Bootcamp sont certifiés Certified
              Kubernetes Administrator (CKA) afin de mieux vous préparer à cet
              examen.
            </p>
            <p
              className="desc"
              style={{
                fontSize: "1.2rem",
                marginBottom: "40px",
                textAlign: "justify",
              }}
            >
              Vous voulez rencontrez les auteurs de ce Bootcamp ? Consultez les
              dates des prochaines sessions d'information
            </p>
            <div className="sl-btn my-20">
              <Link
                onClick={(e) => {
                  e.preventDefault();
                  setShowModal(true);
                }}
                className="program_download readon orange-btn main-home"
                href="#"
              >
                Contactez-nous
              </Link>
            </div>
          </div>

          {/* Image Section */}
          <div className="col-md-4 my-3 mb-lg-0 me-lg-3 col-40">
            <img
              className="instructorImage"
              src={InstructorImage}
              alt=""
              style={{
                objectFit: "contain",
              }}
            />
          </div>
        </div>
      </section>

      {/* FINANCEMENT SECTION */}
      <section id="financement" className="hero-section mb-4">
        <h6 style={{ color: "grey", fontSize: "2rem" }}>Financement</h6>
        <h2
          className="text-center sl-title"
          style={{
            fontSize: "3rem",
            fontWeight: "bolder",
          }}
        >
          Financer sa Formation
        </h2>
        <div className="d-flex flex-column flex-lg-row align-items-center">
          {/* Text Section */}
          <div>
            <p
              className="desc pr-20"
              style={{
                fontSize: "1.2rem",
                textAlign: "justify",
              }}
            >
              Bénéficiez de nos conseils d'experts pour trouver la solution de
              financement la plus adaptée quelque soit votre situation, avec la
              possibilité de couvrir jusqu'à 100% de vos frais de formation.
            </p>
            <p className="desc" style={{ fontSize: "1.2rem" }}>
              Contactez-nous pour plus d'information.
            </p>

            <div className="sl-btn my-20">
              <Link
                onClick={(e) => {
                  e.preventDefault();
                  setShowModal(true);
                }}
                className="program_download readon orange-btn main-home"
                href="#"
              >
                Contacter un conseiller
              </Link>
            </div>
          </div>

          {/* Image Section */}
          <div className="col-md-6 my-3 mb-lg-0 me-lg-3">
            <img
              className="instructorImage"
              src={ConsultantImage}
              alt="Contacter un conseiller CloudiaSys Academy"
              style={{
                objectFit: "contain",
                width: "50vw",
              }}
            />
          </div>
        </div>
      </section>

      {/* Admission section */}
      <section>
        <div
          id="admission"
          className="hero-section bg-color-grey-100 hide-on-tablet"
          style={{
            marginRight: "10rem",
            marginLeft: "10rem",
            marginBottom: "10rem",
          }}
        >
          <h6 className="mt-20" style={{ color: "grey", fontSize: "2rem" }}>
            Admission
          </h6>
          <div className="wrapper-1600 content-align-center">
            <h2 className="sl-title" style={{ fontSize: "3rem" }}>
              Comment Intégrer ce Bootcamp
            </h2>
          </div>
          <div className="wrapper-920 content-align-center mb-s3">
            <p
              className="desc"
              style={{
                fontSize: "1.2rem",
                textAlign: "center",
              }}
            >
              Notre processus d'admission garantit que vous disposez des bases
              indispensables pour évoluer dans l'environnement d'apprentissage
              exigeant du Bootcamp Kubernetes. Il validera vos connaissances des
              fondamentaux de Linux et votre capacité à comprendre les principes
              de la conteneurisation et de l'orchestration des conteneurs.
            </p>
          </div>

          <div className={isMobile ? "hidden" : "web-view"}>
            {/* Original timeline for web view */}
            <div className="wrapper-1600 flex-inline mb-s3 mt-s1">
              <div className="wrapper-250" style={{ width: "20vw" }}>
                <div className="typical-day-timeline-horizontal mb-s3">
                  <div className="timeline-divider-horizontal start"></div>
                  <div
                    className="typical-day-hour"
                    style={{
                      backgroundColor: "#ff5421",
                    }}
                  >
                    <span
                      className="sl-title"
                      style={{
                        fontSize: "1.5rem",
                        color: "white",
                        fontWeight: "bold",
                      }}
                    >
                      1
                    </span>
                  </div>
                </div>
                <div
                  className="timeline-horizontal-content"
                  style={{
                    width: "auto",
                    margin: "auto",
                  }}
                >
                  <h4 className="mb-20">Candidature</h4>
                  <p className="desc">
                    Candidatez en ligne. Vous serez contacté par notre équipe
                    d'admission sous 24 heures.
                  </p>
                </div>
              </div>
              <div className="wrapper-250" style={{ width: "20vw" }}>
                <div className="typical-day-timeline-horizontal mb-s3">
                  <div className="timeline-divider-horizontal"></div>
                  <div
                    className="typical-day-hour"
                    style={{
                      backgroundColor: "#ff5421",
                    }}
                  >
                    <span
                      className="sl-title"
                      style={{
                        fontSize: "1.5rem",
                        color: "white",
                        fontWeight: "bold",
                      }}
                    >
                      2
                    </span>
                  </div>
                </div>
                <div
                  className="timeline-horizontal-content"
                  style={{
                    width: "auto",
                    margin: "auto",
                  }}
                >
                  <h4 className="mb-20">Échange rapide</h4>
                  <p className="desc">
                    Posez toutes vos questions et partagez vos objectifs, pour
                    que nous puissions évaluer vos motivations.
                  </p>
                </div>
              </div>
              <div className="wrapper-250" style={{ width: "20vw" }}>
                <div className="typical-day-timeline-horizontal mb-s3">
                  <div className="timeline-divider-horizontal"></div>
                  <div
                    className="typical-day-hour"
                    style={{
                      backgroundColor: "#ff5421",
                    }}
                  >
                    <span
                      className="sl-title"
                      style={{
                        fontSize: "1.5rem",
                        color: "white",
                        fontWeight: "bold",
                      }}
                    >
                      3
                    </span>
                  </div>
                </div>
                <div
                  className="timeline-horizontal-content"
                  style={{
                    width: "auto",
                    margin: "auto",
                  }}
                >
                  <h4 className="mb-20">Test technique</h4>
                  <p className="desc">
                    40 minutes en ligne pour vérifier que vous disposez des
                    prérequis techniques nécessaires avant d'intégrer le
                    Bootcamp.
                  </p>
                </div>
              </div>
              <div className="wrapper-250" style={{ width: "20vw" }}>
                <div className="typical-day-timeline-horizontal mb-s3">
                  <div className="timeline-divider-horizontal end"></div>
                  <div
                    className="typical-day-hour"
                    style={{
                      backgroundColor: "#ff5421",
                    }}
                  >
                    <span
                      className="sl-title"
                      style={{
                        fontSize: "1.5rem",
                        color: "white",
                        fontWeight: "bold",
                      }}
                    >
                      4
                    </span>
                  </div>
                </div>
                <div
                  className="timeline-horizontal-content"
                  style={{
                    width: "auto",
                    margin: "auto",
                  }}
                >
                  <h4 className="mb-20">Options de financement</h4>
                  <p className="desc">
                    Identifions ensemble les solutions de prise en charge les
                    plus adaptées à votre situation.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className={isMobile ? "mobile-view" : "hidden"}>
            {/* Original timeline for web view */}
            <div className="wrapper-1600 flex-inline mb-s3 mt-s1">
              <div className="wrapper-250" style={{ width: "100%" }}>
                <div className="typical-day-timeline-horizontal mb-s3">
                  <div className="timeline-divider-horizontal start"></div>
                  <div className="typical-day-hour">
                    <span
                      className="sl-title"
                      style={{ fontSize: "1.5rem", color: "#ff5421" }}
                    >
                      1
                    </span>
                  </div>
                </div>
                <div className="timeline-horizontal-content w-100">
                  <div className="h6-style margin-bottom-4">Candidature</div>
                  <p className="desc">
                    Candidatez en ligne. Vous serez contacté par notre équipe
                    d'admission sous 24 heures.
                  </p>
                </div>
              </div>
              <div className="wrapper-250" style={{ width: "100%" }}>
                <div className="typical-day-timeline-horizontal mb-s3">
                  <div className="timeline-divider-horizontal"></div>
                  <div className="typical-day-hour">
                    <span
                      className="sl-title"
                      style={{ fontSize: "1.5rem", color: "#ff5421" }}
                    >
                      2
                    </span>
                  </div>
                </div>
                <div className="timeline-horizontal-content w-100">
                  <div className="h6-style margin-bottom-4">Échange rapide</div>
                  <p className="desc">
                    Posez toutes vos questions et partagez vos objectifs, pour
                    que nous puissions évaluer vos motivations.
                  </p>
                </div>
              </div>
              <div className="wrapper-250" style={{ width: "100%" }}>
                <div className="typical-day-timeline-horizontal mb-s3">
                  <div className="timeline-divider-horizontal"></div>
                  <div className="typical-day-hour">
                    <span
                      className="sl-title"
                      style={{ fontSize: "1.5rem", color: "#ff5421" }}
                    >
                      3
                    </span>
                  </div>
                </div>
                <div className="timeline-horizontal-content w-100">
                  <div className="h6-style margin-bottom-4">Test technique</div>
                  <p className="desc">
                    40 minutes en ligne pour vérifier que vous disposez des
                    prérequis techniques nécessaires avant d'intégrer le
                    Bootcamp.
                  </p>
                </div>
              </div>
              <div className="wrapper-250" style={{ width: "100%" }}>
                <div className="typical-day-timeline-horizontal mb-s3">
                  <div className="timeline-divider-horizontal end"></div>
                  <div className="typical-day-hour">
                    <span
                      className="sl-title"
                      style={{ fontSize: "1.5rem", color: "#ff5421" }}
                    >
                      4
                    </span>
                  </div>
                </div>
                <div
                  className="timeline-horizontal-content"
                  style={{ width: "100%" }}
                >
                  <div className="h6-style margin-bottom-4">
                    Options de financement
                  </div>
                  <p className="desc">
                    Identifions ensemble les solutions de prise en charge les
                    plus adaptées à votre situation.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="sl-btn mb-30 postuler_btn">
            <Link
              onClick={(e) => {
                e.preventDefault();
                setShowModal(true);
              }}
              className="readon orange-btn main-home"
              to="#"
            >
              Postuler
            </Link>
          </div>

          {/* <a href="/postuler" className="hero-button mt-s2 w-button">
              Postuler
            </a> */}
        </div>
      </section>

      {/* Contact Modal */}
      {showModal && (
        <div
          className="modal-overlay"
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 1000,
          }}
        >
          <div
            className="modal-content"
            style={{
              backgroundColor: "white",
              padding: "2rem",
              borderRadius: "8px",
              width: "90%",
              maxWidth: "500px",
            }}
          >
            <h3 style={{ marginBottom: "1.5rem" }}>Contactez-nous</h3>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div style={{ marginBottom: "1rem" }}>
                <label
                  htmlFor="fullName"
                  style={{ display: "block", marginBottom: "0.5rem" }}
                >
                  Nom complet *
                </label>
                <input
                  type="text"
                  id="fullName"
                  name="fullName"
                  {...register("fullName", { required: true })}
                  style={{
                    width: "100%",
                    padding: "0.5rem",
                    borderRadius: "4px",
                    border: "1px solid #ddd",
                  }}
                />
              </div>
              <div style={{ marginBottom: "1rem" }}>
                <label
                  htmlFor="email"
                  style={{ display: "block", marginBottom: "0.5rem" }}
                >
                  Email *
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  {...register("email", { required: true })}
                  style={{
                    width: "100%",
                    padding: "0.5rem",
                    borderRadius: "4px",
                    border: "1px solid #ddd",
                  }}
                />
              </div>
              <div style={{ marginBottom: "1.5rem" }}>
                <label
                  htmlFor="message"
                  style={{ display: "block", marginBottom: "0.5rem" }}
                >
                  Message *
                </label>
                <textarea
                  id="message"
                  name="message"
                  {...register("message", { required: true })}
                  rows="4"
                  style={{
                    width: "100%",
                    padding: "0.5rem",
                    borderRadius: "4px",
                    border: "1px solid #ddd",
                  }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: "1rem",
                }}
              >
                <button
                  type="button"
                  onClick={() => setShowModal(false)}
                  disabled={isSubmitting}
                  style={{
                    padding: "0.5rem 1rem",
                    borderRadius: "4px",
                    border: "1px solid #ddd",
                    backgroundColor: "#fff",
                    cursor: isSubmitting ? "not-allowed" : "pointer",
                    opacity: isSubmitting ? 0.7 : 1,
                  }}
                >
                  Fermer
                </button>
                <button
                  type="submit"
                  className="readon orange-btn main-home"
                  disabled={isSubmitting}
                  style={{
                    padding: "0.5rem 1rem",
                    borderRadius: "4px",
                    border: "none",
                    cursor: isSubmitting ? "not-allowed" : "pointer",
                    opacity: isSubmitting ? 0.7 : 1,
                  }}
                >
                  {isSubmitting ? "Envoi en cours..." : "Envoyer"}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {/* Download Modal */}
      {showDownloadModal && (
        <div
          className="modal-overlay"
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 1000,
          }}
        >
          <div
            className="modal-content"
            style={{
              backgroundColor: "white",
              padding: "2rem",
              borderRadius: "8px",
              width: "90%",
              maxWidth: "500px",
            }}
          >
            <h3 style={{ marginBottom: "1.5rem" }}>Télécharger le Programme</h3>
            <form onSubmit={handleSubmit(handleDownloadRequest)}>
              <div style={{ marginBottom: "1rem" }}>
                <label
                  htmlFor="fullName"
                  style={{ display: "block", marginBottom: "0.5rem" }}
                >
                  Prénom & Nom *
                </label>
                <input
                  type="text"
                  id="fullName"
                  {...register("fullName", {
                    required: "Nom complet est requis",
                    minLength: {
                      value: 5,
                      message:
                        "Le nom complet doit contenir au moins 5 caractères",
                    },
                  })}
                  style={{
                    width: "100%",
                    padding: "0.5rem",
                    borderRadius: "4px",
                    border: errors.fullName
                      ? "1px solid red"
                      : "1px solid #ddd",
                  }}
                />
                {errors.fullName && (
                  <span style={{ color: "red", fontSize: "0.8rem" }}>
                    {errors.fullName.message}
                  </span>
                )}
              </div>
              <div style={{ marginBottom: "1rem" }}>
                <label
                  htmlFor="email"
                  style={{ display: "block", marginBottom: "0.5rem" }}
                >
                  Email *
                </label>
                <input
                  type="email"
                  id="email"
                  {...register("email", {
                    required: "Email est requis",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: "Email invalide",
                    },
                  })}
                  style={{
                    width: "100%",
                    padding: "0.5rem",
                    borderRadius: "4px",
                    border: errors.email ? "1px solid red" : "1px solid #ddd",
                  }}
                />

                {errors.email && (
                  <span style={{ color: "red", fontSize: "0.8rem" }}>
                    {errors.email.message}
                  </span>
                )}
              </div>
              <div style={{ marginBottom: "1.5rem" }}>
                <label
                  htmlFor="phoneNumber"
                  style={{ display: "block", marginBottom: "0.5rem" }}
                >
                  Numéro de téléphone *
                </label>
                <input
                  type="tel"
                  id="phoneNumber"
                  {...register("phoneNumber", {
                    required: "Numéro de téléphone est requis",
                    pattern: {
                      value: /^[0-9+\s-]{8,}$/,
                      message: "Numéro de téléphone invalide",
                    },
                  })}
                  style={{
                    width: "100%",
                    padding: "0.5rem",
                    borderRadius: "4px",
                    border: errors.phoneNumber
                      ? "1px solid red"
                      : "1px solid #ddd",
                  }}
                />
                {errors.phoneNumber && (
                  <span style={{ color: "red", fontSize: "0.8rem" }}>
                    {errors.phoneNumber.message}
                  </span>
                )}
              </div>
              <div>
                <input
                  type="hidden"
                  {...register("programme")}
                  defaultValue="Ansible - Automatiser la Gestion des Serveurs"
                />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: "1rem",
                }}
              >
                <button
                  type="button"
                  onClick={() => setShowDownloadModal(false)}
                  disabled={isSubmitting}
                  style={{
                    padding: "0.5rem 1rem",
                    borderRadius: "4px",
                    border: "1px solid #ddd",
                    backgroundColor: "#fff",
                    cursor: isSubmitting ? "not-allowed" : "pointer",
                    opacity: isSubmitting ? 0.7 : 1,
                  }}
                >
                  Fermer
                </button>
                <button
                  type="submit"
                  className="readon orange-btn main-home"
                  disabled={isSubmitting}
                  style={{
                    padding: "0.5rem 1rem",
                    borderRadius: "4px",
                    border: "none",
                    cursor: isSubmitting ? "not-allowed" : "pointer",
                    opacity: isSubmitting ? 0.7 : 1,
                  }}
                >
                  {isSubmitting ? "Traitement..." : "Télécharger"}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {/* scrolltop-start */}
      <ScrollToTop scrollClassName="scrollup orange-color" />
      {/* scrolltop-end */}
    </React.Fragment>
  );
};

export default CourseDetailsMain;
