const domainMap = {
  "cloud&Infra": "Cloud & infrastructure",
  dataAnalytics: "Data Analytics",
  devops: "DevOps & DevSecOps",
  IA: "Intelligence Artificielle",
  cybersecurity: "Cyber Sécurité",
  dataViz: "Data Visualization",
};

export const getDomain = (domain) => {
  return domainMap[domain] || "Cloud & infrastructure";
};

const platformMap = {
  azure: "Azure (Microsoft)",
  gcp: "GCP (Google)",
  kubernetes: "Kubernetes (Linux Foundation)",
};

export const getPlatform = (platform) => {
  return platformMap[platform] || "Azure (Microsoft)";
};

const certificationMap = {
  microsoft: "Microsoft",
  google: "Google",
  linux: "Linux Foundation",
};

export const getCertification = (certification) => {
  return certificationMap[certification] || "Microsoft";
};
