import React from "react";
import { Link } from "react-router-dom";

import devopsBootcampImg from "../../assets/img/menu/devops_bootcamp.png";
import kubernetesBootcampImg from "../../assets/img/menu/kubernetes_bootcamp.png";

const Bootcamps = () => {
  return (
    <div
      id="bootcamps"
      className="rs-categories main-home pt-90 md-pt-60 md-pb-80"
    >
      <div className="container">
        <div className="sec-title3 text-center mb-44">
          <div className="sub-title">NOS BOOTCAMPS</div>
          <p className="desc text-center text-muted fs-5 mb-4 mx-lg-20 px-lg-20">
            Des parcours conçus par nos formateurs, avec des projets immersifs
            et des scénarios d'entreprise basés sur leur expérience en
            Consulting dans l'IT pour propulser votre carrière
          </p>
        </div>

        <div className="row g-6 mb-34 justify-content-center">
          {/* First Div */}
          <div
            className="col-lg-6 col-md-8 col-sm-10 mb-30"
            style={{
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
              borderRadius: "8px",
              padding: "20px",
              backgroundColor: "#fff",
            }}
          >
            <div className="d-flex flex-column flex-md-row align-items-center">
              <div
                className="post-img mb-3 mb-md-0 me-md-4"
                style={{
                  width: "100%",
                  maxWidth: "200px",
                  textAlign: "center",
                }}
              >
                <img
                  src={devopsBootcampImg}
                  alt="Devops"
                  style={{
                    width: "100%",
                    height: "auto",
                  }}
                />
              </div>
              <div
                className="post-item"
                style={{
                  flex: "1",
                  paddingLeft: "0",
                  paddingLeft: { md: "15px" },
                }}
              >
                <h5
                  className="title text-center text-md-start"
                  style={{ fontSize: "1.5rem" }}
                >
                  Ingénieur Cloud & DevOps
                </h5>
                <span className="d-block text-center text-md-start">
                  Formation intensive de 10 Semaines orientée projets, qui vous
                  prépare aux compétences essentielles en architecture cloud et
                  en automatisation DevOps
                </span>
                <div
                  className="sl-btn text-center text-md-start"
                  style={{ paddingTop: "20px" }}
                >
                  <Link
                    className="readon orange-btn main-home"
                    to="/Bootcamps/Cloud"
                  >
                    En savoir plus
                  </Link>
                </div>
              </div>
            </div>
          </div>

          {/* Second Div */}
          <div
            className="col-lg-6 col-md-8 col-sm-10 mb-30"
            style={{
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
              borderRadius: "8px",
              padding: "20px",
              backgroundColor: "#fff",
            }}
          >
            <div className="d-flex flex-column  flex-md-row align-items-center">
              <div
                className="post-img mb-3 mb-md-0 me-md-4"
                style={{
                  width: "100%",
                  maxWidth: "200px",
                  textAlign: "center",
                }}
              >
                <img
                  src={kubernetesBootcampImg}
                  alt="Kubernetes"
                  style={{
                    width: "80%",
                    height: "auto",
                  }}
                />
              </div>
              <div
                className="post-item"
                style={{
                  flex: "1",
                  paddingLeft: "0",
                  paddingLeft: { md: "15px" },
                }}
              >
                <h5
                  className="title text-center text-md-start"
                  style={{ fontSize: "1.5rem" }}
                >
                  Orchestration avec Kubernetes
                </h5>
                <span className="post-date d-block text-center text-md-start">
                  Maîtrisez l'art de l'orchestration avec Kubernetes et apprenez
                  à déployer, gérer et automatiser vos applications dans des
                  environnements cloud complexes
                </span>
                <div
                  className="sl-btn text-center text-md-start"
                  style={{ paddingTop: "20px" }}
                >
                  <Link
                    className="readon orange-btn main-home"
                    to="/Bootcamps/Kubernetes"
                  >
                    En savoir plus
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Bootcamps;
