import React from "react";
import Team from "./TeamSection";
import ScrollToTop from "../../components/Common/ScrollTop";
import TrainTheTrainerSection from "./TrainTheTrainerSection";
import AboutStats from "./AboutStats";
import WhatDistinguishesUs from "./WhatDistinguishesUs";
import Partners from "../home/partners/PartnersSection";
import ContactUs from "./ContactUs";

const AboutMain = () => {
  return (
    <React.Fragment>
      {/* AboutCounter Start */}
      {/* <AboutCounter /> */}
      {/* AboutCounter End */}

      {/* AboutVideo Start */}
      {/* <AboutVideo /> */}
      {/* AboutVideo End */}

      {/* Train The Trainer Section Start */}
      <TrainTheTrainerSection />
      {/* Train The Trainer Section End */}

      {/* About Stats Start */}
      <AboutStats />
      {/* About Stats End */}

      {/* What Distinguishes Us Start */}
      <WhatDistinguishesUs />
      {/* What Distinguishes Us End */}

      {/* Partners Section Start */}
      <Partners />
      {/* Partners Section End */}

      {/* Team Section Start */}
      <Team />
      {/* Team Section End */}

      {/* Contact Us Section Start */}
      <ContactUs />
      {/* Contact Us Section End */}

      {/* Testimonial Section Start */}
      {/* <Testimonial /> */}
      {/* Testimonial Section End */}

      {/* Blog Section Start */}
      {/* <Blog /> */}
      {/* Blog Section End */}

      {/* scrolltop-start */}
      <ScrollToTop scrollClassName="scrollup orange-color" />
      {/* scrolltop-end */}
    </React.Fragment>
  );
};

export default AboutMain;
