import React from "react";
import SectionTitle from "../../components/Common/SectionTitle";
import DomainsSingle from "../../CloudiaSys/home/domains/DomainsSingle";

// Domain images and icons
import image1 from "../../assets/img/categories/main-home/001.jpg";
import image2 from "../../assets/img/categories/main-home/002.jpg";
import image3 from "../../assets/img/categories/main-home/003.jpg";
import image4 from "../../assets/img/categories/main-home/004.jpg";
import image5 from "../../assets/img/categories/main-home/005.jpg";
import image6 from "../../assets/img/categories/main-home/006.jpg";
import icon1 from "../../assets/img/categories/main-home/icon/1.png";
import icon2 from "../../assets/img/categories/main-home/icon/2.png";
import icon3 from "../../assets/img/categories/main-home/icon/3.png";
import icon4 from "../../assets/img/categories/main-home/icon/4.png";
import icon5 from "../../assets/img/categories/main-home/icon/5.png";
import icon6 from "../../assets/img/categories/main-home/icon/6.png";
import { getDomain } from "../../utils/helpers";
const DOMAIN_ITEMS = [
  {
    id: "cloud&Infra",
    image: image1,
    title: "Cloud & Infrastructure",
    icon: icon1,
  },
  {
    id: "dataAnalytics",
    image: image2,
    title: "Data Analytics",
    icon: icon2,
  },
  {
    id: "devops",
    image: image3,
    title: "DevOps & DevSecOps",
    icon: icon3,
  },
  {
    id: "IA",
    image: image4,
    title: "Intelligence Artificielle",
    icon: icon4,
  },
  {
    id: "dataViz",
    image: image5,
    title: "Data Visualisation",
    icon: icon5,
  },
  {
    id: "cybersecurity",
    image: image6,
    title: "Cyber Sécurité",
    icon: icon6,
  },
];

const Domains = () => {
  return (
    <div className="rs-categories main-home pt-20 pb-100 md-pt-60 md-pb-80">
      <div className="container">
        <SectionTitle
          sectionClass="sec-title3 text-center mb-44"
          subtitleClass="sub-title"
          subtitle="TOP DOMAINES"
          titleClass="title black-color"
          title="Domaines Populaires"
        />
        <div className="row mb-34">
          {DOMAIN_ITEMS.map((domain) => (
            <div key={domain.id} className="col-lg-4 col-md-6 mb-30">
              <DomainsSingle
                id={domain.id}
                itemClass="categories-items"
                image={domain.image}
                title={getDomain(domain.id)}
                iconImg={domain.icon}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Domains;
