import React from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import FeatureStyle1 from "../Feature/FeatureStyle1";

const SliderDefault = () => {
  const sliderSettings = {
    dots: false,
    centerMode: false,
    infinite: true,
    arrows: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: false,
        },
      },
    ],
  };

  return (
    <div className="rs-slider main-home">
      <Slider {...sliderSettings}>
        <div className="slider-content slide1">
          <div className="container">
            <div className="content-part">
              <div className="sl-sub-title">
                COMMENCER À APPRENDRE DÈS AUJOURD'HUI
              </div>
              <h1 className="sl-title">
                Faites le Premier Pas vers votre Nouvelle Carrière
              </h1>
              <div className="sl-btn">
                <a
                  className="readon orange-btn main-home"
                  href="#offreFormations"
                >
                  Trouver une formation
                </a>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="slider-content slide2">
          <div className="container">
            <div className="content-part">
              <div className="sl-sub-title">
                COMMENCER À APPRENDRE DÈS AUJOURD'HUI
              </div>
              <h1 className="sl-title">
                Faites le Premier Pas vers votre Nouvelle Carrière
              </h1>
              <div className="sl-btn">
                <Link className="readon orange-btn main-home" to="/course">
                  Trouver une formation
                </Link>
              </div>
            </div>
          </div>
        </div> */}

        <div className="slider-content slide3">
          <div className="container">
            <div className="content-part">
              <div className="sl-sub-title">
                COMMENCER À APPRENDRE DÈS AUJOURD'HUI
              </div>
              <h1 className="sl-title">
                Faites le Premier Pas vers votre Nouvelle Carrière
              </h1>
              <div className="sl-btn">
                <Link className="readon orange-btn main-home" to="/course">
                  Trouver une formation
                </Link>
              </div>
            </div>
          </div>
        </div>
      </Slider>
      <FeatureStyle1 />
    </div>
  );
};

export default SliderDefault;
